const getToken = () => (localStorage.getItem('token'))

const getUser = () => {
  const user = localStorage.getItem('user')
  return JSON.parse(user)
}

const state = {
  session: {
    isLoggedIn: getToken() != null,
    user: getUser()
  },
  plan: {},
  userUsageReport: {},
  catalog: {
    currentSortDir: "asc",
    setOfFeedIdToRemove: new Set(),
    search: "",
    checkedCategories: [],
    showOnlySubscribed: true
  }
}

export default state