<template>
  <div class="modal fade" id="progress-dialog" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <div class="row" v-if="!message">
            <div class="col-3">
              <spinner loading="loading" klass="centered-spinner" />
            </div>
            <div class="col-9">
              <h3>Please wait...</h3>
            </div>
          </div>

          <div class="row message-container" v-if="message">
            <div class="col-12">
              <h3>{{ message }}</h3>
            </div>
          </div>
        </div>

        <div class="modal-footer justify-content-center" v-if="message">
          <button type="button" class="btn btn-default" @click="dismiss">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from './spinner.vue'
  import modals from './modals.js'

  export default {
    components: {
      Spinner
    },

    props: ['message'],

    data() {
      return {
        loading: true
      }
    },

    methods: {
      dismiss() {
        $('#progress-dialog').modal('hide')
        this.$emit('onclose');
      },

      show() {
        $('#progress-dialog').modal(modals.show)
      }
    }
  }
</script>

<style>
  h3 {
    font-size: 15px;
  }

  #progress-dialog {
    top: 25%;
  }

  #progress-dialog  .message-container {
    padding: 10px 15px;
    text-align: center;
  }

  #progress-dialog .modal-footer {
    padding: 15px !important;
  }
</style>