<template>
  <div class="footer row text-light pt-4" id="footer">
    <div class="col">
      <div class="row">
        <div class="col-md-4 pl-3">
          <h3>
            <a href="https://www.narrativa.com" target="_blank">
              <img
                height="40"
                src="/assets/img/NarrativaLogoBlancoOnlyLogo.png"
              />
            </a>
          </h3>
         
        </div>
        <div class="col-md-4 mb-4" id="footer-contact">
          <h4
            class="widget-title fusion-responsive-typography-calculated"
            style="--fontsize: 14; line-height: 1.25; --minfontsize: 14; --fontSize:10; --minFontSize:10;"
            data-fontsize="10"
            data-lineheight="12.5px"
          >General</h4>
          <div style="font-size: small;">
           <a href="mailto:info@narrativa.com"><span style="color: #ffffff;">info@narrativa.com</span></a>
            <br />730 Arizona Avenue
            <br />Attn: Narrativa
            <br />Santa Monica, CA 90401
          </div>
          <div style="margin-top: 20px;"></div>
        </div>
        <div class="col-md-4" id="footer-links">
          <ul class="menu">
            <li class="mt-2">
              <strong>
                <a href="https://www.narrativa.com/">Narrativa.com</a>
              </strong>
            </li>
            <li>
              <a href="/catalog">&gt;&nbsp;Catalog</a>
            </li>
            <li>
              <a href="https://www.narrativa.com/es/blog-noticias/">&gt;&nbsp;Blog</a>
            </li>
            <li>
              <a href="https://www.narrativa.com/es/sala-de-prensa/">&gt;&nbsp;Newsroom</a>
            </li>
            <li>
              <a href="https://www.narrativa.com/es/clientes/">&gt;&nbsp;Customers</a>
            </li>
            <li>
              <a href="https://www.narrativa.com/es/contacto/">&gt;&nbsp;Contact Us</a>
            </li>
            <li>
              <a target="_blank" href="https://stats.uptimerobot.com/Q6NlpspLQ">&gt;&nbsp;API Status</a>
            </li>
            <li>
              <a target="_blank" href="https://help.narrativa.com/">&gt;&nbsp;Knowledge Base</a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.narrativa.com/privacy-policy/"
              >&gt;&nbsp;Privacy Policy</a>
            </li>
            <li>
              <a href="/terms">
              &gt;&nbsp;Terms of use
              </a>
            </li>
          </ul>
        </div>
        <!-- col-md-4 -->
      </div>
      <!-- row -->
      <div class="row mb-4 mt-4">
        <div class="col-md-10 mt-4">
          <p>&copy; Copyright All Rights Reserved 2015-{{new Date().getFullYear()}} by Narrativa</p>
        </div>
        <div class="col-md-2 mt-4" id="footer-social-links">
          <a href="https://www.linkedin.com/company/9329868" target="_blank">
            <i class="fab fa-linkedin-in" aria-hidden="true"></i>
            <span class="d-none">linkedin</span>
          </a>
          <a href="https://www.twitter.com/NarrativaAI" target="_blank">
            <i class="fab fa-twitter" aria-hidden="true"></i>
            <span class="d-none">twitter</span>
          </a>
          <a href="https://www.facebook.com/narrativaai/" target="_blank">
            <i class="fab fa-facebook-f" aria-hidden="true"></i>
            <span class="d-none">facebook</span>
          </a>
          <a href="mailto:customer-support@narrativa.com" target="_blank">
            <i class="fa fa-envelope" aria-hidden="true"></i>
            <span class="d-none">email</span>
          </a>
        </div>
      </div>
    </div>
    <!-- col -->
  </div>
  <!-- row -->
</template>

<script>
export default {
  name: "common-footer"
};
</script>

<style>
#footer {
  background-color: black;
  font-size: 0.9em;
}

#footer a {
  color: white !important;
}

#contact a {
  color: white;
}

#footer h3 {
  color: white;
  font-size: 1.5em !important;
}

#footer-social-links a {
  color: white !important;
  font-size: 1.5em;
  margin-left: 0.75em;
}

#footer-contact {
  line-height: 1em;
  line-height: 2.5em;
  font-size: 0.7em;
}

#footer-contact h4 {
  color: white;
  font-size: 1.8em;
  font-weight: bold;
}

#footer-links a {
  color: white;
  font-size: 1.3em;
  margin-bottom: 1em;
}

#footer-links li {
  margin-bottom: 1em;
}
#footer-links ul {
  list-style-type: none;
  margin-right: 0px;
}

.footer .menu {
  padding-left: 0 !important;
}
</style>