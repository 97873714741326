<template>
  <div class="fade show">
    <div class="modal fade" id="user-modal" role="dialog">
      <div class="modal-dialog slide-up modal-lg">
        <div class="modal-content">
          <form @submit="save">
            <div class="modal-header clearfix text-left">
              <button type="button" class="close" @click="close" aria-hidden="true">×</button>
              <h5
                style="font-size: 20px;"
                class="semi-bold"
              >{{ !isUpdate ? 'Create User' : 'Edit User' }}</h5>
            </div>
            <div class="modal-body p-5">
              <h6 class="mb-4">Basic Information</h6>
              <div class="form-group-attached">
                <div class="row clearfix">
                  <div class="col-md-4">
                    <div class="form-group form-group-default required">
                      <label>Email</label>
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        v-model="user.email"
                        spellcheck="false"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group form-group-default" :class="{ required: !isUpdate }">
                      <label>Password</label>
                      <input
                        type="password"
                        class="form-control"
                        name="password"
                        v-model="user.password"
                        :required="!isUpdate"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group form-group-default" :class="{ required: !isUpdate }">
                      <label>Role</label>
                      <select v-model="user.roles" class="custom-select" id="selectRole">
                        <option :value="['ROLE_CLIENT']">Client</option>
                        <option :value="['ROLE_ADMIN']">Admin</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-4">
                    <div class="form-group form-group-default required">
                      <label>First name</label>
                      <input
                        type="text"
                        class="form-control"
                        name="firstName"
                        v-model="user.firstName"
                        spellcheck="false"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group form-group-default required">
                      <label>Last name</label>
                      <input
                        type="text"
                        class="form-control"
                        name="lastName"
                        v-model="user.lastName"
                        spellcheck="false"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group form-group-default required">
                      <label>Client code</label>
                      <input
                        type="text"
                        class="form-control"
                        name="lastName"
                        v-model="user.clientCode"
                        spellcheck="false"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-12">
                    <div class="form-group form-group-default" :class="{ required: isUpdate }">
                      <label>Token</label>
                      <input
                        type="text"
                        class="form-control"
                        name="firstName"
                        v-model="user.apiKey"
                        spellcheck="false"
                        :required="isUpdate"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h6 class="mt-4 mb-4">Billing Information</h6>
              <div class="form-group-attached">
                <div class="form-group form-group-default">
                  <label>Company</label>
                  <input
                    type="text"
                    class="form-control"
                    name="billingName"
                    v-model="user.billingName"
                    spellcheck="false"
                  />
                </div>

                <div class="form-group form-group-default">
                  <label>Billing Address</label>
                  <input
                    type="text"
                    class="form-control"
                    name="billingAddress1"
                    v-model="user.billingAddress1"
                    spellcheck="false"
                  />
                </div>

                <div class="form-group form-group-default">
                  <label>Billing Address 2</label>
                  <input
                    type="text"
                    class="form-control"
                    name="billingAddress2"
                    v-model="user.billingAddress2"
                    spellcheck="false"
                  />
                </div>

                <div class="row clearfix">
                  <div class="col-md-4">
                    <div class="form-group form-group-default">
                      <label>Billing Country</label>
                      <input
                        type="text"
                        class="form-control"
                        name="billingCountry"
                        v-model="user.billingCountry"
                        spellcheck="false"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group form-group-default">
                      <label>Billing Zip Code</label>
                      <input
                        type="text"
                        class="form-control"
                        name="billingZip"
                        v-model="user.billingZip"
                        spellcheck="false"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group form-group-default">
                      <label>Billing City</label>
                      <input
                        type="text"
                        class="form-control"
                        name="billingCity"
                        v-model="user.billingCity"
                        spellcheck="false"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group form-group-default">
                  <label>VAT Number</label>
                  <input
                    type="text"
                    class="form-control"
                    name="billingVat"
                    v-model="user.billingVat"
                    spellcheck="false"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" @click="close">Close</button>
              <button type="submit" class="btn btn-success">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modals from "../../ui/modals.js";
import FormInput from "../../ui/form-input.vue";
export default {
  name: "user-modal",
  components: { FormInput },
  data() {
    return {
      user: {
        roles: ["ROLE_CLIENT"]
      },
      isUpdate: false
    };
  },
  methods: {
    show(user) {
      if (user) {
        this.isUpdate = true;
        this.user = user;
      }
      $("#user-modal").modal(modals.show);
    },
    close() {
      (this.user = {
        roles: ["ROLE_CLIENT"]
      }),
        (this.isUpdate = false);
      $("#user-modal").modal("hide");
    },
    async save(e) {
      e.preventDefault();

      if (!this.isUpdate) {
        this.$emit("createUser", this.user);
      } else {
        this.$emit("updateUser", this.user);
      }

      this.close();
    }
  }
};
</script>

<style scoped>
label {
  margin-bottom: 1em;
  color:#888;
}
</style>