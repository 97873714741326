<template>
  <div>
    <div class="header p-r-0 page_header" :class="{'header_bottom_line':!isUserLogged}">
      <dashboard-header></dashboard-header>
      <tabs></tabs>
    </div>

    <div class="dashboard page-container">
      <div class="page-content-wrapper">
        <div class="container">
          <div v-if="!isAdminUser" class="row mb-4" style="min-height:500px">
            <div class="col-lg-9 mt-3">
              <router-view></router-view>
            </div>
            <div class="col-lg-3">
              <div>
                <div class="">
                  <client-status></client-status>
                </div>
              </div>
              <!-- row -->
            </div>
          </div>
          <!-- case of admin -->
          <div v-else class="row mb-4" style="min-height:500px">
            <div class="col-md-12 mt-3">
              <router-view></router-view>
            </div>
          </div>
        </div>
        <!-- container -->
      </div>
      <!-- page-content-wrapper -->

      <terms title="Subscriptions Terms and condition" id="subscription-tos" />
      <terms title="Terms of use" id="termsofuse-modal" />
      <terms title="Privacy Policy" id="privacy-policy-modal" />
    </div>
    <!-- page-container -->
    <div class="container container-fixed-lg footer" id="footer">
      <common-footer></common-footer>
    </div>
  </div>
</template>

<script>
import ENABLED from '../toggle_feature';
import DashboardHeader from "../components/dashboard/header/header.vue";
import Tabs from "../components/dashboard/tabs.vue";
import Terms from "../components/terms.vue";
import ClientStatus from "../components/dashboard/client-status-switch";
import CommonFooter from "./common-footer.vue";
import { isAdminUser } from "../utils/user";
import { isUserLogged } from "../utils/user";

export default {
  name: "cover-dashboard-container",
  components: {
    DashboardHeader,
    Tabs,
    Terms,
    ClientStatus,
    CommonFooter
  },

  mounted() {
    document.body.className =
      "fixed-header horizontal-menu horizontal-app-menu";
  },

  data() {
    return {
      ENABLED: ENABLED,
      isAdminUser: isAdminUser(),
      isUserLogged: isUserLogged()
    };
  }
};
</script>

<style scoped>
.page_header {
  background-color: black !important;
}

.header_bottom_line {
  border-bottom: 0.5em solid #fedd4e !important;
}

.dashboard {
  background-color: #eee !important;
}
</style>