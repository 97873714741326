export const isAdminUser = () => {
  return localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user')) &&
    JSON.parse(localStorage.getItem('user')).roles.length &&
    JSON.parse(localStorage.getItem('user')).roles.includes('ROLE_ADMIN');
};

export const isUserLogged = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const isEnterprise = () => {
  const user = localStorage.getItem('user');
  return user && JSON.parse(user).is_enterprise;
};

export const usedTrial = () => {
  const user = localStorage.getItem('user');
  return user && JSON.parse(user).used_trial;
};


export const isDemoUser = () => {
  const user = localStorage.getItem('user');
  return user && JSON.parse(user).email == "nrt_demo@narrativa.com"; //For PROD
  // return user && JSON.parse(user).email == "info@narrativa.com";  //For TESTING
};