<template>
	<div class="fade show">
		<div class="modal fade" id="feed-modal" role="dialog">
			<div class="modal-dialog slide-up modal-lg">
				<div class="modal-content">
					<form @submit="save">
						<div class="modal-header clearfix text-left">
							<button
								type="button"
								class="close"
								@click="close"
								aria-hidden="true"
							>
								×
							</button>
							<h5 style="font-size: 20px" class="semi-bold">
								{{ !isUpdate ? "Add New Feed" : "Update Feed" }}
							</h5>
						</div>
						<div class="modal-body">
							<div class="container p-5">
								<alert v-bind:message="error" type="danger"></alert>
								<div class="form-group row">
									<label for="inputTitle" class="col-sm-2 col-form-label"
										>Title</label
									>
									<div class="col-sm-10">
										<input
											type="text"
											v-model="feed.title"
											class="form-control"
											id="inputTitle"
											placeholder="Title"
											required
										/>
									</div>
								</div>
								<div class="form-group row">
									<label for="inputCoverTitle" class="col-sm-2 col-form-label"
										>Cover Title</label
									>
									<div class="col-sm-10">
										<input
											type="text"
											v-model="feed.cover_title"
											class="form-control"
											id="inputCoverTitle"
											placeholder="Cover Title"
											required
										/>
									</div>
								</div>
								<div class="form-group row">
									<label for="inputType" class="col-sm-2 col-form-label"
										>Type</label
									>
									<div class="col-sm-10">
										<input
											type="text"
											v-model="feed.type"
											class="form-control"
											id="inputType"
											placeholder="Type"
											required
										/>
									</div>
								</div>
								<div class="form-group row">
									<label for="inputSubtype" class="col-sm-2 col-form-label"
										>Subtype</label
									>
									<div class="col-sm-10">
										<input
											type="text"
											v-model="feed.subtype"
											class="form-control"
											id="inputSubtype"
											placeholder="Subtype"
											required
										/>
									</div>
								</div>
								<div class="form-group row">
									<label for="selectLanguage" class="col-sm-2 col-form-label"
										>Language</label
									>
									<div class="col-sm-10">
										<select
											v-model="feed.language"
											class="custom-select my-1 mr-sm-2"
											id="selectLanguage"
											required
										>
											<option
												v-for="language in languages"
												:value="language.value"
											>
												{{ language.text }}
											</option>
										</select>
									</div>
								</div>
								<div class="form-group row">
									<label for="selectDomain" class="col-sm-2 col-form-label"
										>Domain</label
									>
									<div class="col-sm-10">
										<vue-autosuggest
											:limit="10"
											:suggestions="filteredDomains"
											:input-props="domainSelectInputProps"
											@selected="onSelected"
											@input="onInputChange"
											v-model="feed.domain"
										></vue-autosuggest>

										<!--select
											v-model="feed.domain"
											class="custom-select my-1 mr-sm-2"
											id="selectDomain"
											required
										>
											<option :value="undefined" selected>
												Select a Domain
											</option>
											<option v-for="domain in domains" :value="domain">
												{{ domain }}
											</option>
										</select-->
									</div>
								</div>

								<div class="form-group row">
									<label for="selectPublishType" class="col-sm-2 col-form-label"
										>Publishing Type</label
									>
									<div class="col-sm-10">
										<select
											v-model="feed.publish_type"
											class="custom-select my-1 mr-sm-2"
											id="selectPublishType"
											required
										>
											<option
												v-for="publishing_type in publishing_types"
												:value="publishing_type.value"
											>
												{{ publishing_type.text }}
											</option>
										</select>
									</div>
								</div>

								<div class="form-group row">
									<label for="description" class="col-sm-2 col-form-label"
										>Description</label
									>
									<div class="col-sm-10">
										<input
											type="text"
											v-model="feed.description"
											class="form-control"
											id="description"
											placeholder="Brief description"
										/>
									</div>
								</div>

								<div class="form-group row">
									<label
										for="publish_periodicity"
										class="col-sm-2 col-form-label"
										>Publish Periodicity</label
									>
									<div class="col-sm-10">
										<input
											type="text"
											v-model="feed.publish_periodicity"
											class="form-control"
											id="publish_periodicity"
											placeholder="Monthly, weekly, daily..."
										/>
									</div>
								</div>

								<div class="form-group row">
									<label for="post_rate" class="col-sm-2 col-form-label"
										>Publish Rate</label
									>
									<div class="col-sm-10">
										<input
											type="text"
											v-model="feed.post_rate"
											class="form-control"
											id="post_rate"
											placeholder="20 post / week"
										/>
									</div>
								</div>

								<div class="form-group row">
									<label for="categories" class="col-sm-2 col-form-label"
										>Categories</label
									>
									<div class="col-sm-10">
										<ul id="categories-list" class="list-unstyled">
											<li v-for="cat in categories" v-bind:key="cat.code">
												<input
													type="checkbox"
													v-bind:id="cat.code"
													v-model="feed.categories"
													:value="cat.code"
												/>
												<label v-bind:for="cat.code">{{ cat.title }}</label>
											</li>
										</ul>
									</div>
								</div>

								<!--<div class="form-group row">
                  <label for="tags" class="col-sm-2 col-form-label">Tags (comma separated)</label>
                  <div class="col-sm-10">
                    <input
                      type="text"
                      v-model="feed.tags"
                      class="form-control"
                      id="tags"
                      placeholder="Tags1, Tag2"
                    />
                  </div>
                </div>-->

								<div class="form-group row">
									<label for="img_cover" class="col-sm-2 col-form-label"
										>Cover Image URL</label
									>
									<div class="col-sm-10">
										<input
											type="text"
											v-model="feed.img_cover"
											class="form-control"
											id="img_cover"
											placeholder="http://..."
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default" @click="close">
								Close
							</button>
							<button type="submit" class="btn btn-success">Save</button>
							<button
								type="button"
								class="btn"
								:class="{ 'btn-danger': feed.active }"
								v-if="isUpdate"
								@click="clickActiveButton"
							>
								{{ feed.active ? "Deactivate" : "Activate" }}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<confirm-dialog
			ref="confirmDialog"
			:message="confirmMessage"
			@confirm="toggleActive"
		></confirm-dialog>
	</div>
</template>

<script>
import modals from "../../ui/modals.js";
import feeds from "../../../api/feeds.js";
import categories from "../../../api/categories.js";
import Alert from "../../ui/alert.vue";
import domains from "../../../api/domains.js";
import ConfirmDialog from "../../ui/confirm-dialog.vue";
import { VueAutosuggest } from "vue-autosuggest";

export default {
	name: "feed-modal",
	components: {
		ConfirmDialog,
		Alert,
		VueAutosuggest,
	},
	data() {
		return {
			feed: {
				active: true,
				categories: [],
			},
			languages: [
				{ value: undefined, text: "Select a language" },
				{ value: "en", text: "English" },
				{ value: "es", text: "Spanish" },
				{ value: "ar", text: "Arabic" },
				{ value: "de", text: "German" },
				{ value: "it", text: "Italian" },
				{ value: "fr", text: "French" },
				{ value: "zh", text: "Chinese" },
				{ value: "ja", text: "Japanese" },
			],
			publishing_types: [
				{ value: undefined, text: "Select private or public" },
				{ value: "private", text: "Private" },
				{ value: "public", text: "Public" },
			],
			error: null,
			domains: [],
			isUpdate: false,
			confirmMessage: "",
			categories: null,
			domainSelectInputProps: {
				id: "autosuggest__input",
				placeholder: "Select a Domain",
				class: "custom-select my-1 mr-sm-2",
			},
			filteredDomains: [],
		};
	},

	mounted() {
		this.getCategories();
	},

	methods: {
		initialize() {
			this.feed = {
				active: true,
				categories: [],
			};
			this.isUpdate = false;
			this.error = null;
		},
		show(feed) {
			if (feed) {
				this.isUpdate = true;
				this.feed = feed;
			}
			$("#feed-modal").modal(modals.show);
			this.getDomains();
		},
		close() {
			this.initialize();
			$("#feed-modal").modal("hide");
		},
		async getCategories() {
			const [data, err] = await categories.getCategories();

			if (err) {
				this.error = err.data.message;
				return;
			}

			this.categories = data;
		},

		async getDomains() {
			const [data, err] = await domains.getDomains();

			if (err) {
				this.error = err.data.message;
				return;
			}
			this.filteredDomains = [
				{
					data: data,
				},
			];
			this.domains = data;
		},
		async save(e) {
			e.preventDefault();

			let data, err;
			if (!this.isUpdate) {
				[data, err] = await feeds.create(this.feed);
			} else {
				[data, err] = await feeds.update(this.feed);
			}

			if (err) {
				this.error = err.data.message;
				return;
			}

			this.$emit("reload");
			this.close();
		},
		clickActiveButton() {
			if (this.feed.active) {
				this.confirmMessage =
					"Are you sure you want to deactivate the News Feed?";
			} else {
				this.confirmMessage =
					"Are you sure you want to activate the News Feed?";
			}
			this.$refs.confirmDialog.show();
		},
		async toggleActive() {
			const updatedFeed = JSON.parse(JSON.stringify(this.feed));
			updatedFeed.active = !this.feed.active;
			const [data, err] = await feeds.update(updatedFeed);

			this.feed = data;

			if (err) {
				this.error = err.data.message;
				return;
			}

			this.$emit("reload");
			this.close();
		},
		onInputChange(text) {
			if (text === "" || text === undefined) {
				this.filteredDomains = [
					{
						data: this.domains,
					},
				];
				return;
			}

			this.feed.domain = text;

			const filteredData = this.domains
				.filter((item) => {
					return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
				})
				.slice(0, this.limit);

			this.filteredDomains = [
				{
					data: filteredData,
				},
			];
		},
		onSelected(option) {
			this.feed.domain = option.item;
		},
	},
};
</script>

<style scoped>
/*#feed_modal .modal-lg{
  width: 900px !important;
}*/

#categories-list {
}
</style>