const user = {
  check() {
    if (!localStorage.getItem('user')) {
      localStorage.removeItem('token');
    }
    return localStorage.getItem('token')
  },

  isAdmin() {
    return this.check() && JSON.parse(localStorage.getItem('user')).roles.includes('ROLE_ADMIN');
  },

  redirect(path, to) {
    return {
      path,
      query: {
        redirect: to.name
      }
    }
  }
}

export default {
  guest(to, from, next) {
    next(user.check() ?
      user.redirect('/dashboard', to) : true)
  },

  index(to, from, next) {
    next(user.check() ?
      user.redirect('/dashboard', to) :
      user.redirect('/user/login', to))
  },

  auth(to, from, next) {
    next(user.check() ? true : user.redirect('/user/login', to))
  },

  admin(to, from, next) {
    next(user.isAdmin() ? true : user.redirect('/user/login', to))
  }
}