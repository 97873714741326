<template>
  <div class="content">
    <div class="no-padding container-fixed-lg">
      <div class="row">
        <div class="col mt-4 mb-4">
          <a @click="$router.go(-1)">
            <i class="fa fa-arrow-left"></i>&nbsp;Back
          </a>
        </div>
        <!-- col -->
      </div>
      <!-- row -->
      <div class="row">
        <div v-if="max_reached" class="alert alert-danger" role="alert">
          You have reached the maximum number of feeds. Upgrade your plan to access more feeds.
        </div>
        <div class="col-md-10 col-sm-12">
          <feed-title :feed="feed"></feed-title>
        </div>
        <div class="col-md-2 col-sm-12" v-if="!isDemoUser">
          <div v-if="feed && feed.is_user_suscribed">
            <button
              class="btn btn-danger float-right"
              @click="showUnsubscriberModal()"
            >
              <i class="fa fa-trash"></i>&nbsp;Unsubscribe
            </button>
          </div>
          <div v-else-if="feed">
            <button
              v-if="!isAdminUser"
              class="btn btn-success float-right"
              @click="showSubscriberModal()"
            >
              <i class="fas fa-pencil-alt"></i>&nbsp;Subscribe
            </button>
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row">
        <div class="col" v-if="feed">
          &nbsp;
          <div class="accordion" id="accordionExample">
            <h2 class="mb-1">
              <a
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                @click="toogleShowFeedDetails()"

              >
                Feed Details & URL
                <i class="fa"
                  v-bind:class="{ 'fa-plus-square': !showFeedDetails, 'fa-minus-square': showFeedDetails }"
                  aria-hidden="true"></i>
              </a>
            </h2>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div>
              <feed-features-table
                :feed="feed"
                :subscribed="feed.is_user_suscribed"
              ></feed-features-table>
            </div>
          </div>
        </div>
        <!-- col -->
      </div>
      <!-- row -->

      <div class="row">
        <div class="col mt-2 mb-2">
          <div
            v-if="feed && feed.is_user_suscribed && !isDemoUser"
            class="btn-group"
            role="group"
            aria-label="Basic example"
            id="manual_validation_buttons"
          >
            <p>Auto Publish &nbsp;</p>
            <span v-if="!feed.needs_validation">
              <button
                class="btn float-left bt-sm btn-success"
                @click="showEnableNeedsValidationDialog()"
              >
                On
              </button>
              <button
                class="btn float-right bt-sm"
                @click="showEnableNeedsValidationDialog()"
              >
                Off
              </button>
            </span>
            <span v-else>
              <button
                class="btn float-left bt-sm"
                @click="showDisableNeedsValidationDialog()"
              >
                On
              </button>
              <button
                class="btn float-right bt-sm btn-success"
                @click="showDisableNeedsValidationDialog()"
              >
                Off
              </button>
            </span>
          </div>
        </div>
        <!-- col -->
      </div>
      <div class="row" v-if="!isDemoUser">
        <div class="col-md-3 offset-md-9 text-right">
          <a class="btn btn-success" @click="newEmptyPost()">
            <i class="fa fa-plus" aria-hidden="true"></i> Add empty post
          </a>
        </div>
      </div>
      <!-- row -->

      <div class="row">
        <div class="col">
          <h1
            v-if="feed && feed.is_user_suscribed"
            class="examples-title"
            v-show="!loadingContent"
          >
            Your latest content
          </h1>
          <h1 v-else class="examples-title" v-show="!loadingContent">
            Demo Content Samples
          </h1>
        </div>
      </div>

      <!-- Pagination -->
      <div class="row" v-if="feedContent && feedContent.length">
        <div class="col">
          <pagination-cover
            @next="next"
            @prev="prev"
            :to_last_num="this.to"
            :next_is_active="this.next_is_active"
            :from="this.from"
            :itemsPerPage="this.itemsPerPage"
            :to="this.to"
            @changeItemsPerPage="onChangeItemsPerPage"
            @newEmptyPost="newEmptyPost"
          />
        </div>
      </div>
      <!-- Pagination -->

      <div class="row">
        <div class="col">
          <div v-show="!loading">
            <div v-if="feedContent && feedContent.length">
              <div v-for="post in feedContent" v-bind:key="post.id">
                <div v-if="feed && feed.is_user_suscribed">
                  <cover-feed-detail-post-suscribed
                    :a_feed_content="post"
                    :feed_info="feed"
                    @refreshContent="refreshContent"
                  ></cover-feed-detail-post-suscribed>
                </div>
                <div v-else>
                  <cover-feed-detail-post
                    :a_feed_content="post"
                    :feed_info="feed"
                  ></cover-feed-detail-post>
                </div>
              </div>
            </div>
            <p
              v-else-if="feed && feed.is_user_suscribed"
              class="text-center"
              v-show="!loadingContent"
            >
              <strong>
                <i class="fa fa-moon-o" aria-hidden="true"></i> There is no
                content yet for you in this feed</strong>. You have to wait until some posts are generated.
              <br />
              <br />If you think there should be posts at this time, please
              contact us at
              <a href="mailto:customer-support@narrativa.com">customer-support@narrativa.com</a> and we
              will try to solve the problem.
            </p>
            <p v-else class="text-center" v-show="!loadingContent">
              <strong>
                <i class="fa fa-moon-o" aria-hidden="true"></i> Sorry, no
                content samples available in this moment </strong
              >.
              <br />
              <br />If you are interested in this content, please contact us at
              <a href="mailto:customer-support@narrativa.com">customer-support@narrativa.com</a>.
            </p>
          </div>
          <spinner klass="centered-spinner" :loading="loadingContent" />
        </div>
        <!-- col -->
      </div>
      <!-- row -->

      <div class="row" v-if="feedContent && feedContent.length">
        <div class="col">
          <pagination-cover
            @next="next"
            @prev="prev"
            :to_last_num="this.to"
            :next_is_active="this.next_is_active"
            :from="this.from"
            :itemsPerPage="this.itemsPerPage"
            :to="this.to"
            @changeItemsPerPage="onChangeItemsPerPage"
            @newEmptyPost="newEmptyPost"
          />
        </div>
      </div>
    </div>
    <confirm-dialog
      :a_id="'unsubscribeDialog'"
      ref="unsubscribeDialog"
      :message="`You are going to <span class='underline'>unsubscribe</span> from this feed: ${feed.title}.`"
      confirmButton="Unsubscribe"
      @confirm="unsubscribe()"
    ></confirm-dialog>
    <confirm-dialog
      :a_id="'subscribeDialog'"
      ref="subscribeDialog"
      :message="`You are going to <span class='underline'>subscribe</span> to this feed: ${feed.title}.`"
      confirmButton="Subscribe"
      @confirm="subscribe()"
    ></confirm-dialog>
    <!-- Auto publish -->
    <confirm-dialog
      :a_id="'disableNeedsValidationDialog'"
      ref="disableNeedsValidationDialog"
      :message="'You are going to <strong>ENABLE the autopublicaton</strong> in this feed.<br><br>This means that the posts generated from this moment <strong>will be published in the feed automatically</strong>.<br><br>Are you sure?'"
      
      @confirm="disableNeedsValidation()"
    ></confirm-dialog>
    <confirm-dialog
      :a_id="'enableNeedsValidationDialog'"
      ref="enableNeedsValidationDialog"
      :message="'You are going to <strong>DISABLE the autopublicaton</strong> in this feed.<br><br>This means that the posts generated from this moment <strong>will not be published in the feed automatically</strong> and you will have to aprove one by one manually.<br><br>Are you sure?'"
      @confirm="enableNeedsValidation()"
    ></confirm-dialog>

    <spinner klass="centered-spinner" :loading="loading" />
  </div>
</template>

<script>
import FilterView from "./filter.vue";
import FeedFeaturesTable from "./feed-features-table.vue";
import CoverFeedTable from "./cover-feed-table.vue";
import Spinner from "../ui/spinner.vue";
import ConfirmDialog from "../ui/confirm-dialog.vue";
import CoverFeedDetailPost from "./cover-feed-detail-post.vue";
import CoverFeedDetailPostSuscribed from "./cover-feed-detail-post-suscribed.vue";
import PaginationCover from "../ui/table/pagination-cover.vue";

import feeds from "../../api/feeds.js";
import feedsContent from "../../api/feedscontent.js";

import { isAdminUser, isDemoUser } from "../../utils/user";
import users from "../../api/users";

import { sleep_time } from "../../utils/utils.js";

import FeedTitle from "./feed-title.vue";


const ITEMS_PER_PAGE = 10;

export default {
  name: "cover-feed-detail",

  components: {
    FilterView,
    Spinner,
    CoverFeedDetailPost,
    CoverFeedDetailPostSuscribed,
    PaginationCover,
    FeedFeaturesTable,
    FeedTitle,
    ConfirmDialog,
  },

  mounted() {
    this.getFeed(this.$route.params.feedId);
    this.getFeedContent(this.$route.params.feedId);
  },

  data() {
    return {
      loading: false,
      loadingContent: false,
      feed: null,
      feedContent: null,
      a_feed: null,
      currentFeed: null,
      from: ITEMS_PER_PAGE,
      to: 0,
      itemsPerPage: ITEMS_PER_PAGE,
      next_is_active: false,
      isAdminUser: isAdminUser(),
      isDemoUser: isDemoUser(),
      showFeedDetails: false,
      max_reached: false
    };
  },

  methods: {
    async getFeed(feedId) {
      this.feed = null;
      this.loading = true;
      let response;

      const user = JSON.parse(localStorage.getItem("user"));
      response = await feeds.getByIdForUser(feedId, user.id);

      const [data, err] = response;

      if (!err) {
        this.feed = data;
      }

      this.loading = false;

      return data;
    },

    async getFeedNoLoading(feedId) {
      let response;

      const user = JSON.parse(localStorage.getItem("user"));
      response = await feeds.getByIdForUser(feedId, user.id);

      const [data, err] = response;

      if (!err) {
        this.feed = data;
      }

      return data;
    },

    async getFeedContent(feedId) {
      this.feedContent = null;
      this.loadingContent = true;
      let response;

      var is_suscribed = false;

      /* waiting the feed to be loaded */
      while (!this.feed) {
        await sleep_time(200);
      }

      if (this.feed) {
        is_suscribed = this.feed.is_user_suscribed;
      } else {
        console.log("Todavía no está definido el feed");
      }

      if (is_suscribed) {
        const user = JSON.parse(localStorage.getItem("user"));
        // this.from+1 to check if the "next" button can be active
        response = await feedsContent.getFeedContentContentByTokenIgnoreValidation(
          feedId,
          user.apiKey,
          parseInt(this.from) + 1,
          parseInt(this.to)
        );
      } else {
        response = await feedsContent.getFeedContentDemoById(
          feedId,
          parseInt(this.from) + 1,
          parseInt(this.to)
        );
      }

      const [data, err] = response;
      this.loading = false;
      this.loadingContent = false;

      //that's why we load one more
      if (data && data.data && data.data.post) {
        this.next_is_active =
          data.data.post.length > parseInt(this.itemsPerPage);
      } else {
        this.next_is_active = false;
      }

      if (this.next_is_active) {
        /*console.log("There are enoguh posts to active the 'next' button");*/
        data.data.post.length = parseInt(this.itemsPerPage);
      }

      if (!err && data && data.data && data.data.post) {
        this.feedContent = data.data.post;
      } else {
        console.log("Error while reading the demo feed");
      }
    },
    showUnsubscriberModal() {
      this.$refs.unsubscribeDialog.show();
    },
    showSubscriberModal() {
      this.$refs.subscribeDialog.show();
    },

    showDisableNeedsValidationDialog() {
      this.$refs.disableNeedsValidationDialog.show();
    },

    showEnableNeedsValidationDialog() {
      this.$refs.enableNeedsValidationDialog.show();
    },

    toogleShowFeedDetails(){
      this.showFeedDetails = !this.showFeedDetails;
    },

    async unsubscribe() {
      let feedId = this.feed.id;
      let message = null;

      console.log("Unsubscribe for feed:" + feedId);

      const user_id = JSON.parse(localStorage.getItem("user")).id;
      const [data, error] = await users.unsubscribe(user_id, feedId);
      if (!error) {
        this.getFeedNoLoading(this.$route.params.feedId);
      }
      this.$router.go()
    },

    async subscribe() {
      let feedId = this.feed.id;
      let message = null;

      // console.log("Subscribe for feed:" + feedId);

      const user_id = JSON.parse(localStorage.getItem("user")).id;
      const [data, error] = await users.subscribe(user_id, feedId);
      if (!error) {
        this.getFeedNoLoading(this.$route.params.feedId);
      }

      if (error && error.status==403){
        this.max_reached=true;
        return
      }

      this.$router.go()

    },

    async disableNeedsValidation() {
      let feedId = this.feed.id;

      const user_id = JSON.parse(localStorage.getItem("user")).id;
      const [data, error] = await users.disableNeedsValidation(user_id, feedId);
      if (!error) {
        this.getFeedNoLoading(this.$route.params.feedId);
      }
    },

    async enableNeedsValidation() {
      let feedId = this.feed.id;

      const user_id = JSON.parse(localStorage.getItem("user")).id;
      const [data, error] = await users.enableNeedsValidation(user_id, feedId);
      if (!error) {
        this.getFeed(this.$route.params.feedId);
      }
    },

    refreshContent(){
      this.getFeedContent(this.$route.params.feedId);
    },

    onPageChange(dataset) {
      console.log("called to onPageChange");
      /*this.data = dataset*/
    },

    prev() {
      console.log("Prev page");
      this.from = Math.max(
        parseInt(this.itemsPerPage),
        parseInt(this.from) - parseInt(this.itemsPerPage)
      );
      this.to = Math.max(0, parseInt(this.to) - parseInt(this.itemsPerPage));
      this.getFeedContent(this.$route.params.feedId);
    },

    next() {
      console.log("Next page");
      this.from = parseInt(this.from) + parseInt(this.itemsPerPage);
      this.to = parseInt(this.to) + parseInt(this.itemsPerPage);
      this.getFeedContent(this.$route.params.feedId);
    },

    onChangeItemsPerPage(value) {
      var prev_itemsPerPage = parseInt(this.itemsPerPage);
      this.itemsPerPage = parseInt(value);

      /*Changes only the "from"*/
      this.from =
        parseInt(this.from) +
        (parseInt(this.itemsPerPage) - parseInt(prev_itemsPerPage));

      this.getFeedContent(this.$route.params.feedId);
    },

    hasNext() {
      return true;
    },

    hasPrev() {
      return true;
    },

    async newEmptyPost() {
      console.log("Called to newEmptyPost event handler");
      /*userCode,domain,language*/
      const [data, error] = await feedsContent.addNewEmptyDoc(
        JSON.parse(localStorage.getItem("user")).id,
        this.feed.domain,
        this.feed.language
      );

      if (!error) {
        this.getFeedContent(this.$route.params.feedId);
      }
    },
  },
};
</script>

<style>
.feed-title {
  font-size: 2em;
  display: inline;
}

.examples-title {
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  /*border-bottom: 1px solid #ddd;*/
}

#manual_validation_buttons {
  font-size: 10px;
  float: right;
  margin-top: 1em;
}

#manual_validation_buttons button {
  font-size: 1em;
  padding: 0em 1em 0em 1em;
}
#manual_validation_buttons p {
  font-size: 1.5em;
  margin-bottom: 0px;
}
h1 {
  color: black !important;
}

a.btn-success {
  color: #fff !important;
}
</style>