import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Narratives extends Endpoint {
  constructor() {
    super('/narratives')
    bindAll(this, [ 'filter' ])
  }

  filter(filters) {
    return this.post('/', { filters })
  }

  getFeed(feedId) {
    return this.get(`/${feedId}`)
  }
}


export default new Narratives()