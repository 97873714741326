<template>
    <div>

        <feed-table :feeds="feeds" v-show="!loading" @reload="getFeeds" />

        <spinner klass="centered-spinner" :loading="loading" />
    </div>
</template>

<script>
import FilterView from "./filter.vue";
import FeedTable from "./feed-table.vue";
import Spinner from "../ui/spinner.vue";
import feeds from "../../api/feeds.js";
import { isAdminUser } from "../../utils/user";

export default {
  name: "dashboard",

  components: {
    FilterView,
    FeedTable,
    Spinner
  },

  mounted() {
    this.getFeeds();
  },

  data() {
    return {
      loading: false,
      feeds: null,
      currentFeed: null,
      isAdminUser: isAdminUser()
    };
  },

  methods: {
    async getFeeds() {
      this.feeds = null;
      this.loading = true;
      let response;
      if (this.isAdminUser) {
        response = await feeds.getAll();
      } else {
        const user = JSON.parse(localStorage.getItem("user"));
        response = await feeds.getUserFeeds(user.id);
      }
      const [data, err] = response;
      this.loading = false;

      if (!err) {
        this.feeds = data;
      }
    }
  }
};
</script>

<style>
.feeds-view {
  min-height: 60vh;
}

table {
  border: 1px solid #eaeaea;
}
</style>