<template>
  <div class="content">
    <div class="no-padding container-fixed-lg">
      <div class="row">
        <div class="col mt-4 mb-4">
          <a @click="$router.go(-1)">
            <i class="fa fa-arrow-left"></i>&nbsp;Back
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h1>Your Profile</h1>         
        </div>
      </div>
      <div class="row" v-if="!isDemoUser">
        <!-- row -->

        <div class="col">
          <alert v-bind:message="message" v-bind:type="status"></alert>

          <alert :message="error" type="error" v-for="error in errors" v-bind:key="error" />

          <form role="form" autocomplete="off" @submit="update" id="profile-form">
            <h6 class="mb-4">Basic Information</h6>
            <div class="form-group-attached">
              <div class="row clearfix">
                <div class="col-md-6">
                  <div class="form-group form-group-default">
                    <label>First name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="firstName"
                      v-model="user.firstName"
                      spellcheck="false"
                      
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group form-group-default">
                    <label>Last name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="lastName"
                      v-model="user.lastName"
                      spellcheck="false"
                      
                    />
                  </div>
                </div>
              </div>
              <div>
                <div class="form-group form-group-default">
                  <label>Email</label>
                  <input type="text" class="form-control" name="email" v-model="user.email" />
                </div>
              </div>
              <div>
                <div class="form-group form-group-default">
                  <label>Token</label>
                  <input
                    type="text"
                    class="form-control"
                    name="token"
                    v-model="user.apiKey"
                    readonly
                  />
                </div>
              </div>
            </div>

            <h6 class="mt-4 mb-4">Billing Information</h6>
            <div class="form-group-attached">
              <div class="form-group form-group-default">
                <label>Company</label>
                <input
                  type="text"
                  class="form-control"
                  name="billingName"
                  v-model="user.billingName"
                  
                />
              </div>

              <div class="form-group form-group-default">
                <label>Billing Address</label>
                <input
                  type="text"
                  class="form-control"
                  name="billingAddress1"
                  v-model="user.billingAddress1"
                  
                />
              </div>

              <div class="form-group form-group-default">
                <label>Billing Address 2</label>
                <input
                  type="text"
                  class="form-control"
                  name="billingAddress2"
                  v-model="user.billingAddress2"
                  
                />
              </div>

              <div class="row clearfix">
                <div class="col-md-4">
                  <div class="form-group form-group-default">
                    <label>Billing Country</label>
                    <input
                      type="text"
                      class="form-control"
                      name="billingCountry"
                      v-model="user.billingCountry"
                      
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group form-group-default">
                    <label>Billing Zip Code</label>
                    <input
                      type="text"
                      class="form-control"
                      name="billingZip"
                      v-model="user.billingZip"
                      
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group form-group-default">
                    <label>Billing City</label>
                    <input
                      type="text"
                      class="form-control"
                      name="billingCity"
                      v-model="user.billingCity"
                      
                    />
                  </div>
                </div>
              </div>

              <div class="form-group form-group-default">
                <label>VAT Number</label>
                <input
                  type="text"
                  class="form-control"
                  name="billingVat"
                  v-model="user.billingVat"
                  
                />
              </div>
              <div class="form-group form-group-default" style="text-align: right;" v-if="user.stripe_customer_id">
                <button @click="updatePayment" type="button">
                  Edit payment
                </button>
              </div>
            </div>
            <div class="row mt-4">
            <div class="col-lg-4 ml-auto">
              <button
                type="submit"
                class="btn btn-primary btn-cons btn-lg btn-block"
                :disabled="loading"
              >
                <span>Update</span>
                <spinner v-bind:loading="loading" klass="action-spinner"></spinner>
              </button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import consts from "../consts";

import users from "../../api/users";

import FormInput from "../ui/form-input.vue";
import Alert from "../ui/alert.vue";
import Spinner from "../ui/spinner.vue";

import PaymentMethods from "./payment-methods.vue";
import { validateForm, validate } from "../../utils/validation.js";

import stripe_payment from "../../api/stripe_payment";
import { isDemoUser } from "../../utils/user";

export default {
  name: "profile",

  data() {
    return {
      loading: false,

      message: null,
      status: null,

      confirmation: true,
      user: JSON.parse(localStorage.getItem("user")),
      data: {},
      formError: false,
      errors: [],
      isDemoUser: isDemoUser(),
    };
  },

  components: {
    Alert,
    Spinner,
    FormInput,
    PaymentMethods
  },

  computed: {
    disabled() {
      return this.loading || !this.confirmation || this.formError;
    }
  },

  methods: {
    async update(e) {
      e.preventDefault();

      const errors = validateForm(e.target);
      this.errors = errors;

      if (errors.length != 0) return;

      // const password = e.target.querySelector("[name=password]").value;
      // if (password.length) {
      //   const [validated, validationError] = validate(password);
      //   if (!validated) {
      //     this.errors = [validationError];
      //     return;
      //   }
      // }
      
      this.loading = true;
      const [data, err] = await users.updateProfile(this.user);
      this.loading = false;

      if (err) {
        this.message = consts.REQUEST_ERR;
        this.status = "danger";
        return;
      }

      const { status, message, user } = data;

      if (status == "failure") {
        this.error = message;
        this.status = "danger";
        return;
      }

      // localStorage.setItem('user', JSON.stringify(this.user))
      this.$store.dispatch("updateUser", this.data);

      this.status = "success";
      this.message = "Profile updated successfully";
      this.data = {};

      const { query } = this.$router.currentRoute;
      const next = query.next;

      if (next) {
        setTimeout(() => {
          this.$router.push(next);
        }, 250);
      }
    },

    updateForm(e) {
      const { name, value } = e.target;
      this.user[name] = value;
      this.data[name] = value;

      if (name === "password") {
        if (!value.length) {
          this.errors = [];
          return;
        }

        this.errors = [validate(value)[1]];
      }
    },

    async updatePayment(e){
      const response = await stripe_payment.customer_portal({stripe_customer_id: this.user.stripe_customer_id})
      if (response[0]){
        window.location.href = response[0].url;
      }

    },
  }
};
</script>

<style scoped>
.form-control[readonly] {
  color: #2c2d2f;
}

label {
  margin-bottom: 1em;
  color:#888;
}
</style>