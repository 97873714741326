<template>
  <div class="content">
    <div class="no-padding container-fixed-lg">
      


        

        
      </div>
    </div>
</template>

<script>
  
  import Spinner from '../ui/spinner.vue'
  import feeds from '../../api/feeds.js';
  

  export default {
    name: 'dashboard',

    components: {
      Spinner
    },

    mounted() {
      this.getFeeds(null);
    },

    data() {
      return {
        loading: false,
        feeds: null,
        currentFeed: null,
        isAdminUser: isAdminUser(),
      }
    },

    methods: {
      async getFeeds() {
        this.feeds = null;
        this.loading = true;
        let response;
        response = await feeds.getAllPublic();
        
        const [data, err] = response;
        this.loading = false;

        if (!err) {
          this.feeds = data;
        }
      },
    }
  }
</script>

<style>
  .feeds-view {
    min-height: 60vh;
  }

  table {
    border: 1px solid #eaeaea;
  }
</style>