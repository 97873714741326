<template>
  <div class="message-progress">
    <spinner :loading="!message" klass="m-spinner" />
    <span>{{ _message }}</span>
  </div>
</template>

<script>
  import Spinner from './spinner.vue'

  export default {
    props: ['message'],

    components: {
      Spinner
    },

    computed: {
      _message() {
        return this.message || "Loading..."
      }
    }
  }
</script>

<style>
  .message-progress {
    padding: 30px 20px;
    border: 1px solid #eaeaea;
    height: 80px;
    display: flex;
    max-width: 275px;
    min-width: 250px;

    position: absolute;
    left: 40%;
    top: 35%;
  }

  .m-spinner {
    margin: 0 10px 0 0 !important;
    position: relative !important;
  }
</style>