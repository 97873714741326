import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Users extends Endpoint {
  constructor() {
    super('/users')
    bindAll(this, [
      'signup',
      'login',
      'forgot',
      'register',
      'resetPassword',
      'updateProfile',
      'verify',
      'getUserInfo',
      'getUsers',
      'createUser',
      'updateUser',
      'deleteUser',
      'subscribe',
      'unsubscribe',
      'getUsageReport',
      'getUsageReportHistory',
      'getUsageReportHistoryCurrentMonth',
      'getPlans',
      'subscribePlan',
      'subscribePlanStripe',
      'unsubscribePlans',
      'active',
    ])
  }

  signup(data) {
    return this.post('/signup', data)
  }
  login(data) {
    return this.post('/login', data)
  }

  register(data) {
    return this.post('/register', data)
  }

  forgot(data) {
    return this.post('/pwd_reset_request', data)
  }

  resetPassword(data) {
    return this.post('/pwd_reset', data)
  }

  updateProfile(data) {
    const userId = data.id
    return this.put(`/${userId}`, data)
  }

  getSubscriptions(userId) {
    return this.get(`/${userId}/subscriptions`)
  }

  getSubscriptionInvoices(subscriptionId) {
    return this.get(`/subscriptions/${subscriptionId}/invoices`);
  }

  verify(email) {
    return this.post('/verify', { email })
  }

  getUserInfo(userId) {
    return this.get(`/${userId}`);
  }

  getUsageReport(userId) {
    return this.get(`/${userId}/usage_report`);
  }

  getUsageReportHistory(userId) {
    return this.get(`/${userId}/usage_report_history`);
  }
  getUsageReportHistoryCurrentMonth(userId) {
    return this.get(`/${userId}/usage_report_history_current_month`);
  }

  getUsageReportHistoryPastMonths(userId) {
    return this.get(`/${userId}/usage_report_history_past_months`);
  }

  getPlans(userId) {
    return this.get(`/${userId}/plans`);
  }

  getUsers() {
    return this.get('');
  }

  createUser(user) {
    return this.post('', user);
  }

  updateUser(userId, user) {
    return this.put(`/${userId}`, user);
  }

  updatePassword(userId, data) {
    return this.put(`/change_password/${userId}`, data);
  }

  deleteUser(userId) {
    return this.delete(`/${userId}`);
  }

  subscribe(userId, feedId) {
    return this.post(`/${userId}/subscribe/${feedId}`);
  }

  subscribePlan(userId, planId) {
    return this.post(`/${userId}/plans/${planId}`);
  }

  subscribePlanStripe(userId, planId, periodicity) {
    return this.post(`/${userId}/stripe_plans/${planId}/payment/${periodicity}`);
  }

  subscribeTrialPlanStripe(userId, planId, periodicity) {
    return this.post(`/${userId}/stripe_trial_plans/${planId}/payment/${periodicity}`);
  }

  unsubscribe(userId, feedId) {
    return this.post(`/${userId}/unsubscribe/${feedId}`);
  }

  unsubscribePlans(userId) {
    return this.delete(`/${userId}/plans`);
  }


  disableNeedsValidation(userId, feedId) {
    return this.delete(`/${userId}/needs_validation/${feedId}`);
  }

  enableNeedsValidation(userId, feedId) {
    return this.put(`/${userId}/needs_validation/${feedId}`);
  }

  active() {
    return this.post('/active');
  }
}

const users = new Users();
export default users
