<template>
  <div class="client-status">
    <div class="client-status-root">
      <div class="mb-4">
        <div
          class="card mb-0"
          v-bind:class="{
            suscription_limit:
              usage_report.has_user_pass_the_plan_limit == true,
          }"
        >
          <div class="card-header">My Subscription</div>
          <div v-if="!plan" class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <h1 class="mt-4 mb-4 text-center">No Subscription Selected</h1>
                <a
                  @click="$router.push('/manage-plans')"
                  class="btn btn-warning mb-2"
                  >Subscribe to a Plan!</a
                >
              </div>
            </div>
          </div>
          <!-- card-body else -->
          <div v-else class="card-body">
            <div class="row">
              <div class="col-12">
                <h1 class="text-center">
                  {{ plan.name }} Plan
                </h1>
                <!-- <h1 v-if="!usage_report.has_user_pass_the_plan_limit">
                  {{ plan.name }} Plan
                </h1>
                <h1 v-else class="limit_reached">
                  <i class="fa fa-bell" aria-hidden="true"></i>
                  Limit Reached for {{ plan.name }} Plan
                  <br />
                </h1>
                <p v-if="plan && usage_report" class="text-center">
                  <span
                    class="posts-count"
                    v-if="usage_report.published_docs && plan.limit"
                    >{{ usage_report.published_docs.toLocaleString() }} /
                    {{ plan.limit.toLocaleString() }}</span
                  >
                  <br />
                  posts/{{ plan.interval }}
                </p>-->
                <p class="text-center">Subscribed to {{usage_report.subscriptions}} feed<span v-if="usage_report.subscriptions>1">s</span> <span v-if="plan.id!=4">of {{ usage_report.max_subscriptions }}</span></p>
                <!-- <p
                  v-if="usage_report.has_user_pass_the_plan_limit"
                  class="mt-3 text-center"
                >
                  <strong>Not publishing any post since:</strong>
                  <br />
                  {{
                    formatDate(
                      usage_report.last_date_allowed_because_plan_limit
                    )
                  }}
                </p> -->
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center">
                <p
                  v-if="!usage_report.has_user_pass_the_plan_limit"
                  id="manage_plan_link"
                >
                  <a
                    @click="$router.push('/manage-plans')"
                    class="btn btn-warning"
                    >Manage My Plan</a
                  >
                </p>
                <p v-else id="manage_plan_link">
                  <a
                    @click="$router.push('/manage-plans')"
                    class="btn btn-warning"
                  >
                    <i class="fa fa-arrow-right" aria-hidden="true"></i> Upgrade
                    My Plan
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <!-- card-body -->
        </div>
        <!-- card -->
      </div>
      <!-- Updates Row -->
      <div>
        <div class="card mt-0">
          <div class="card-header">Updates</div>
          <div class="card-body card-updates">
            <div
              class="row"
              v-for="update in updates"
              v-bind:key="update.title"
            >
              <div class="col-12">
                <!-- Lista de updates -->
                <!-- Update Type -->
                <h2>{{ update.type }}</h2>
                <!-- Title -->
                <h1>{{ update.title }}</h1>
                <!-- content -->
                <p v-html="update.content">{{ update.content }}</p>
              </div>
            </div>
            <!-- col-12 -->
          </div>
          <!-- row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isAdminUser, isEnterprise } from "../../utils/user";
import users from "../../api/users.js";
import updates from "../../api/updates.js";
import { formatDate } from "../../utils/utils.js";

export default {
  name: "tabs",
  computed: {
    usage_report() {
      return this.$store.state.userUsageReport;
    },
    plan() {
      return this.$store.state.plan;
    },
  },

  mounted() {
    this.$store.dispatch("updateUsageReport", this.$store.getters.user.id);
    this.$store.dispatch("updateUserPlanInfo", this.$store.getters.user.id);
    this.getLatestsUpdates();
  },

  data() {
    return {
      isAdminUser: isAdminUser(),
      isEnterprise: isEnterprise(),
      updates: null,
    };
  },

  methods: {
    formatDate(stringDate) {
      return formatDate(stringDate);
    },

    async getLatestsUpdates() {
      this.loading = true;
      let response;

      response = await updates.getLatestsUpdates();

      const [data, err] = response;
      this.loading = false;

      if (!err) {
        this.updates = data;
      }
    },
  },
};
</script>

<style scoped>
.tabs_parent_bg {
  background-color: #157ef3;
  color: white;
}

.card-header {
  background-color: #157ef3;
  padding: 9px !important;
  font-size: 1.1em !important;
  min-height: 0px !important;
  font-weight: bold;
  color: white !important;
}


@media (min-width: 992px) {
  .client-status-root {
    margin-top: 17.6rem;
  }

  .manage-plans .client-status-root {
    margin-top: 12.6rem;
  }
}

.card-header {
  font-size: 1.2em;
  padding: 10px;
}

#manage_plan_link {
  font-weight: bold;
  margin-top: 1em !important;
}

#manage_plan_link:hover {
  text-decoration: underline;
}

h1.limit_reached {
  font-weight: bold;
  color: red !important;
  text-align: center;
}

.suscription_limit {
  border-color: 1px solid red;
  background-color: #ffeaee;
}

.suscription_limit .card-header {
  background-color: #b10000;
  color: white;
  border: 1px solid #ff0000;
}

.suscription_limit .card-body {
  border: 1px solid #ff0000;
  border-top: 0px;
}

.card {
  /*border-color: #ccc;*/
  border: 0px;
}
.card-updates h2 {
  color: #157ef3;
  font-weight: bold;
  font-size: 1em !important;
  line-height: 0.7em;
}

.card-header {
  border: 1px solid #157ef3;
}

.card-body {
  border: 1px solid #157ef3;
}

.card-updates h1 {
  font-weight: bold;
  font-size: 1.2em !important;
  line-height: 1.1em;
}

.card-updates p {
  font-size: 1em !important;
  line-height: 1.1em;
}

.posts-count {
  font-size: 1.2em;
  font-weight: bold;
}
</style>