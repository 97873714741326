<template>
  <div class="mt-4">
    <h1 class="mb-4">Feeds Management</h1>
    <div v-if="isAdminUser" class="form-group mb-4 mt-4">
      <button class="btn btn-success" @click="createNewsFeed">Add New Feed</button>
    </div>

    <div class="form-group mb-4">
      <input type="text" v-model="search" class="form-control" placeholder="Search for..." />
    </div>

    <div>
      <table class="table table-hover table-responsive-block mb-4">
        <thead>
          <tr>
            <th>Cover</th>
            <th @click="sort('title')">Title</th>
            <th @click="sort('type')">Type</th>
            <th @click="sort('subtype')">Subtype</th>
            <th @click="sort('public')" class="text-center">Public</th>
            <th @click="sort('language')" class="text-center">Language</th>
            <th @click="sort('tags')">Tags</th>
            <th>Control</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(feed, index) in data" v-bind:class="{'feed_disabled': feed.active==false}">
            <td class="v-align-middle">
              <img v-bind:src="feed.img_cover" class="feed_img_cover" v-if="feed.img_cover!==null && feed.img_cover!==''" />
              <img v-else src="/assets/img/no_preview.png" class="feed_img_cover"/>
            </td>
            <td class="v-align-middle">{{ feed.title }}</td>
            <td class="v-align-middle">{{ feed.type }}</td>
            <td class="v-align-middle">{{ feed.subtype }}</td>
            <td class="v-align-middle text-center">{{ feed.publish_type }}</td>
            <td class="v-align-middle text-center">{{ feed.language}}</td>
            <td class="v-align-middle">{{ feed.tags }}</td>

            <td class="v-align-middle">
              <button
                v-if="!isAdminUser"
                class="btn btn-success"
                :data-index="index"
                @click="showDetails(feed.id)"
              >Details</button>
              <button
                v-if="isAdminUser"
                class="btn btn-success"
                @click="updateNewsFeed(feed)"
              >Update</button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-4">
        <table-pagination :data="sortedFeefs" @pageChange="onPageChange" />
      </div>
      <feed-detail-container ref="feedDetail" @reload="reload" />
      <feed-modal ref="feedModal" @reload="reload" />
    </div>
  </div>
</template>

<script>
import feeds from "../../api/feeds.js";
import { isAdminUser } from "../../utils/user";

import TablePagination from "../ui/table/pagination.vue";
import FeedDetailContainer from "./feeds/detail-modal.vue";
import FeedModal from "./feeds/feed-modal.vue";

export default {
  name: "feeds-table",
  props: ["feeds"],

  components: {
    FeedModal,
    TablePagination,
    FeedDetailContainer
  },

  data() {
    return {
      feed: null,
      data: [],
      search: "",
      currentSort: "type",
      currentSortDir: "asc",
      isAdminUser: isAdminUser()
    };
  },

  watch: {
    $route(to, from) {
      this.handleModal(to, from);
    }
  },

  mounted() {
    this.handleModal(this.$router.currentRoute, null);
  },

  computed: {
    feedId() {
      const { feed } = this;
      return feed ? feed.narrativaID : null;
    },
    filteredItems() {
      if (this.feeds != null) {
        return this.feeds.filter(item => {
          var searched =
            item.type.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            item.subtype.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
          return searched;
        });
      } else {
        return this.data.filter(item => {
          var searched =
            item.type.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            item.subtype.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
          return searched;
        });
      }
    },
    sortedFeefs: function() {
      if (this.feeds != null) {
        var searched = this.feeds.filter(item => {
          var items =
            item.type.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            item.subtype.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
          return items;
        });

        return searched.sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (
            a[this.currentSort].toLowerCase() <
            b[this.currentSort].toLowerCase()
          )
            return -1 * modifier;
          if (
            a[this.currentSort].toLowerCase() >
            b[this.currentSort].toLowerCase()
          )
            return 1 * modifier;
          return 0;
        });
      }
    }
  },

  methods: {
    handleModal(to, from) {
      const path = to.path;
      if (path.indexOf("/feed/") != -1) {
        const { feedId } = to.params;
        this.$refs.feedDetail.show(feedId);
      }
    },

    reload() {
      this.$emit("reload");
    },

    showDetails(feedId) {
      this.$refs.feedDetail.show(feedId);
    },

    onPageChange(dataset) {
      this.data = dataset;
    },

    createNewsFeed() {
      this.$refs.feedModal.show();
    },

    updateNewsFeed(feed) {
      this.$refs.feedModal.show(feed);
    },

    sort: function(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    }
  }
};
</script>

<style>
.status-subscribed {
  font-weight: bold;
  color: green;
}

.feed_img_cover {
  background-color: #eee;
  width: 3em;
  height: 3em;
  border: 1px solid #ccc;
  object-fit: cover;
}

.feed_disabled td {
  background-color: #efefef !important;
  color: grey;
}
</style>