<template>
  <ul class="list-group">
    <li class="list-group-item d-flex justify-content-between align-items-center cc-item"
        v-for="card in cards" :class="_active(card)" @click="select(card)">

      <span>
        <i class="fa brand-icon" :class="getIcon(card.brand)"/>

        <i class="fa fa-check" v-if="card.cardId == primary"/>
      </span>

      <span class="cc-brand">{{ card.brand }}</span>

      <span>
        xxxx-xxxx-xxxx-{{ card.last4 }}
      </span>

      <span>{{ card.expMonth }}/{{ card.expYear }}</span>
    </li>
  </ul>
</template>

<script>
  export default {
    props: ['cards', 'primary'],
    data() {
      return {
        active: null
      }
    },

    methods: {
      _active(card) {
        const active = this.active
        const cardId = card.cardId;

        return {
          "active-card": active == cardId
        }
      },

      getIcon(brand) {
        let icon = brand.trim().toLowerCase()

        if (brand == 'American Express') {
          icon = 'amex'
        }

        return `fa-cc-${icon}`
      },

      select(card) {
        const {cardId} = card;
        const {primary} = this

        this.active = cardId
        const selected = primary == cardId ? null : cardId
        this.$emit('selected', selected)
      }
    }
  }
</script>

<style>
  .cc-brand {
    width: 125px;
  }

  .cc-item {
    cursor: pointer;
    transition: .1s ease;
  }

  .cc-item:hover {
    background: #fafafa;
  }

  .active-card {
    border: 2px solid #fedd4e;
  }
</style>