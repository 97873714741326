<template>
  <div class="container">
    <div class="client-status-root">
      <!-- Updates Row -->
      <div class="row mt-4 pl-4">
        <div class="card mt-0">
          <div class="card-header">Updates</div>
          <div class="card-body card-updates">
            <div
              class="row"
              v-for="update in updates"
              v-bind:key="update.title"
            >
              <div class="col-12">
                <!-- Lista de updates -->
                <!-- Update Type -->
                <h2>{{ update.type }}</h2>
                <!-- Title -->
                <h1>{{ update.title }}</h1>
                <!-- content -->
                <p v-html="update.content">{{ update.content }}</p>
              </div>
            </div>
            <!-- col-12 -->
          </div>
          <!-- row -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isAdminUser } from "../../utils/user";
import users from "../../api/users.js";
import updates from "../../api/updates.js";
import { formatDate } from "../../utils/utils.js";

export default {
  name: "tabs",

  created() {
    /*this.$store.dispatch('updateUsageReport',this.$store.getters.user.id)
      this.$store.dispatch('updateUserPlanInfo',this.$store.getters.user.id)*/
  },

  computed: {
    usage_report() {
      return this.$store.state.userUsageReport;
    },
    plan() {
      return this.$store.state.plan;
    },
  },

  mounted() {
    this.$store.dispatch("updateUsageReport", this.$store.getters.user.id);
    this.$store.dispatch("updateUserPlanInfo", this.$store.getters.user.id);
    this.getLatestsUpdates();
  },

  data() {
    return {
      isAdminUser: isAdminUser(),
      updates: null,
    };
  },

  methods: {
    formatDate(stringDate) {
      return formatDate(stringDate);
    },

    async getLatestsUpdates() {
      this.loading = true;
      let response;

      response = await updates.getLatestsUpdates();

      const [data, err] = response;
      this.loading = false;

      if (!err) {
        this.updates = data;
      }
    },
  },
};
</script>

<style scoped>
.tabs_parent_bg {
  background-color: #157ef3;
  color: white;
}

.card-header {
  background-color: #157ef3;
  padding: 9px !important;
  font-size: 1.1em !important;
  min-height: 0px !important;
  font-weight: bold;
  color: white !important;
}

.client-status-root {
  margin-top: 6em;
}

.card-header {
  font-size: 1.2em;
  padding: 10px;
}

#manage_plan_link {
  font-weight: bold;
  margin-top: 1em !important;
}

#manage_plan_link:hover {
  text-decoration: underline;
}

h1.limit_reached {
  font-weight: bold;
  color: red !important;
  text-align: center;
}

.suscription_limit {
  border-color: 1px solid red;
  background-color: #ffeaee;
}

.suscription_limit .card-header {
  background-color: #b10000;
  color: white;
  border: 1px solid #ff0000;
}

.suscription_limit .card-body {
  border: 1px solid #ff0000;
  border-top: 0px;
}

.card {
  /*border-color: #ccc;*/
  border: 0px;
}
.card-updates h2 {
  color: #157ef3;
  font-weight: bold;
  font-size: 1em !important;
  line-height: 0.7em;
}

.card-header {
  border: 1px solid #157ef3;
}

.card-body {
  border: 1px solid #157ef3;
}

.card-updates h1 {
  font-weight: bold;
  font-size: 1.2em !important;
  line-height: 1.1em;
}

.card-updates p {
  font-size: 1em !important;
  line-height: 1.1em;
}

.posts-count {
  font-size: 1.2em;
  font-weight: bold;
}
</style>