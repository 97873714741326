<template>
  <div class="feed-filters">
    <div class="feed-filters__header">
      <span @click="toggleFilters()">
        <i class="fas fa-times"></i>
      </span>
    </div>
    <div class="filter-list" v-for="(filter) in filters" v-bind:key="filter.code">
      <h3>{{filter.title}}</h3>
      <ul>
        <li v-for="(value) in filter.values" v-bind:key="value.id">
          <input
            type="checkbox"
            v-bind:id="value.id + '_cbox'"
            @change="check($event)"
            v-model="checkedCategories"
            :value="filter.filter_field +'$'+value.id"
          />
          <label v-bind:for="value.id + '_cbox'">{{value.title}}</label>
        </li>
      </ul>
    </div>
    <!-- Filter-list -->
  </div>
</template>

<script>
import feeds from "../../api/feeds.js";
import FeedDetailContainer from "./feeds/detail-modal.vue";
import { toggleFiltersMenu } from "../../utils/utils";

export default {
  name: "catalog-cover-feeds-table-filter",
  props: ["the_feeds"],

  components: {
    FeedDetailContainer
  },

  data() {
    return {
      filters: null
    };
  },

  mounted() {
    this.getFilters();
  },
  beforeDestroy () {
    this.resetFilters();
  },

  computed: {
    checkedCategories: {
      get() {
        return this.$store.state.catalog.checkedCategories;
      },
      set(value) {
        this.$store.dispatch("updateCatalogCheckedCategories", value);
      }
    }
  },

  methods: {
    toggleFilters () {
      toggleFiltersMenu();
    },
    resetFilters() {
      this.checkedCategories = [];
      this.$emit("filterChange", new Set());
    },

    getListOfCheckedFilters() {
      var list_of_filters_by_category = [];
      var sortedCheckedCategories = this.checkedCategories.slice().sort();

      var result = {};

      var current_sortedkey = "";
      for (let i = 0; i < sortedCheckedCategories.length; i++) {
        var condition = sortedCheckedCategories[i].split("$");
        var condition_field = condition[0];
        var condition_value = condition[1];

        if (condition_field !== current_sortedkey) {
          current_sortedkey = condition_field;
          result[condition_field] = [];
        }
        result[condition_field].push(sortedCheckedCategories[i]);
      }

      return result;
    },

    check(e) {
      console.log(this.checkedCategories);

      if (this.checkedCategories.length == 0) {
        console.log("No conditions");
        this.$emit("filterChange", new Set());
        return;
      }

      /* copy array*/
      var feeds = this.the_feeds.slice();
      let setOfFeedIdToRemove = new Set();

      var dictOfCheckedFilters = this.getListOfCheckedFilters();

      for (let j = 0; j < feeds.length; j++) {
        var anyFilterPassed = true;

        /* For each type of filter */
        for (let key in dictOfCheckedFilters) {
          var anyFilterPassedForThisGroupOfFilters = false;

          for (let i = 0; i < dictOfCheckedFilters[key].length; i++) {
            var condition = dictOfCheckedFilters[key][i].split("$");
            var condition_field = condition[0];
            var condition_value = condition[1];

            /* Check in array of strings*/
            if (
              feeds[j][condition_field] &&
              feeds[j][condition_field].constructor === Array &&
              feeds[j][condition_field].includes(condition_value)
            ) {
              anyFilterPassedForThisGroupOfFilters =
                true || anyFilterPassedForThisGroupOfFilters;
            } else if (
              feeds[j][condition_field] &&
              typeof feeds[j][condition_field] == "string" &&
              feeds[j][condition_field] == condition_value
            ) {
              anyFilterPassedForThisGroupOfFilters =
                true || anyFilterPassedForThisGroupOfFilters;
            } else {
              anyFilterPassedForThisGroupOfFilters =
                false || anyFilterPassedForThisGroupOfFilters;
            }
            if (anyFilterPassedForThisGroupOfFilters) {
              console.log(
                "anyFilterPassedForThisGroupOfFilters " +
                  anyFilterPassedForThisGroupOfFilters +
                  " for:" +
                  feeds[j]["id"]
              );
            }
          }
          anyFilterPassed =
            anyFilterPassed && anyFilterPassedForThisGroupOfFilters;
        }

        if (!anyFilterPassed) {
          setOfFeedIdToRemove.add(feeds[j]["id"]);
        }
      }

      var final_list = [];
      for (let j = 0; j < feeds.length; j++) {
        if (!setOfFeedIdToRemove.has(feeds[j]["id"])) {
          final_list.push(feeds[j]);
        }
      }

      this.$emit("filterChange", setOfFeedIdToRemove);
    },

    async getFilters() {
      this.filters = null;
      this.loading = true;
      let response;
      response = await feeds.getFeedsFilters();

      const [data, err] = response;
      this.loading = false;

      if (!err) {
        this.filters = data;
      }
    }
  }
};
</script>



<style>
.feed-filters {
  background-color: #eee !important
}

.feed-filters__header {
  cursor: pointer;
  display: none;
}

.filter-list {
  border-bottom: 1px solid #ccc;
}

.filter-list label {
  cursor: pointer;
}

.filter-list h3 {
  font-size: 1.1em !important;
  font-weight: bold;
}

.filter-list ul {
  list-style-type: none;
  padding-left: 0px;
}

.filter-list ul li input {
  margin-right: 1em;
}

@media (max-width: 992px) {
  .feed-filters {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    position: fixed;
    top: 66px;
    left: 0;
    transform: translateX(-280px);
    z-index: 99;
    width: 280px;
    bottom: 0;
    background: #000;
    transition: transform .5s ease;
    padding: 0 .6rem;
    overflow-y: scroll;
  }

  .feed-filters.open {
    transform: translateX(0);
  }

  .feed-filters__header {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
  }
}
</style>