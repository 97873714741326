import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Updates extends Endpoint {
  constructor() {
    super('/signup');
    bindAll(this, [ 'signupEmail' ]);
  }

  signupEmail(email) {
    return this.post('',{"email":email});
  }

}


export default new Updates()