<template>
  <div class="col-lg-7">
    <div class="card card-transparent">
      <div class="card-block">
        <div class="d-flex download-feeds" v-if="subscribed">
          <h3 class="flex-grow-1">Select narratives to download</h3>
          <button class="btn btn-success mb-4 float-right" @click="toggledownload">
            <i class="fa fa-download"/> Download Feed
          </button>
          <!-- <button class="btn btn-danger mb-4 ml-1" v-show="showDownloadView" @click="cancel">
            <i class="fa fa-close"/>
            Cancel
          </button> -->
        </div>
        <div class="table-responsive">
          <div class="dataTables_wrapper no-footer narratives-table">
            <table class="table table-striped header-fixed" id="tblGrid">
              <thead id="tblHead">
                <tr>
                  <th v-if="subscribed">
                    <checkbox name="select-all" @onChange="selectAll" :checked="isAllSelected"/>
                  </th>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Language</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr data-toggle="tooltip" data-placement="bottom" data-html="true"
                    :title="getPreview(nrtv)" v-for="nrtv in narratives">
                  <td v-if="subscribed">
                    <checkbox type="checkbox" :name="nrtv._id" :checked="isNrtvSeclected(nrtv._id)"
                              :data="nrtv._id" @onChange="onNrtvSelected"/>
                  </td>
                  <td>{{ nrtv.id }}</td>
                  <td>{{ nrtv.title }}</td>
                  <td>Spanish</td>
                  <td>{{ nrtv.date }}</td>
                </tr>
              </tbody>
            </table>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Checkbox from '../../ui/checkbox.vue';
  export default {
    name: 'narratives-table',
    props: ['narratives', 'subscribed'],
    components: {
      Checkbox
    },

    mounted() {
      console.log('mounted')
    },

    data() {
      return {
        selected: [],
        allSelected: false
      }
    },

    computed: {
      isAllSelected() {
        const _allSelected = this.selected.length == this.narratives.length
        return this.allSelected || _allSelected;
      },

      isSelectedEmpty() {
        return this.selected.length == 0;
      }
    },

    methods: {
      getMeta(narrative) {
        const { metadata } = narrative
        return meta[Object.keys(metadata)[0]]
      },

      getPreview(narrative) {
        return `<h2 class='preview-title'>${narrative.title}</h2>
                ${ narrative.content }`;
      },

      selectAll(e) {
        const prevSelected = this.isAllSelected;
        this.allSelected = e.checked;

        if (prevSelected)
          this.selected = [];

      },

      isNrtvSeclected(id) {
        const selected = this.selected.indexOf(id) != -1;
        return selected || this.allSelected;
      },

      onNrtvSelected(e) {
        const id = e.dataset.attr;
        const selected = this.selected;
        const exists = selected.indexOf(id) != -1;

        // if (this.tooltips) {
        //   this.toggleTooltips();
        // }

        if (this.isNrtvSeclected(id)) {
          this.selected = selected.filter(item => item !== id);
          this.allSelected = false;
          return;
        }

        this.selected.push(id);
      },

      toggleTooltips() {
        const tstate = this.tooltips ? 'disable' : 'enable';
        $('[data-toggle="tooltip"]').tooltip(tstate);
      },

      /*
      cancel() {
        this.allSelected = false;
        this.showDownloadView = false;
        this.selected = [];

        $('[data-toggle="tooltip"]').tooltip('disable');
      },*/

      toggledownload() {
        this.$emit('onDownload', this.selected)
      }
    },

    mounted() {
      $('[data-toggle="tooltip"]').tooltip({
        animation: false,
        template: '<div class="tooltip tooltip-narrative-preview"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
      });
    }
  }
</script>

<style>
  .narratives-table {
    max-height: 500px;
  }

  .tooltip-narrative-preview .tooltip-inner{
    text-align: left !important;
    max-width: 450px !important;
    background: white;
    color: black;
    border: 1px solid #eaeaea;
  }

  .preview-title {
    text-align: center !important;
    font-size: 30px;
  }

  .download-feeds > h3 {
    line-height: 20px;
    flex-grow: 1;
  }

  .tooltip.tooltip-bottom .tooltip-inner::before {
    border-bottom-color: #eaeaea;
  }
</style>