<template>
  <div class="dropdown-menu dropdown-menu-right profile-dropdown" role="menu">
    <router-link v-if="!ENABLED" to="/dashboard/profile" class="dropdown-item">
      <i class="fa fa-user" aria-hidden="true"></i> My Profile
    </router-link>

    <router-link v-if="ENABLED && !isDemoUser" to="/dashboard/edit_profile" class="dropdown-item">
      <i class="fa fa-user" aria-hidden="true"></i> My Profile
    </router-link>

    <router-link v-if="ENABLED && !isDemoUser" to="/dashboard/change_password" class="dropdown-item">
      <i class="fa fa-key" aria-hidden="true"></i> Change Password
    </router-link>

    <router-link
      to="https://narrativa.freshdesk.com/support/home"
      class="dropdown-item"
      target="_blank"
    >
      <i class="fa fa-question"></i> Help
    </router-link>

    <a href="#" class="clearfix bg-master-lighter dropdown-item" @click="logout">
      <span class="pull-left">Logout</span>
      <span class="pull-right">
        <i class="pg-power"></i>
      </span>
    </a>
  </div>
</template>

<script>
import ENABLED from '../../../toggle_feature';
import {  isDemoUser } from "../../../utils/user";
export default {
  data(){
    return {
      ENABLED: ENABLED,
      isDemoUser: isDemoUser(),
    }
  },
  methods: {
    logout(e) {
      e.preventDefault();

      this.$store.dispatch("logOutUser");
      this.$router.go()
    }
  }
};
</script>

<style scoped>
</style>