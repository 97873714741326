<template>
  <div class="table-pagination mt-4 mb-4" v-if="pages && pages.length>0">
    <nav aria-label="Feeds Navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="disablePrev">
          <a class="page-link" href="#" aria-label="Previous" @click="prev">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
            <span class="sr-only">Previous</span>
          </a>
        </li>

        <li class="page-item" v-for="page in pages" :class="isActive(page)">
          <a class="page-link" @click="setPage(page)">{{ page + 1 }}</a>
        </li>

        <li class="page-item" :class="disableNext">
          <a class="page-link" aria-label="Next" @click="next">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
const disable = condition => {
  return { disabled: condition };
};

const ITEM_COUNT = 15;

export default {
  props: ["data"],

  data() {
    return {
      active: 0
    };
  },

  computed: {
    pages() {
      const { data } = this;
      if (!data) {
        return [];
      }

      const count = Math.ceil(data.length / ITEM_COUNT);
      return [...Array(count).keys()];
    },

    disableNext() {
      return disable(!this.hasNext());
    },

    disablePrev() {
      return disable(!this.hasPrev());
    }
  },

  methods: {
    next() {
      this.setPage(this.active + 1);
    },

    prev() {
      this.setPage(this.active - 1);
    },

    setPage(page) {
      this.active = page;

      const { active, data } = this;
      const size = this.data.length;

      const start = active * ITEM_COUNT;
      const end = Math.min(start + ITEM_COUNT, size);

      const sliced = this.data.slice(start, end);
      this.$emit("pageChange", sliced);
    },

    refresh() {
      this.setPage(this.active);
    },

    hasNext() {
      const size = this.pages.length;
      if (size == 0) return false;

      return this.active != size - 1;
    },

    hasPrev() {
      return this.active != 0;
    },

    isActive(page) {
      return { active: page == this.active };
    }
  },

  watch: {
    data: function(ndata, odata) {
      if (ndata != null) this.setPage(0);
    }
  }
};
</script>
<style>
.page-link {
  border: 0px;
}

li.page-item.active > a.page-link {
  background-color: transparent !important;
  border-bottom: 2px solid #157ef3;
  color: black !important;
}

.page-link {
  background-color: transparent;
}

li.page-item.disabled > a.page-link {
  background-color: transparent;
}
</style>
