<template>
  <div>
    <div class="feed-post-time">{{a_feed_content.date}}</div>
    <div class="feed-post">
      <h1>{{ a_feed_content.title }}</h1>
      <h2>{{ a_feed_content.subtitle }}</h2>
      <h3>{{ a_feed_content.leadin }}</h3>
      <p v-html="a_feed_content.content">{{a_feed_content.content}}</p>
      <hr v-if="a_feed_content.picurl" />
      <p v-if="a_feed_content.picurl" class="picurl">
        <a v-bind:href="a_feed_content.picurl" target="_blank">
          <img v-bind:src="a_feed_content.picurl+'&scale=0.5&extension=.jpg'" />
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "cover-feed-detail-post",

  props: ["a_feed_content", "feed_info"]
};
</script>

<style scoped>
.feed-post {
  border: 1px solid #ccc;
  margin-bottom: 3em;
  padding: 1em;
  background-color: #fff;
}

.feed-post-time {
  border: 1px solid #ccc;
  padding: 0.3em 1em 0.3em 1em;
  display: inline-block;
  background-color: #eee;
}

h1 {
  font-size: 1.5em !important;
  line-height: 1.3em;
}

h2 {
  font-size: 1.25em !important;
  line-height: 1.3em;
}

h3 {
  font-size: 1em !important;
  line-height: 1.3em;
}

#metainfo {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1em;
}

.picurl {
  text-align: center;
}
</style>