import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Billing extends Endpoint {
  constructor() {
    super('/user/payment-methods')
    bindAll(this, [
      'getPaymentMethods',
      'setDefault',
      'addMethod',
      'deleteMethod'
    ])
  }

  getPaymentMethods() {
    return this.get()
  }

  addMethod(token) {
    return this.post('', { token })
  }

  setDefault(data) {
    return this.patch('/', data)
  }

  deleteMethod(cardId) {
    return this.delete(`/${cardId}`)
  }
}

const billing = new Billing()
export default billing
