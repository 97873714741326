export default {
  thumbnail: state => {
    const { user } = state.session;
    if(user.lastName){
      return user ? user.firstName[0] + user.lastName[0] : '';
    }

    return user ? user.firstName[0] + user.firstName[1] : '';
  },

  name: state => {
    const { user } = state.session;
    const name = user ? `${user.firstName} ${user.lastName}` : '';
    return name;
  },

  user: state => {
    return state.session.user;
  },

  catalogCurrentSortDir: state => {
    return state.catalog.currentSortDir;
  }
}