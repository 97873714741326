<template>
  <div class="onboarding container-fluid p-0 fh">
    <div class="row fh">
      <div class="onboarding__background-section col-lg-8 col-sm-0 d-none d-lg-block">
        <div class="onboarding__background-inner d-flex align-items-end">
          <div class="bg-caption text-white">
            <h2 class="onboarding__title semi-bold text-white mt-0 mb-0">Narrativa Content Platform</h2>
            <p class="onboarding__beta mb-3">Beta 2022.11.17</p>
            <a href="/catalog" class="onboarding__btn btn btn-warning">
              <i class="onboarding__btn-icon fa fa-shopping-basket" aria-hidden="true"></i>
              <span class="onboarding__btn-text">Access to Catalog</span>
            </a>
            <div class="mt-3 d-flex align-items-baseline">
              <p class="onboarding__copy m-0">&copy; {{new Date().getFullYear()}}, Narrativa. All rights reserved.</p>
              <div id="footer-social-links">
                <a href="https://www.linkedin.com/company/9329868" target="_blank">
                  <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                  <span class="d-none">linkedin</span>
                </a>
                <a href="https://www.twitter.com/NarrativaAI" target="_blank">
                  <i class="fab fa-twitter" aria-hidden="true"></i>
                  <span class="d-none">twitter</span>
                </a>
                <a href="https://www.facebook.com/narrativaai/" target="_blank">
                  <i class="fab fa-facebook-f" aria-hidden="true"></i>
                  <span class="d-none">facebook</span>
                </a>
                <a href="mailto:customer-support@narrativa.com" target="_blank">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <span class="d-none">email</span>
                </a>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="col-lg-4 onboarding-content fh">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "onboarding"
};
</script>

<style scoped>
.onboarding__background-section {
  padding: 2rem 3rem;
}

.onboarding__background-inner {
  height: 100%;
  width: 100%;
}

.onboarding__title {
  font-size: 22px !important;
}

.onboarding__btn {
  font-size: 20px;
  padding: 11px 20px;
}

.onboarding__btn-icon {
  font-size: 22px;
  margin-right: 5px;
}

.onboarding__beta,
.onboarding__copy {
  font-size: 14px;
}

.onboarding__btn-text {
  font-size: 17px;
  font-weight: 800;
}
.onboarding-content {
  background-color: #fedd4e;
  padding-top: 3rem;
}

@media (min-width: 768px) {
  .onboarding-content {
    padding-top: 13rem;
  }
}

.container-fluid {
  background-color: #157ef3;
}

body {
  background-color: #157ef3;
}

.fh {
  height: 100%;
  min-height: 100%;
}

.fw {
  width: 100%;
  min-width: 100%;
}
</style>