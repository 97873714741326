<template>
  <div class="form-group form-group-default" :class='reqcls'>
    <label>{{ label }}</label>
    <input type="text" class="form-control" :name="name" :value="value" spellcheck="false"
           @input="updateInput" ref="input" :placeholder="placeholder" />
  </div>
</template>

<script>
  export default {
    name: 'form-input',
    props: ['label', 'value', 'name', 'placeholder', 'required'],

    computed: {
      reqcls() {
        return { 'required': this.required }
      }
    },

    methods: {
      updateInput(e) {
        this.$emit('input', e);
      }
    }
  }
</script>