<template>
  <button type="type" class="btn" :class="klass" :disabled="loading"
          @click="$emit('click')">
    <span>{{ text }}</span>
    <span class='spinner' :class='spinnercls' v-show="loading"/>
  </button>
</template>

<script>
  export default {
    props: {
      text: String,
      loading: Boolean,
      klass: String,
      type: {
        type: String,
        default: "button"
      },

      spinnerKlass: {
        type: String,
        default: ""
      }
    },

    computed: {
      spinnercls() {
        return `${this.spinnerKlass} action-spinner`
      }
    }
  }
</script>