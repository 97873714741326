<template>
  <div class="fade show">
    <div class="modal fade" id="feed-modal" role="dialog">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <form @submit="save">
            <div class="modal-header clearfix text-left">
              <button type="button" class="close" @click="close" aria-hidden="true">×</button>
              <h5 class="modal-title">Take Part in our Private Beta!</h5>
            </div>

            <div class="modal-body">
              <div class="container p-3">
                <div class="row">
                  <div class="col">
                    <!--<h2>
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;We are currently in private BETA version of our Content Distribution Platform.
                    </h2>-->
                    <p class="text-center">
                      <strong>Only registered users can subscribe to feeds.</strong>
                    </p>
                    <p>Just send us your email address and we will provide you with the credentials.</p>
                    <input
                      type="text"
                      v-model="email"
                      class="form-control"
                      id="inputTitle"
                      placeholder="name@domain.com"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- modal-body -->

            <div class="modal-footer">
              <button type="button" class="btn btn-default" @click="close">Close</button>
              <button type="submit" class="btn btn-success">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modals from "./ui/modals.js";
import signup from "../api/signup.js";

export default {
  name: "subscription-beta-modal",
  components: {},
  data() {
    return {
      email: null
    };
  },
  methods: {
    initialize() {
      this.isUpdate = false;
      this.error = null;
    },
    show(feed) {
      $("#feed-modal").modal(modals.show);
    },
    close() {
      this.initialize();
      $("#feed-modal").modal("hide");
    },

    async save(e) {
      e.preventDefault();

      let data, err;

      if (this.email) {
        [data, err] = await signup.signupEmail(this.email);
      }

      if (err) {
        this.error = err.data.message;
        return;
      }

      this.$emit("reload");
      this.close();
    }
  }
};
</script>

<style scoped>
</style>