<template>
  <div>
    <div class="row" v-if="a_feed_content" id="tabs">
      <div
        class="col-md-2"
        v-bind:class="{
          'tab-active': selected_tab == 'preview',
          tab: selected_tab != 'preview',
        }"
      >
        <a @click="set_selected_tab('preview')">
          <h2><i class="fa fa-eye" aria-hidden="true"></i> Preview</h2>
        </a>
      </div>
      <div
        class="col-md-2"
        v-bind:class="{
          'tab-active': selected_tab == 'editor',
          tab: selected_tab != 'editor',
          'tab-warning': is_changed_but_not_saved == true,
        }"
        v-if="!isDemoUser"
      >
        <a @click="set_selected_tab('editor')">
          <h2 v-if="is_changed_but_not_saved">
            <i class="fas fa-pencil-alt" aria-hidden="true"></i> Editor*
          </h2>
          <h2 v-else><i class="fas fa-pencil-alt" aria-hidden="true"></i> Editor</h2>
        </a>
      </div>
      <div
        class="col-md-2"
        v-bind:class="{
          'tab-active': selected_tab == 'code',
          tab: selected_tab != 'code',
        }"
      >
        <a @click="set_selected_tab('code')">
          <h2><i class="fa fa-code" aria-hidden="true"></i> Code</h2>
        </a>
      </div>
    </div>
    <!-- tabs -->
    <div
      class="feed-post"
      v-bind:class="{ 'feed-post-warning': is_changed_but_not_saved == true }"
    >
      <div class="row mb-4">
        <div class="col-md-4">
          <div class="feed-post-time">
            {{ formatDate(a_feed_content.date) }}
          </div>
        </div>
        <div class="col-md-8 text-right" v-if="!isDemoUser">
          <button
            v-if="a_feed_content && a_feed_content.validated == true"
            class="btn btn-primary btn-validation"
            @click="showDisablePublishPostDialog()"
          >
            <i class="fa fa-eye-slash"></i>&nbsp;Unpublish
          </button>
          <button
            v-else
            class="btn btn-warning btn-validation"
            @click="showEnablePublishPostDialog()"
          >
            <i class="fa fa-eye"></i>&nbsp;Publish
          </button>
          <button
            v-if="a_feed_content && (a_feed_content.manual == true || isAdminUser) "
            class="btn btn-danger"
            @click="showDeletePostDialog()"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <!-- buttons -->

      <div v-if="a_feed_content && this.selected_tab == 'preview'">
        <feed-preview-simple
          :a_feed_content="a_feed_content"
        ></feed-preview-simple>
      </div>

      <!-- preview code -->
      <div v-if="a_feed_content && this.selected_tab == 'editor'">
        <feed-preview-editor
          :a_feed_content="a_feed_content"
          :is_changed_but_not_saved="is_changed_but_not_saved"
          @doc_changed="doc_changed"
          @doc_saved="doc_saved"
          @doc_cancel_and_reload="doc_cancel_and_reload"
        ></feed-preview-editor>
      </div>
      <!-- editor tab -->
      <div v-if="a_feed_content && this.selected_tab == 'code'">
        <json-viewer
          :value="a_feed_content"
          :expand-depth="5"
          expanded
          sort
        ></json-viewer>
      </div>
      <!-- code tab -->
    </div>

    <confirm-dialog
      :a_id="'enablePublishPostDialog_' + a_feed_content._id"
      ref="enablePublishPostDialog"
      :message="'You are going to <strong>PUBLISH this post on your feed</strong>.<br><br>This means that this post will appear from this moment in the feed.<br><br>Are you sure?'"
      @confirm="showPost()"
    ></confirm-dialog>

    <confirm-dialog
      :a_id="'deletePostDialog_' + a_feed_content._id"
      ref="deletePostDialog"
      :message="'You are going to <strong>DELETE this post from your feed</strong>.<br><br>This action can not be undone and the post will not be recoverable.<br><br>Are you sure?'"
      @confirm="deletePost()"
    ></confirm-dialog>

    <confirm-dialog
      :a_id="'disablePublishPostDialog_' + a_feed_content._id"
      ref="disablePublishPostDialog"
      :message="'You are going to <strong>UNPUBLISH this post on your feed</strong>.<br><br>This means that this post will not appear from this moment in the feed. You can publish later if you want.<br><br>Are you sure?'"
      @confirm="hidePost()"
    ></confirm-dialog>
  </div>
</template>

<script>
import feedsContent from "../../api/feedscontent.js";
import FeedPreviewSimple from "./feed-preview-simple.vue";
import FeedPreviewEditor from "./feed-preview-editor.vue";
import { formatDate } from "../../utils/utils.js";
import { isAdminUser } from "../../utils/user";
import JsonViewer from "vue-json-viewer";
import ConfirmDialog from "../ui/confirm-dialog.vue";
import { isDemoUser } from "../../utils/user";


export default {
  name: "cover-feed-detail-post-suscribed",

  props: ["a_feed_content", "feed_info"],

  components: {
    JsonViewer,
    FeedPreviewSimple,
    FeedPreviewEditor,
    ConfirmDialog,
  },

  data() {
    return {
      selected_tab: "preview",
      is_changed_but_not_saved: false,
      copy_of_a_feed_content: null,
      isAdminUser: isAdminUser(),
      isDemoUser: isDemoUser(),
    };
  },

  methods: {
    formatDate(stringDate) {
      return formatDate(stringDate);
    },

    set_selected_tab(value) {
      this.selected_tab = value;
    },

    showDeletePostDialog() {
      this.$refs.deletePostDialog.show();
    },
    showEnablePublishPostDialog() {
      this.$refs.enablePublishPostDialog.show();
    },
    showDisablePublishPostDialog() {
      this.$refs.disablePublishPostDialog.show();
    },

    async hidePost() {
      this.loading = true;
      let post = this.$props.a_feed_content;

      const [data, error] = await feedsContent.setPostAsNotValidated(post._id);

      if (!error && data._id == post._id) {
        post.validated = false;
      } else {
        console.log(
          "Error while setting validated == false to post:" + post._id
        );
      }
      this.loading = false;
    },

    async showPost() {
      this.loading = true;
      let post = this.$props.a_feed_content;

      const [data, error] = await feedsContent.setPostAsValidated(post._id);

      if (!error && data._id == post._id) {
        post.validated = true;
      } else {
        console.log(
          "Error while setting validated == true to post:" + post._id
        );
      }
      this.loading = false;
    },

    async deletePost() {
      this.loading = true;
      let post = this.$props.a_feed_content;

      const [data, error] = await feedsContent.deletePost(post._id);

      if (!error && data._id == post._id) {
        post.validated = true;
        this.$emit("refreshContent");
      } else {
        console.log("Error while deleting post: " + post._id);
      }
      this.loading = false;
    },

    doc_changed() {
      console.log("doc_changed event received");
      this.is_changed_but_not_saved = true;
    },

    doc_saved() {
      feedsContent.updatePost;
      this.is_changed_but_not_saved = false;
      console.log(
        "this.is_changed_but_not_saved:" + this.is_changed_but_not_saved
      );
    },

    doc_cancel_and_reload() {
      console.log("doc_cancel_and_reload event received");
      /*console.log("this.is_changed_but_not_saved:" + this.is_changed_but_not_saved)*/
      this.is_changed_but_not_saved = false;
    },
  },
};
</script>

<style>
.feed-post {
  border: 1px solid #ccc;
  margin-bottom: 3em;
  padding: 1em;
  background-color: #fff;
}

.feed-post-warning {
  border-color: red !important;
}

.feed-post-time {
  border: 1px solid #ccc;
  padding: 0.3em 1em 0.3em 1em;
  display: inline-block;
  background-color: #eee;
}

h1 {
  font-size: 1.5em !important;
  line-height: 1.3em;
}

h2 {
  font-size: 1.25em !important;
  line-height: 1.3em;
}

h3 {
  font-size: 1em !important;
  line-height: 1.3em;
}

#metainfo {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1em;
}

.picurl {
  text-align: center;
}

.btn-validation {
  margin-left: 1em;
  margin-right: 0em;
}

.tab-warning {
  background-color: red !important;
}
</style>