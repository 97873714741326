<template>
  <div class="mb-4">
    <div class="form-group">
      <input v-model="searchQuery" placeholder="Search for..." class="form-control mb-4" />
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <!--<th>First Name</th>
          <th>Last Name</th>-->
          <th class="text-left">Email</th>
          <th class="text-center">User Code</th>
          <th class="text-center">Token</th>
          <th class="text-center">"My Feeds"</th>
          <th class="text-center">Control</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in filterItems(users)" :key="index">
          <!--<td class="v-align-middle">            
            {{ user.firstName }}
          </td>
          <td class="v-align-middle">
            {{ user.lastName }}
          </td>-->
          <td class="v-align-middle">{{ user.email }}</td>
          <td class="v-align-middle text-center">{{ user.clientCode }}</td>
          <td class="v-align-middle text-center">{{ user.apiKey }}</td>
          <td class="v-align-middle text-center">
            <a
              :href="`https://content.gabriele.ai/my_content/${user.apiKey}/list_domains`"
              target="_blank"
              class="btn btn-success"
            >
              Go
              <i class="fa fa-external-link" aria-hidden="true"></i>
            </a>
          </td>
          <td class="v-align-middle text-center">
            <button class="btn btn-success" @click="editUserSubscriptions(user)">
              <i class="fas fa-newspaper" aria-hidden="true"></i>
            </button>
            <button class="btn btn-warning" @click="updateUser(user)">
              <i class="fas fa-pen-square" aria-hidden="true"></i>
            </button>
            <button class="btn btn-danger" @click="deleteUser(user.id)">
              <i class="fas fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "users-table",
  props: ["users"],
  data() {
    return {
      searchQuery: ""
    };
  },
  methods: {
    updateUser(user) {
      this.$emit("updateUser", user);
    },
    deleteUser(userId) {
      this.$emit("deleteUser", userId);
    },
    editUserSubscriptions(userId,user) {
      this.$emit("editUserSubscriptions", userId, user);
    },
    filterItems: function(users) {
      var app = this;
      var filtered_result =  users.filter(function(user) {
        let regex = new RegExp("(" + app.searchQuery + ")", "i");

        let foundFirstName =
          user.firstName != null ? user.firstName.match(regex) : false;
        let foundLastName =
          user.lastName != null ? user.lastName.match(regex) : false;
        let foundEmail = user.email != null ? user.email.match(regex) : false;
        let foundClientCode =
          user.clientCode != null ? user.clientCode.match(regex) : false;
        let foundApiKey =
          user.apiKey != null ? user.apiKey.match(regex) : false;

        return (
          foundFirstName ||
          foundLastName ||
          foundEmail ||
          foundClientCode ||
          foundApiKey
        );
      });

      return filtered_result.sort((a, b) => {
        if (a["email"].toLowerCase() < b["email"].toLowerCase())
            return -1;
          if (a["email"].toLowerCase() > b["email"].toLowerCase())
            return 1;
          return 0;
      })
    }
  }
};
</script>

<style scoped>
</style>