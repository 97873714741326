<template>
  <div>
    <h2 class="mt-2 mb-2 text-right">{{monthYear}} Report</h2>
    <table class="table mt-2">
      <thead>
        <tr>
          <th>Feed</th>
          <th class="text-center">Year</th>
          <th class="text-center">Month</th>
          <th class="text-right">Posts</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="report in listOfFeedsReport" v-bind:key="report.id">
          <td>
            <a v-bind:href="'/feed/details/'+report.feed_id" target="_blank">{{ report.feed_name }}</a>
          </td>
          <td class="year text-cente">{{ report.year }}</td>
          <td class="month text-cente">{{ report.month }}</td>
          <td class="posts text-cente">{{ report.published_docs.toLocaleString()}}</td>
        </tr>
        <tr class="totals">
          <td colspan="3" class="text-right">Total</td>
          <td>{{totalSum}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "usage-report-details-report-table",
  props: ["monthYear", "listOfFeedsReport"],
  computed: {
    totalSum() {
      return this.listOfFeedsReport
        .reduce((a, b) => +a + +b.published_docs, 0)
        .toLocaleString();
    }
  }
};
</script>

<style scoped>
table.table {
  border: 1px solid #ccc;
}

table tr th {
  background-color: #fff;
}

.selected-plan {
  border: 2px solid #157ef3;
  background-color: #157ef3;
  color: white !important;
}

tr.totals td {
  font-weight: bold;
  text-align: right;
  font-size: 1.3em;
}
</style>