<template>
  <div class="card-header">
    <div class="card-title">Sort content by</div>
    <div class="row filter-container">
      <div class="col-xs-3" v-for="filter in Object.keys(dropdowns)">
        <label class="typo__label">{{ filter }}</label>

        <multiselect v-model="values[filter]" :options="getOptions(filter)" :multiple="true"
                     :close-on-select="false" :clear-on-select="false" :hide-selected="true"
                     :preserve-search="true" placeholder="Select filters" label="name"
                     track-by="name" @select="onSelect" @remove="onRemove">

          <template slot="tag" slot-scope="props">
            <span class="custom__tag">
              <span>{{ props.option.name }}</span>
              <span class="fa fa-close" @click="props.remove(props.option)"></span>
            </span>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import "vue-multiselect/dist/vue-multiselect.min.css"

  export default {
    components: {
      Multiselect
    },

    /* dropdowns => characteristic map,
       fmap => characteristic -> namespace map
    */
    props: ['dropdowns', 'fmap'],

    data () {
      return {
        values: {},
        filters: []
      }
    },

    methods: {
      getOptions(key) {
        const filters = this.dropdowns[key]

        const options = filters.map((filter) => ({
          name: filter,
          key
        }))

        return options
      },

      /* returns characteristic string from option object */
      getCharacteristic(option) {
        const namespace = this.fmap[option.key]
        const {name} = option

        return `${namespace}/${name}`
      },

      onSelect(selected, id) {
        const characteristic = this.getCharacteristic(selected);
        this.filters.push(characteristic)
        this.$emit('filter', this.filters);
      },

      onRemove(removed, id) {
        const characteristic = this.getCharacteristic(removed)
        // remove characteristic from filters
        this.filters = this.filters.filter( i => ( i != characteristic ))
        const filters = this.filters.length == 0 ? null : this.filters

        this.$emit('filter', filters);
      }
    }
  }

</script>

<style>
  .filter-container {
    margin: 10px 0px;
  }

  .custom__tag {
    color: white;
    display: inline-block;
    padding: 3px 12px;
    background: #157EF3;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
    cursor: pointer;
  }

  .custom_remove {
    padding-left: 5px;
  }

  .multiselect__tags {
    border-radius: 0;
    min-height: 45px;
  }

  .multiselect__element {
    padding: 0;
  }

  .multiselect__single {
    line-height: 27px;
  }

  .multiselect__content-wrapper {
  }
</style>