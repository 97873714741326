<template>
  <span class='spinner' v-bind:class='klass' v-show="loading"/>
</template>

<script>
  export default {
    name: 'spinner',
    props: {
      loading: Boolean,
      klass: String
    }
  }
</script>