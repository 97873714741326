<template>
  <div >
    <div class="p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
      <img src="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png" alt="logo" data-src="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png" data-src-retina="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png" width="329" height="50"/>

      <div v-if="!showMessage">
        <p class="p-t-35">{{ message }}</p>
        <spinner v-bind:loading="loading"></spinner>
      </div>

      <onboard-message v-if="showMessage" title="Your account has been verified."
                       message="Click on the button below to go to your dashboard."
                       showbtn="true"></onboard-message>
    </div>
  </div>
</template>

<script>
  import Spinner from './ui/spinner.vue'
  import verification from '../api/verification'
  import OnboardMessage from './onboarding/onboard-message.vue'

  const message = "Your email is being verified!"

  export default {
    name: 'verify-mail',
    components: {
      Spinner,
      OnboardMessage
    },

    data() {
      return {
        message,
        loading: true,
        showMessage: false
      }
    },

    mounted() {
      this.verify()
    },

    methods: {
      async verify() {
        const {hash} = this.$route.params
        const [data, err] = await verification.verify(hash)

        this.loading = false

        if (err) {
          this.message = "Something went wrong."
          return
        }


        if (data.status == "success") {
          this.showMessage = true
          this.$store.dispatch('logInUser', data)
        } else {
          this.message = data.message
        }
      }
    }
  }
</script>