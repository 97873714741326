<template>
  <div class="row">
    <div class="col-3 mb-4">
      Posts per page
      <select v-model="sync_itemsPerPage">
        <option value="2">2</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="1000">1000</option>
      </select>
    </div>
    <div class="col-6">
      <nav aria-label="Feeds Navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="disablePrev">
            <a class="page-link" href="#" aria-label="Previous" @click="prev()">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item numbers">{{ to }} - {{ from }}</li>

          <li class="page-item" :class="disableNext">
            <a class="page-link" aria-label="Next" @click="next()">
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>

  </div>
</template>

<script>
const disable = (condition) => {
  return { disabled: condition };
};

export default {
  props: ["to_last_num", "next_is_active", "from", "to", "itemsPerPage"],

  data() {
    return {
      the_itemsPerPage: this.itemsPerPage,
    };
  },

  created() {
    this.the_itemsPerPage = this.itemsPerPage;
  },

  methods: {
    next() {
      this.$emit("next", 0);
    },

    prev() {
      this.$emit("prev", 0);
    },
    hasNext() {
      return true;
    },

    hasPrev() {
      return true;
    },

    newEmptyPost() {
      console.log("New call to newEmptyPost");
      this.$emit("newEmptyPost");
    },
  },

  computed: {
    disableNext() {
      return { disabled: this.next_is_active == false };
    },

    disablePrev() {
      var result = this.to_last_num > 0;
      return { disabled: !result };
    },

    sync_itemsPerPage: {
      get() {
        var result = parseInt(this.itemsPerPage);
        if (typeof result == "undefined") {
          result = itemsPerPage;
        }
        return result;
      },
      set(val) {
        this.the_itemsPerPage = val;
        this.$emit("changeItemsPerPage", val);
        return val;
      },
    },
  },
};
</script>
<style scoped>
.page-item.disabled {
  color: #ccc;
}

.numbers {
  padding-left: 1em !important;
  padding-right: 1em !important;
  padding-top: 0.35em !important;
}
</style>
