<template>
  <div class="modal fade" id="setup-billing" role="dialog">
    <div class="modal-dialog modal-m">
      <div class="modal-content">
        <div class="modal-header clearfix text-left">
          <h5 style="font-size: 20px;" class="semi-bold">Setup Billing</h5>
        </div>

        <div class="modal-body">
          <div class="modal-message">
            <h3>{{ message }}</h3>
            <span class="label" v-for='field in fields' v-if='emptyPartial'>
              {{ field }}
            </span>
          </div>
        </div>
        <div class="modal-footer">

          <button type="button" class="btn btn-default" data-dismiss="modal">
            Close
          </button>

          <button type="button" class="btn btn-success" @click="setupProfile">
            Setup Billing
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import modals from './modals.js'

  const EMPTY_BILLING = 'Your billing profile is empty, click on the button below to setup your billing profile.'

  const EMPTY_PARTIAL = 'Your billing profile is missing some information, Look for the fields below and update them.'

  export default {
    props: ['fields', 'feedId'],

    mounted() {
      this.modal = $('#setup-billing')
    },

    computed: {
      message() {
        const length = this.fields.length
        return length == 6 ? EMPTY_BILLING : EMPTY_PARTIAL
      },

      emptyPartial() {
        return this.fields.length < 6
      }
    },

    methods: {
      show() {
        this.modal.modal(modals.show)
      },

      setupProfile() {
        const feedId = this.feedId
        const url = `/dashboard/feed/${feedId}`
        this.$router.push(`/dashboard/profile?next=${url}`)
        this.modal.modal('hide')
      }
    }
  }
</script>

<style>
  .modal-message {
    padding: 0 20px;
    line-height: 30px;
  }

  .modal-message span.label {
    margin-right: 10px;
  }
</style>