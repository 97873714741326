<template>
  <div class="alert" role="alert" v-bind:class="klass" v-if="message">{{ message }}</div>
</template>

<script>
export default {
  functional: false,
  name: "alert",
  props: ["message", "type"],

  computed: {
    klass() {
      return `alert-${this.$props.type}`;
    }
  }
};
</script>