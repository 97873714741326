import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Plans extends Endpoint {
  constructor() {
    super('/plans');
    bindAll(this, [ 'getAllPublic' ]);
  }

  getAllPublic() {
    return this.get('/public');
  }

}


export default new Plans()