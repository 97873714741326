const MSG_SHORT_PWD = 'Password should be at least 8 characters long'
const MSG_UPPER = 'Password should contain at least 1 capital letter'
const MSG_LOWER = 'Password should contain at least 1 small letter'
const MSG_NUMBER = 'Password should contain at least 1 number'
const MSG_SPECIAL = 'Password should contain at least 1 special character'


const rules = [
  {regex: /[A-Z]/, error: MSG_UPPER},
  {regex: /[a-z]/, error: MSG_LOWER},
  {regex: /[0-9]/, error: MSG_NUMBER},
  {regex: /[!@#$%^&*(),.?":{}|<>]/, error: MSG_SPECIAL}
]

export const validatePassword = (input) => {
  const validations = [
    {
        name: "length",
        valid: false,
        regex: /^.{8,}$/
    },
    {
        name: "uppercase",
        valid: false,
        regex: /[A-Z]/
    },
    {
        name: "lowercase",
        valid: false,
        regex: /[a-z]/
    },
    {
        name: "numbers",
        valid: false,
        regex: /[0-9]/
    },
    {
        name: "specialChars",
        valid: false,
        regex: /[!@#$%^&*(),.?":{}|<>]/
    }
  ];

  for (let index = 0; index < validations.length; index ++) {
    const rule = validations[index];
    const {regex, ruleName} = rule
    validations[index].valid = regex.test(input)
  }
  return validations;
}

export const validateEmail = (input) => {
  const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return regexEmail.test(input) && input.length > 0;
}

export const validate = (input) => {
  if (input.length < 8) {
    return [false, MSG_SHORT_PWD]
  }

  for (let index = 0; index < rules.length; index ++) {
    const rule = rules[index]

    const {regex, error} = rule

    if (!regex.test(input)) {
      return [false, error]
    }
  }

  return [true, null]
}

export const validateForm = (form) => {
  const requiredFormGroups = form.getElementsByClassName('required')
  const errors = []

  for (let formgroup of requiredFormGroups) {
    const fieldName = formgroup.querySelector('label').innerHTML
    const input = formgroup.querySelector('input')
    const {value} = input

    if (value == '') {
      errors.push(`${fieldName} cannot be empty.`)
      continue
    }

    /*const maxlength = input.getAttribute('maxlength')
    if (maxlength != null) {
      if (value.length != Number(maxlength))
        errors.push(`${fieldName} should be ${maxlength} characters long`)
    }*/
  }

  return errors
}