import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Domains extends Endpoint {
  constructor() {
    super('/domains');
    bindAll(this, ['getDomains']);
  }

  getDomains() {
    return this.get();
  }
}

export default new Domains();