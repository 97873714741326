<template>
  <div class="col-lg-5">
    <div class="card card-info card-transparent">
      <div class="card-block">

        <p class="bold">
          <span><i class="fa fa-feed"></i> Feed</span>
          <div class="api-info-pre">
            {{ feedurl }}
          </div>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import FeedParams from './feed-params.vue'

  export default {
    name: 'feed-info',
    props: ['info'],

    components: {
      FeedParams
    },

    computed: {
      feedurl() {
        const feedUrl = this.info.feedUrl
        return this.subscribed ? `${API_HOST}/api/content/${feedUrl}` : "Subscribe to generate your feed url."
      },

      subscribed() {
        return this.info.subscribed
      }
    }
  }
</script>

<style>
  .card-info p.bold {
    margin: 15px 0;
  }

  .api-info-pre {
    background: #f7f7f7;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    max-width: 410px;
    overflow-x: auto;
    font-weight: 100;
  }
</style>