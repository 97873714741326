<template>
  <div>
    <div v-if="feed && feed.language">
      <dt>Language</dt>
      <dd>{{ feed.language }}</dd>
    </div>

    <div v-if="feed && feed.publish_periodicity">
      <dt>Periodicity</dt>
      <dd>{{ feed.publish_periodicity }}</dd>
    </div>

    <div v-if="feed && feed.post_rate">
      <dt>Posts/time (estimation)</dt>
      <dd>{{ feed.post_rate }}</dd>
    </div>

    <dt>Deliver</dt>
    <dd>JSON or XML feed ready to be automated.</dd>

    <div>
      <dt v-if="subscribed">Your Feed URL</dt>
      <dt v-else>Demo Feed URL</dt>
      <dd class="feed-url">
        <a v-bind:href="feedUrl" target="_blank">{{ feedUrl }}</a>
      </dd>
    </div>
  </div>
</template>

<script>
import users from "../../api/users";
import { isUserLogged } from "../../utils/user";

export default {
  name: "feed-features-table",
  props: ["feed", "subscribed"],
  data() {
    return {
      isUserLogged: isUserLogged(),
    };
  },
  computed: {
    feedUrl() {
      if (isUserLogged() && this.subscribed) {
        console.log("Inside: " + JSON.parse(localStorage.getItem("user")))
        var userToken =JSON.parse(localStorage.getItem("user")).apiKey;

        return (
          "https://content.gabriele.ai/narratives/api/content/" +
          userToken +
          "/" +
          this.$props.feed.id +
          "/latest"
        );
      }
      return (
        "https://content.gabriele.ai/narratives/api/content/" +
        this.$props.feed.id +
        "/demo"
      );
    },
  },
};
</script>

<style>
dt {
  font-size: 1.1em;
  margin-bottom: 0.5em;
}

dd {
  margin-bottom: 1em;
}

dd.feed-url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>