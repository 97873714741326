<template>
  <div>
    <div class="container-fluid" id="search-header">
      <div class="container">
        <div class="form-group pt-3 pb-3 mb-4" id="filter">
          <div class="row pt-4 pb-4">
            <div class="col-lg-1 offset-lg-2 text-right no-padding d-none d-lg-flex align-items-center justify-content-end">
              <i class="fa fa-search" aria-hidden="true" style="font-size:1.5em;"></i>
            </div>
            <div class="col-lg-7">
              <input
                type="text"
                v-model="search"
                class="form-control"
                placeholder="Search for..."
                id="feed-search"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Search For -->
    </div>
    <div class="container">
      <div class="row mt-2 mb-2">
        <div class="col">
          <span @click="toggleFilters()">
            <i class="filters-icon fa fa-filter" aria-hidden="true"></i>Filters
          </span>
          <a
            @click="resetFilters()"
            v-if="checkedCategories && checkedCategories.length"
          >
            <i class="fa fa-window-close" aria-hidden="true"></i>
          </a>
        </div>
        <div class="col text-right">
          <span @click="sort()">
            <span class="filters-icon">Sort by Title</span>
            <i
              v-if="this.currentSortDir=='asc'"
              class="fas fa-sort-alpha-up"
              aria-hidden="true"
            ></i>
            <i v-else class="fas fa-sort-alpha-down" aria-hidden="true"></i>
          </span>
        </div>
      </div>

      <div class="no-padding container-fixed-lg" id="catalog-main-table">
        <div class="row">
          <div class="col-lg-3" id="catalog-filters">
            <!-- filters -->
            <catalog-cover-feed-table-filters
              :the_feeds="feeds"
              @filterChange="onFilterChange"
              ref="filters"
            ></catalog-cover-feed-table-filters>
          </div>
          <div class="col-lg-9">
            <!-- table of results -->
            <div v-if="data && data.length">
              <div class="row mt-4">
                <!--<h3 v-show="feed.length==0">No results found</h3>-->
                <cover-feed-item
                  v-for="(feed, index) in data"
                  :feed="feed"
                  :base_url="'/catalog/feed/details/'"
                  v-bind:key="feed.id"
                ></cover-feed-item>
              </div>
            </div>
            <div v-else>
              <div class="row mt-4">
                <div class="col text-center">
                  <h1>
                    <i class="fa fa-frown-o" aria-hidden="true"></i> Sorry, no results found
                  </h1>
                  <br />
                  <h2>Please try with other search criteria.</h2>
                </div>
              </div>
            </div>
            <table-pagination :data="sortedFeeds" @pageChange="onPageChange" />
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->

      <feed-detail-container ref="feedDetail" @reload="reload" />
      <feed-modal ref="feedModal" @reload="reload" />
    </div>
  </div>
</template>

<script>
import feeds from "../../api/feeds.js";
import categories from "../../api/categories.js";
import { isAdminUser } from "../../utils/user";

import TablePagination from "../ui/table/pagination.vue";
import FeedDetailContainer from "./feeds/detail-modal.vue";
import FeedModal from "./feeds/feed-modal.vue";
import CoverFeedItem from "./cover-feed-item.vue";
import CatalogCoverFeedTableFilters from "./catalog-cover-feed-table-filters.vue";
import { toggleFiltersMenu } from "../../utils/utils";

export default {
  name: "cover-feeds-table",
  props: ["feeds"],

  components: {
    FeedModal,
    TablePagination,
    FeedDetailContainer,
    CoverFeedItem,
    CatalogCoverFeedTableFilters
  },

  data() {
    return {
      feed: null,
      filters: null,
      categories: null,
      data: [],
      dataFiltered: [],
      currentSort: "type",
      isAdminUser: isAdminUser()
    };
  },

  watch: {
    $route(to, from) {
      this.handleModal(to, from);
    }
  },

  mounted() {
    this.handleModal(this.$router.currentRoute, null);
    this.getFilters();
  },

  computed: {
    feedId() {
      const { feed } = this;
      return feed ? feed.narrativaID : null;
    },
    currentSortDir() {
      return this.$store.state.catalog.currentSortDir;
    },
    setOfFeedIdToRemove() {
      return this.$store.state.catalog.setOfFeedIdToRemove;
    },

    checkedCategories() {
      return this.$store.state.catalog.checkedCategories;
    },

    search: {
      get() {
        return this.$store.state.catalog.search;
      },
      set(value) {
        this.$store.dispatch("updateCatalogSearch", value);
      }
    },

    sortedFeeds: function() {
      if (this.feeds != null) {
        var searched = this.feeds.filter(item => {
          var found =
            item.type.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            item.subtype.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
          return found && !this.setOfFeedIdToRemove.has(item.id);
        });

        return searched.sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a["title"].toLowerCase() < b["title"].toLowerCase())
            return -1 * modifier;
          if (a["title"].toLowerCase() > b["title"].toLowerCase())
            return 1 * modifier;
          return 0;
        });
      }
    }
  },

  methods: {
    toggleFilters () {
      toggleFiltersMenu();
    },
    handleModal(to, from) {
      const path = to.path;
      if (path.indexOf("/feed/") != -1) {
        const { feedId } = to.params;
        this.$refs.feedDetail.show(feedId);
      }
    },

    reload() {
      this.$emit("reload");
    },

    showDetails(feedId) {
      this.$refs.feedDetail.show(feedId);
    },

    onFilterChange(theSetOfIdFeedsToRemove) {
      this.$store.dispatch(
        "updateCatalogFeedIdToRemove",
        theSetOfIdFeedsToRemove
      );
    },

    onPageChange(dataset) {
      this.data = dataset;
    },

    createNewsFeed() {
      this.$refs.feedModal.show();
    },

    updateNewsFeed(feed) {
      this.$refs.feedModal.show(feed);
    },

    sort: function(s) {
      this.$store.dispatch(
        "updateCatalogCurrentSortDir",
        this.$store.state.catalog.currentSortDir === "asc" ? "desc" : "asc"
      );
    },

    resetFilters() {
      this.$refs.filters.resetFilters();
    },

    async getFilters() {
      this.feeds = null;
      this.loading = true;
      let response;
      response = await feeds.getFeedsFilters();

      const [data, err] = response;
      this.loading = false;

      if (!err) {
        this.filters = data;
      }
    }
  }
};
</script>

<style scoped>
.status-subscribed {
  font-weight: bold;
  color: green;
}

.feed_disabled td {
  background-color: #efefef !important;
  color: grey;
}

#filter.form-control {
  color: black;
  border: 1px black solid;
}

#catalog-main-table {
  border-top: 1px solid #ccc;
}

#catalog-filters {
  margin-top: 1em;
}

#search-header {
  background-color: #157ef3;
  color: white !important;
}

#search-header h1 {
  color: white;
}

#feed-search{
  border:0px;
  -webkit-border-radius:0px;
  color:#000;

}
</style>