<template>
  <div class="custom-control custom-checkbox">
    <input type="checkbox" class="custom-control-input" :id="id" :checked="checked"
           @change="onChange" :data-attr="attr">
    <label class="custom-control-label" :for="id"></label>
  </div>
</template>

<script>
  export default {
    props: ['name', 'checked', 'data'],

    computed: {
      id() {
        return `checkbox-${this.name}`;
      },

      attr() {
        const { data } = this;
        return data != null ? data : false;
      }
    },

    methods: {
      onChange(e) {
        this.$emit('onChange', e.target);
      }
    }
  }
</script>

<style>
  .custom-checkbox label {
    cursor: pointer;
  }

  .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #fedd4e;
  }
</style>