<template>
  <div class="fade show">
    <div class="modal fade" id="user-subscriptions-modal" role="dialog">
      <div class="modal-dialog slide-up modal-lg">
        <div class="modal-content">
          <div class="modal-header clearfix text-left">
            <h5 style="font-size: 20px;" class="semi-bold">User Subscriptions</h5>
            <button type="button" class="close" @click="close" aria-hidden="true">×</button>
          </div>
          <div class="modal-body p-3">
            <div class="row text-center mb-2">
              <div class="col">
                <h6
                  v-if="user && user.email"
                  style="font-size: 20px;"
                  class="semi-bold"
                >{{user.email}}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <a @click="toggleShowOnlySuscribed()">
                  <i v-if="!showFirstSubscribed" class="far fa-square icon-suscribed-legend"></i>
                  <i v-else class="far fa-check-square" aria-hidden="true"></i>
                  &nbsp;Show first subscribed
                </a>
              </div>
            </div>

            <div class="form-group mb-4 mt-2">
              <input v-model="searchQuery" placeholder="Search for..." class="form-control" />
            </div>
            <div class="subcriptions-list">
            <div class="row no-gutters" v-for="i in feeds.length" v-bind:key="i">
              <span
                v-for="feed in filterItems(feeds).slice((i - 1) * 3, i * 3)"
                class="col-sm-4 p-1"
                v-bind:key="feed.id"
              >
                <button
                  class="list-group-item"
                  style="width: 100%; display:inline-block;"
                  :class="{ 'active': feed.is_user_suscribed}"
                  @click="clickItem(feed)"
                >{{ feed.title }}</button>
              </span>
            </div>
            </div>
            <alert :message="error" type="danger"></alert>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="close">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modals from "../../ui/modals.js";
import feeds from "../../../api/feeds.js";
import users from "../../../api/users.js";
import Alert from "../../ui/alert.vue";

export default {
  name: "user-subscriptions-modal",
  components: { Alert },
  data() {
    return {
      userId: null,
      user: null,
      feeds: [],
      subscriptions: [],
      error: null,
      searchQuery: "",
      showFirstSubscribed: true
    };
  },
  methods: {
    show(user) {
      this.userId = user.id;
      this.user = user;
      this.getSubscriptions();
      this.getFeeds();
      $("#user-subscriptions-modal").modal(modals.show);
    },
    toggleShowOnlySuscribed() {
      this.showFirstSubscribed = !this.showFirstSubscribed;
    },
    close() {
      $("#user-subscriptions-modal").modal("hide");
    },
    async getFeeds() {
      const [data, err] = await feeds.getAllNoNIP();
      var _this = this;

      data.forEach(function(item, index) {
        item.is_user_suscribed = _this.subscriptions.includes(item.id);
        item.title_lowercase = item.title.toLowerCase();
        item.title_lowercase_for_sort_subscribed = item.title.toLowerCase();
        if (item.is_user_suscribed) {
          item.title_lowercase_for_sort_subscribed =
            "aaaaa_" + item.title_lowercase_for_sort_subscribed;
        }
      });

      this.feeds = data;
    },
    async getSubscriptions() {
      const [data, err] = await users.getSubscriptions(this.userId);
      this.subscriptions = data
        .filter(subscription => subscription.status === "active")
        .map(subscription => subscription.feed.id);
    },
    async clickItem(feed) {
      this.error = null;
      if (this.subscriptions.includes(feed.id)) {
        const [data, err] = await users.unsubscribe(this.userId, feed.id);
        if (err) {
          this.error = "An error has occurred while unsubscribing";
          return;
        }
        feed.is_user_suscribed = false;
        feed.title_lowercase_for_sort_subscribed = feed.title_lowercase_for_sort_subscribed.substring(
          6
        );
        this.subscriptions.splice(this.subscriptions.indexOf(feed.id), 1);
      } else {
        const [data, err] = await users.subscribe(this.userId, feed.id);
        if (err) {
          this.error = "An error has occurred while subscribing";
          return;
        }
        feed.is_user_suscribed = true;
        feed.title_lowercase_for_sort_subscribed =
          "aaaaa_" + feed.title_lowercase_for_sort_subscribed.substring(6);
        this.subscriptions.push(feed.id);
      }
    },
    filterItems: function(feeds) {
      var app = this;

      var filtered = feeds.filter(function(feed) {
        let regex = new RegExp("(" + app.searchQuery + ")", "i");
        return feed.title.match(regex);
      });

      /* Ordering by title */
      if (this.showFirstSubscribed) {
        return filtered.sort((a, b) => {
          if (a.title_lowercase_for_sort_subscribed < b.title_lowercase_for_sort_subscribed) return -1;
          if (a.title_lowercase_for_sort_subscribed > b.title_lowercase_for_sort_subscribed) return 1;
          return 0;
        });
      } else {
        return filtered.sort((a, b) => {
          if (a.title_lowercase < b.title_lowercase) return -1;
          if (a.title_lowercase > b.title_lowercase) return 1;
          return 0;
        });
      }
    }
  }
};
</script>

<style scoped>
.subcriptions-list {
  max-height: 400px;
  overflow: auto;
}
button {
  height: 100%;
}
button.active {
  background-color: #157ef3;
  border-color: #157ef3;
}

button.active:hover {
  color: #000;
}
</style>