<template>
  <div class="col-sm-4" v-bind:class="{'feed_disabled': feed.active==false}">
    <a @click="$router.push(base_url+(feed.id))">
      <div class="mb-4 full-item" v-bind:class="{'already_suscribed': feed.is_user_suscribed}">
        <img
          v-if="feed.img_cover!==null && feed.img_cover!==''"
          v-bind:src="feed.img_cover"
          class="img-thumbnail img-responsive"
        />
        <img
          v-else
          src="/assets/img/no_preview.png"
          class="img-thumbnail img-responsive"
          v-bind:class="{'already_suscribed': feed.is_user_suscribed}"
        />
        <div class="card-body">
          <p class="card-text">{{ feed.cover_title }}</p>
          <p class="extra-info">
            <span
              v-if="feed.language"
              class="icon-language"
              v-bind:class="'icon-language-'+feed.language.toLowerCase()"
            >{{feed.language}}</span>
          </p>
          <!--<div class="tags-list mt-3">
            <small class="text-muted">{{ feed.tags }}</small>
          </div>-->
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import feeds from "../../api/feeds.js";
import { isAdminUser } from "../../utils/user";

import TablePagination from "../ui/table/pagination.vue";
import FeedDetailContainer from "./feeds/detail-modal.vue";
import FeedModal from "./feeds/feed-modal.vue";

export default {
  name: "cover-feeds-table",
  props: ["feed", "base_url"],

  components: {
    FeedModal,
    TablePagination,
    FeedDetailContainer
  },

  watch: {
    $route(to, from) {
      this.handleModal(to, from);
    }
  },

  mounted() {
    this.handleModal(this.$router.currentRoute, null);
  },

  computed: {
    feedId() {
      const { feed } = this;
      return feed ? feed.narrativaID : null;
    }
  },

  methods: {
    handleModal(to, from) {
      const path = to.path;
      if (path.indexOf("/feed/") != -1) {
        const { feedId } = to.params;
        this.$refs.feedDetail.show(feedId);
      }
    },

    reload() {
      this.$emit("reload");
    },

    showDetails(feedId) {
      this.$refs.feedDetail.show(feedId);
    },

    onPageChange(dataset) {
      this.data = dataset;
    },

    createNewsFeed() {
      this.$refs.feedModal.show();
    },

    updateNewsFeed(feed) {
      this.$refs.feedModal.show(feed);
    },

    sort: function(s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    }
  }
};
</script>

<style>
.feed_disabled td {
  background-color: #efefef !important;
  color: grey;
}

.already_suscribed {
  border: 2px solid #157ef3 !important;
}

.tags-list {
  text-align: right;
}

.img-thumbnail {
  padding: 0;
  background-color: #fff;
  border-width: 0px !important;
  border-radius: 0 !important;

  width: 100% !important;
  height: 125px;
  object-fit: cover;
  display: block;
}

.card-body {
  padding: 0.5em;
}

.card-text {
  text-align: left !important;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.suscribed-legend {
  color: #157ef3;
  font-size: 1.2em;
  text-align: right;
}

.icon-suscribed-legend {
  color: #157ef3;
}

.icon-suscribed {
  color: #157ef3;
  position: absolute;
  font-size: 1.9em;
  text-align: right;
  right: 50px;
  top: 6px;
}

.icon-language {
  text-transform: uppercase;
  font-size: 0.8em;
  text-align: center;
  /*background-color: #CCC;*/
  padding: 4px;
  color: black;
  border: 0px solid black;
  /*border-radius: 100px;*/
}

/*
  .icon-language-de{
    background-color: #f7c700;
    color: black;
  }

  
  .icon-language-es{
    background-color: #b10000;
    color: white;
  }

  .icon-language-ar{
    background-color: #00742a;
    color: white;
  }

  .icon-language-en{
    background-color: #012169;
    color: white;
  }
  */

.full-item {
  border: 1px solid rgba(0, 0, 0, 0.001);
  background-color: #fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important
}

.full-item.already_suscribed{
  /*box-shadow: 0 1px 3px 0 #157ef3;*/
}



.full-item:hover {
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.5);
}

.extra-info {
  text-align: right;
  margin-bottom: 0em;
}
</style>