<template>
  <div class="modal fade" v-bind:id="'confirm-dialog'+a_id" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row message-container mt-4 mb-4">
            <div class="col-12 text-center">
              <p v-html="message" class="p-3"></p>
            </div>
          </div>
        </div>

        <div class="modal-footer justify-content-center">
          <button type="button" class="btn btn-default" @click="cancel">No</button>
          <button type="button" class="btn btn-success" @click="confirm">{{confirmButton}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modals from "./modals.js";

export default {
  props: {
    "message": String,
    "a_id": String,
    "confirmButton": {
      default: "Yes"
    }
  },
  methods: {
    cancel() {
      $("#confirm-dialog" + this.a_id).modal("hide");
      this.$emit("cancel");
    },
    confirm() {
      $("#confirm-dialog" + this.a_id).modal("hide");
      this.$emit("confirm");
    },
    show() {
      $("#confirm-dialog" + this.a_id).modal(modals.show);
    }
  }
};
</script>

<style>
.modal-header {
  border: 0 !important;
}

.modal .close {
  margin: 0;
  padding: 0;
}

h3 {
  font-size: 15px;
}

#confirm-dialog {
  top: 25%;
}
</style>