<template>
  <div>
    <h6>ID Match</h6>
    <h6>ID Local team</h6>
    <h6>ID Visitor team</h6>
    <h6>ID Round</h6>
  </div>
</template>

<script>
  export default {
    props: ['params']
  }
</script>