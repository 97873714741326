<template>
  <div>
    <div>
      <div v-if="feed" style="float:left;width:5.25em;padding-right:1em;">
        <img
          v-if="feed.img_cover!==null && feed.img_cover!==''"
          v-bind:src="feed.img_cover"
          class="feed-cover-image"
        />
        <img v-else src="/assets/img/no_preview.png" class="feed-cover-image" />
      </div>

      <div v-if="feed" style="padding-left: 6em;" >
        <h1 class="feed-title">{{ feed.cover_title }}</h1>
        <div>
          <span v-for="(category, index) in feed.categories" class="feed-category">{{category }}</span>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col" v-if="feed && feed.description">
        <dt>Description</dt>
        <dd>{{feed.description}}</dd>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../ui/spinner.vue";

import feeds from "../../api/feeds.js";
import feedsContent from "../../api/feedscontent.js";
import users from "../../api/users";
import JsonViewer from "vue-json-viewer";
import SubscriptionBetaModal from "../subscription-beta-modal.vue";

import { sleep_time } from "../../utils/utils.js";

const ITEMS_PER_PAGE = 6;

export default {
  name: "feed-title",

  props: ["feed"],

  components: {
    Spinner
  },

  data() {
    return {
      loading: false,
      loadingContent: false
    };
  }
};
</script>

<style scoped>
.feed-category {
  text-transform: capitalize;
  margin-right: 0.5em;
}
h1.feed-title {
  font-size: 2.4em !important;
  line-height: 1em;
}

.feed-cover-image {
  width: 5em;
  height: 5em;
  border: 1px solid #ccc;
  margin-right: 0.5em;
  background-color: #eee;
  object-fit: cover;
  /*display: block;*/
}
</style>