<template>
  <div class="min-height-100">
    <alert :message="error" type="danger" class="m-3" />
    <table v-show="!loading && !error" class="table table-hover table-responsive-block m-0">
      <thead>
        <tr>
          <th>Number</th>
          <th>Date</th>
          <th>Amount</th>
          <th class="text-center">Status</th>
          <th>Invoice</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(invoice, index) in invoices" :key="index">
          <td class="v-align-middle">{{ invoice['number'] }}</td>
          <td class="v-align-middle">{{ new Date(invoice['date']).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) }}</td>
          <td class="v-align-middle">${{ invoice['amount'] / 100 }}</td>
          <td class="v-align-middle text-center">
            <span class="fa icon-success" v-bind:class="[ invoice['paid'] ? 'fa-check icon-success' : 'fa-times icon-failure' ]" />
          </td>
          <td class="v-align-middle">
            <button class="btn btn-success" @click="downloadInvoice(invoice['url'])"><i class="fa fa-download"/> Download </button>
          </td>
        </tr>
      </tbody>
    </table>
    <spinner klass="centered-spinner" :loading="loading" />
  </div>
</template>

<script>
  import Alert from '../../../ui/alert.vue'
  import Spinner from '../../../ui/spinner.vue'
  import users from '../../../../api/users.js';
  import consts from '../../../consts.js';

  export default {
    components: {
      Alert,
      Spinner,
    },
    props: [ 'subscription' ],
    data() {
      return {
        loading: false,
        error: null,
        invoices: [],
      }
    },
    watch: {
      subscription: function() {
        this.getInvoices();
      },
    },
    created() {
      this.getInvoices();
    },
    methods: {
      async getInvoices() {
        this.loading = true;

        const [ data, err ] = await users.getSubscriptionInvoices(this.subscription.subscriptionId);

        this.loading = false;

        if (err || data.status !== 'success') {
          this.error = consts.REQUEST_ERR;
          return;
        }

        this.invoices = data.invoices;
      },
      downloadInvoice(url) {
        window.open(url);
      }
    },
  }
</script>

<style>
  .min-height-100 { min-height: 100px; }
  .icon-success { color: green; }
  .icon-failure { color: red; }
</style>