import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Verification extends Endpoint {
  constructor() {
    super('/verify')
    bindAll(this, [ 'verify' ])
  }

  verify(hash) {
    return this.post(`/${hash}`)
  }
}


export default new Verification()