<template>
  <div>
    <h2 class="reset-password__title">{{ title }}</h2>
    <h6 class="reset-password__copy">{{ message }}</h6>
    <div class="d-flex justify-content-center">
      <router-link to="/user/login" class="reset-password__btn btn btn-primary btn-cons btn-lg">Sign in</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'onboard-message',
    props: ['title', 'showbtn', 'message'],
    methods: {
      nav() {
        window.location='/dashboard'
      }
    }
  }
</script>

<style scoped>
.reset-password__title {
  font-size: 28px !important;
  font-weight: 800;
  margin-bottom: 1rem;
}

.reset-password__copy {
  font-size: 16px;
  margin-bottom: 2rem;
}

.reset-password__btn {
  font-size: 16px;
  font-weight: 800;
}

.reset-password__btn:hover {
  background-color: #000;
  color: #fff;
}
</style>