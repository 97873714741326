<template>
  <div>
    <div class="header pr-0 page_header" :class="{'header_bottom_line':!isUserLogged}">
      <dashboard-header></dashboard-header>
      <tabs></tabs>
    </div>
    <div class="dashboard page-container">
      <div class="page-content-wrapper">
        <div class="container-fluid p-0">
          <div class="row no-gutters" style="min-height:700px;">
            <div class="col-12">
              <router-view></router-view>
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </div>
      <!-- page-content-wrapper -->

      <terms title="Subscriptions Terms and condition" id="subscription-tos" />
      <terms title="Terms of use" id="termsofuse-modal" />
      <terms title="Privacy Policy" id="privacy-policy-modal" />
    </div>
    <signup-popup v-if="ENABLED" title="Sign up" id="signup-popup" />
    <!-- page-container -->
    <div class="container container-fixed-lg footer" id="footer">
      <common-footer></common-footer>
    </div>
  </div>
</template>

<script>
import ENABLED from '../toggle_feature'
import DashboardHeader from "../components/dashboard/header/header.vue";
import Tabs from "../components/dashboard/tabs.vue";
import Terms from "../components/terms.vue";
import ClientStatus from "../components/dashboard/client-status-switch";
import CommonFooter from "./common-footer.vue";
import { isUserLogged } from "../utils/user";
import SignupPopup from '../components/signup-popup.vue';

export default {
  name: "cover-dashboard-container",
  components: {
    DashboardHeader,
    Tabs,
    Terms,
    ClientStatus,
    CommonFooter,
    SignupPopup,
  },

  mounted() {
    document.body.className =
      "fixed-header horizontal-menu horizontal-app-menu";
  },
  data() {
    return {
      isUserLogged: isUserLogged(), 
      ENABLED: ENABLED,
    };
  }
};
</script>

<style scoped>
.page_header {
  background-color: black !important;
  border-bottom: 0.5em solid #fedd4e !important;
}

.header_bottom_line {
  border-bottom: 0.5em solid #fedd4e !important;
}

.dashboard {
  background-color: #eee !important;
}
</style>