<template>
  <div class="card-block">
    <table class="table table-hover table-responsive-block">
      <thead>
        <tr>
          <th>#</th>
          <th>Subscription</th>
          <th>Subscribed on</th>
          <th>Billing Period</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(sub, index) in subscriptions" :key="index">
          <td class="v-align-middle">{{ index + 1 }}</td>
          <td class="v-align-middle">
            Subscription to {{ sub.feed.title }}
          </td>
          <td class="v-align-middle">
            {{ new Date(sub.createdOn).toLocaleDateString() }}
          </td>
          <td class="v-align-middle">
            Monthly
          </td>
          <td class="v-align-middle">
            {{ getStatus(sub) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import SubscriptionsModal from './subscription-detail.vue';

  export default {
    props: ['subscriptions'],
    components: {
      SubscriptionsModal
    },

    methods: {
      viewDetails(subscription) {
        this.$emit('viewDetails', subscription);
      },
      getStatus(subscription) {
        if (subscription.status === 'active') {
          return 'Subscribed';
        } else if (subscription.status === 'cancelled') {
          return 'Cancelled';
        } else {
          return 'Unsubscribed';
        }
      }
    }
  }
</script>

<style>
  .badge-big {
    padding: 5px 20px !important;
  }
</style>