<template>
  <div>
    <p>
      <span v-if="a_feed_content && a_feed_content.section1" class="section">
        <a>{{ a_feed_content.section1 }}</a>
      </span>
      <span v-if="a_feed_content && a_feed_content.section2" class="section">
        <a>| {{ a_feed_content.section2 }}</a>
      </span>
      <span v-if="a_feed_content && a_feed_content.section3" class="section">
        <a>| {{ a_feed_content.section3 }}</a>
      </span>
    </p>
    <h1>{{ a_feed_content.title }}</h1>
    <h2>{{ a_feed_content.subtitle }}</h2>
    <h3>{{ a_feed_content.leadin }}</h3>
    <p v-html="a_feed_content.content">{{ a_feed_content.content }}</p>
    <div v-if="a_feed_content.picurl">
      <hr />
      <p v-if="a_feed_content.picurl" class="picurl">
        <a v-bind:href="a_feed_content.picurl" target="_blank">
          <img
            class="img-fluid"
            v-bind:src="a_feed_content.picurl + '&scale=0.8&extension=.jpg'"
          />
        </a>
      </p>
    </div>
    <!-- !picurl -->
    <p v-if="a_feed_content.tag" class="tags"><strong>Tags:</strong> {{ a_feed_content.tag }}</p>
    <div v-if="a_feed_content.metadata">
      <hr />

      <div class="accordion" id="'#accordionExample' + a_feed_content.id">
        <h2 class="mb-1">
          <a
            data-toggle="collapse"
            v-bind:data-target="'#collapseOne' + a_feed_content.id"
            aria-expanded="true"
            v-bind:aria-controls="'collapseOne' + a_feed_content.id"
          >
            Metadata
            <i class="fa fa-plus-square" aria-hidden="true"></i>
          </a>
        </h2>
      </div>

      <div
        v-bind:id="'collapseOne' + a_feed_content.id"
        aria-labelledby="headingOne"
        class="collapse"
        v-bind:data-parent="'#accordionExample' + a_feed_content.id"
      >
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Key</th>
                <th scope="col">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in a_feed_content.metadata">
                <th scope="row">{{ key }}</th>
                <td v-html="item">{{ item }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- metadata -->
  </div>
</template>

<script>
import feedsContent from "../../api/feedscontent.js";
import { formatDate } from "../../utils/utils.js";
import JsonViewer from "vue-json-viewer";

export default {
  name: "feed-preview-simple",

  props: ["a_feed_content"],

  methods: {
    formatDate(stringDate) {
      return formatDate(stringDate);
    },
  },
};
</script>

<style>
.feed-post {
  border: 1px solid #ccc;
  margin-bottom: 3em;
  padding: 1em;
  background-color: #fff;
}

.feed-post-time {
  border: 1px solid #ccc;
  padding: 0.3em 1em 0.3em 1em;
  display: inline-block;
  background-color: #eee;
}

h1 {
  font-size: 1.5em !important;
  line-height: 1.3em;
}

h2 {
  font-size: 1.25em !important;
  line-height: 1.3em;
}

h3 {
  font-size: 1em !important;
  line-height: 1.3em;
}

#metainfo {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1em;
}

.picurl {
  text-align: center;
}

.btn-validation {
  margin-left: 1em;
  margin-right: 0em;
}

.tags {
  font-style: italic;
}

.section{
  text-transform: capitalize;
}
</style>