<template>
  <div>
    <div class="header p-r-0 page_header">
      <dashboard-header></dashboard-header>
      <tabs></tabs>
    </div>

    <div class="dashboard page-container">
      <div class="page-content-wrapper">
        <router-view></router-view>
      </div>

      <terms title="Subscriptions Terms and condition" id="subscription-tos" />
      <terms title="Terms of use" id="termsofuse-modal" />
      <terms title="Privacy Policy" id="privacy-policy-modal" />
    </div>
  </div>
</template>

<script>
  import DashboardHeader from '../components/dashboard/header/header.vue';
  import Tabs from '../components/dashboard/tabs.vue';
  import Terms from '../components/terms.vue';
  import CommonFooter from "./common-footer.vue";

  export default {
    name: 'dashboard-container',
    components: {
      DashboardHeader,
      Tabs,
      Terms,
      CommonFooter
    },

    mounted() {
      document.body.className = "fixed-header horizontal-menu horizontal-app-menu"
    }
  }

</script>

<style scoped>
.page_header{
  background-color: black !important;
}

</style>