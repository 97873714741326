import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class StripePayment extends Endpoint {
  constructor() {
    super('/stripe')
    bindAll(this, [
      'customer_portal',
      'subs_state',
    ])
  }

    customer_portal(data) {
    return this.post('/customer-portal', data)
  }

  subs_state(data) {
    return this.post('/subs-state', data)
  }
}

const strype_payment = new StripePayment();
export default strype_payment
