<template>
    <p v-if="validations && validations.length" class="password-error">It must have <span :class="{'error': !validations[0].valid}">at least 8 characters</span>, <span :class="{'error': !validations[1].valid}">uppercase</span><span :class="{'error': !validations[2].valid}"> and lowercase letters</span>, <span :class="{'error': !validations[3].valid}">numbers</span> and <span :class="{'error': !validations[4].valid}">one special character</span>.</p>
</template>

<script>
export default {
    name: "PasswordValidationMsg",
    data () {
        return {}
    },
    props: {
        validations: Array
    }
}
</script>

<style scoped>
.password-error {
    font-size: 13px;
}
.password-error .error {
    color: #157ef3;
    display: inline-block;
    font-size: 13px;
}
</style>