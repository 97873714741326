import { render, staticRenderFns } from "./signup-popup.vue?vue&type=template&id=3fa6d57a&scoped=true&"
import script from "./signup-popup.vue?vue&type=script&lang=js&"
export * from "./signup-popup.vue?vue&type=script&lang=js&"
import style0 from "./signup-popup.vue?vue&type=style&index=0&id=3fa6d57a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fa6d57a",
  null
  
)

export default component.exports