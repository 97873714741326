<template>
  <div class="mb-4">
    <div class="container-fluid" v-show="!loading" id="feed-back-header">
      <div class="container">
        <div class="row">
          <div class="col mt-4 mb-4">
            <a @click="$router.go(-1)">
              <i class="fa fa-arrow-left"></i>&nbsp;Back
            </a>
          </div>
          <!-- col -->
        </div>
        <!-- row -->
        <div>
          <feed-title :feed="feed"></feed-title>
        </div>
        <div class="row mb-4" id="subscribe">
          <div class="col-md-3 offset-md-9 mb-4">
            <button
              v-if="!isUserLogged"
              class="btn btn-lg btn-warning btn-block"
              @click="showSubscriptionWarningModal"
            >
              <i class="fa fa-shopping-cart" aria-hidden="true"></i> Subscribe to this Feed
            </button>
            <button
              v-if="isUserLogged"
              class="btn btn-lg btn-warning btn-block"
              @click="$router.push('/feed/details/'+(feed.id))"
            >
              <i class="fa fa-shopping-cart" aria-hidden="true"></i> Subscribe to this Feed
            </button>
          </div>
        </div>
        <!-- subscribe button -->
      </div>
    </div>
    <!-- container-fluid -->
    <div class="container">
      <!-- row -->
      <div class="row">
        <div class="col-md-9">
          <div clas="row">
            <h1>
              <i class="fa fa-eye" aria-hidden="true"></i> Sample
            </h1>
            <p
              class="mt-4 mb-4"
              v-if="a_feed"
            >This is how it could look a sample of one of our posts for this feed in your site. Interested? Click in the subscription button.</p>
          </div>
          <div class="row" v-if="a_feed" id="tabs">
            <div
              class="col-md-4"
              v-bind:class="{ 'tab-active': !preview_code, 'tab': preview_code }"
            >
              <a @click="set_preview_newspaper">
                <h2>
                  <i class="fa fa-star" aria-hidden="true"></i> Your Site
                </h2>
              </a>
            </div>
            <div
              class="col-md-4"
              v-bind:class="{ 'tab-active': preview_code, 'tab': !preview_code }"
            >
              <a @click="set_preview_code">
                <h2>
                  <i class="fa fa-code" aria-hidden="true"></i> Feed Code
                </h2>
              </a>
            </div>
          </div>
          <div v-else v-show="!loadingContent" class="mt-4">
            <p class="text-center">
              <strong><i class="fa fa-moon-o" aria-hidden="true"></i> No content samples currently available for this feed.</strong>
              <br />
              <br />Contact us at <a href="mailto:customer-support@narrativa.com">customer-support@narrativa.com</a> for more information on content.
            </p>
          </div>
          <div id="code" class="preview" v-if="a_feed && this.preview_code">
            <div class="row">
              <div class="col">
                <h3>This is the code that our feed returns for the example post.</h3>
                <json-viewer :value="a_feed" :expand-depth="5" expanded sort></json-viewer>
              </div>
            </div>
          </div>
          <feed-preview-newspaper :post="a_feed" v-if="a_feed && !this.preview_code"></feed-preview-newspaper>
          <spinner klass="centered-spinner" :loading="loading" />
        </div>
        <!-- col9 -->
        <div class="col-md-3">
          <div clas="row">
            <h1>
              <i class="fa fa-info" aria-hidden="true"></i> Details
            </h1>
          </div>
          <feed-features-table :feed="feed" :suscribed="false"></feed-features-table>
        </div>
      </div>
    </div>
    <!-- container -->
    <subscription-beta-modal ref="showSubscriptionWarningModal"></subscription-beta-modal>
  </div>
</template>

<script>
import FilterView from "./filter.vue";
import FeedFeaturesTable from "./feed-features-table.vue";
import FeedPreviewNewspaper from "./feed-preview-newspaper.vue";
import FeedTitle from "./feed-title.vue";
import CoverFeedTable from "./cover-feed-table.vue";
import Spinner from "../ui/spinner.vue";
import CoverFeedDetailPost from "./cover-feed-detail-post.vue";
import CoverFeedDetailPostSuscribed from "./cover-feed-detail-post-suscribed.vue";

import feeds from "../../api/feeds.js";
import feedsContent from "../../api/feedscontent.js";
import users from "../../api/users";
import { isUserLogged } from "../../utils/user";
import JsonViewer from "vue-json-viewer";
import SubscriptionBetaModal from "../subscription-beta-modal.vue";

import { sleep_time } from "../../utils/utils.js";

const ITEMS_PER_PAGE = 6;

export default {
  name: "catalog-cover-feed-detail",

  components: {
    FilterView,
    Spinner,
    CoverFeedDetailPost,
    CoverFeedDetailPostSuscribed,
    JsonViewer,
    SubscriptionBetaModal,
    FeedFeaturesTable,
    FeedPreviewNewspaper,
    FeedTitle
  },

  mounted() {
    this.getFeed(this.$route.params.feedId);
    this.getFeedContent(this.$route.params.feedId);
  },

  data() {
    return {
      loading: false,
      loadingContent: false,
      loaded: false,
      feed: null,
      feedContent: null,
      currentFeed: null,
      from: ITEMS_PER_PAGE,
      to: 0,
      itemsPerPage: ITEMS_PER_PAGE,
      next_is_active: false,
      preview_code: false,
      isUserLogged: isUserLogged(),
      a_feed: null
    };
  },

  methods: {
    showSubscriptionWarningModal() {
      this.$refs.showSubscriptionWarningModal.show();
    },

    set_preview_code() {
      this.preview_code = true;
    },

    set_preview_newspaper() {
      this.preview_code = false;
    },

    async getFeed(feedId) {
      this.feed = null;
      this.loading = true;
      let response;

      response = await feeds.getById(feedId);

      const [data, err] = response;

      if (!err) {
        this.feed = data;
      }

      this.loading = false;
      this.loaded = true;

      return data;
    },

    async getFeedNoLoading(feedId) {
      let response;

      response = await feeds.getById(feedId);

      const [data, err] = response;

      if (!err) {
        this.feed = data;
      }

      return data;
    },

    async getFeedContent(feedId) {
      this.feedContent = null;
      this.a_feed = null;
      this.loadingContent = true;
      let response;

      var is_suscribed = false;

      while (!this.feed) {
        await sleep_time(200);
      }

      response = await feedsContent.getFeedContentDemoById(
        feedId,
        this.from + 1,
        this.to
      );

      const [data, err] = response;
      this.loading = false;
      this.loadingContent = false;

      //that's why we load one more
      this.next_is_active = data.length > this.itemsPerPage;

      if (this.next_is_active) {
        console.log("There are enoguh posts to active the 'next' button");
        data.length = this.itemsPerPage;
      }

      if (!err) {
        this.feedContent = data;
        this.a_feed = data.data.post[0];
      } else {
        console.log("Error while reading the demo feed");
      }
    },

    onPageChange(dataset) {
      console.log("called to onPageChange");
      /*this.data = dataset*/
    },

    prev() {
      console.log("Prev page");
      this.from = Math.max(this.itemsPerPage, this.from - this.itemsPerPage);
      this.to = Math.max(0, this.to - this.itemsPerPage);
      this.getFeedContent(this.$route.params.feedId);
    },

    next() {
      console.log("Next page");
      this.from = this.from + this.itemsPerPage;
      this.to = this.to + this.itemsPerPage;

      this.getFeedContent(this.$route.params.feedId);
    },

    onChangeItemsPerPage(value) {
      var prev_itemsPerPage = this.itemsPerPage;
      console.log(
        "onChangeItemsPerPage:" + value + "; Previous:" + prev_itemsPerPage
      );

      this.itemsPerPage = value;

      /*Changes only the "from"*/
      this.from = this.from + (this.itemsPerPage - prev_itemsPerPage);

      this.getFeedContent(this.$route.params.feedId);
    },

    hasNext() {
      return true;
    },

    hasPrev() {
      return true;
    }
  }
};
</script>

<style>
#feed-back-header {
  background-color: #157ef3;
  color: white;
}

#feed-back-header h1.feed-title {
  color: white !important;
}

#tabs {
  margin-left: 0px;
}

#tabs div {
  margin-right: 3px;
}
#tabs h2 {
  color: white !important;
}

#tabs .tab-active {
  background-color: #000;
}

#tabs .tab {
  background-color: #fedd4e;
}

#tabs .tab h2 {
  color: black !important;
}

#subscribe {
  margin-top: 2em;
  margin-bottom: 4em !important;
}
</style>