<template>
  <div>
    <spinner klass="centered-spinner" :loading="loading" />
    <div>
      <div v-if="usageReportHistoryCurrentMonth && usageReportHistoryCurrentMonth.length">
        <!-- RECOVER WHEN PLANS ARE AVAILABLE
        <div v-if="!plan">
          <div class="text-center mt-4 mb-4">
            <h2 class="text-danger">
              <i class="fa fa-bell" aria-hidden="true"></i> Warning! No plan selected
            </h2>
            <p>You have to be subscribed to a plan to access our content. Please, choose one.</p>
          </div>
        </div>
        -->

        <div v-if="usage_report.has_user_pass_the_plan_limit">
          <div class="text-center mt-4 mb-4">
            <h2 class="text-danger">
              <i class="fa fa-bell" aria-hidden="true"></i> Warning! You have reached your plan's limit
            </h2>
          </div>

          <p v-if="plan">
            Your current plan
            <strong>does not have enough credit to access to latests posts</strong> that are in the feeds for you. At this time there are
            <strong>{{usage_report.published_docs.toLocaleString()}}</strong> published docs on your feeds but your plan covers up to
            <strong>{{plan.limit.toLocaleString()}}</strong>.
          </p>
          <p>
            The latest post you have access to is from
            <strong>{{formatDate(usage_report.last_date_allowed_because_plan_limit)}}</strong>. If you want to access to more posts, please, choose another plan with more credits.
          </p>
        </div>
        <div v-else>
          <p
            v-if="plan"
          >We have published {{usage_report.published_docs.toLocaleString()}} posts for you during this period.</p>
        </div>

        <usage-report-details-report-table
          :monthYear="currentMonthYear"
          :listOfFeedsReport="usageReportHistoryCurrentMonth"
          class="mb-4"
        ></usage-report-details-report-table>
      </div>

      <div v-if="usageReportHistoryCurrentMonth" class="row justify-content-center mt-4">
        <div class="col-lg-6 mt-4">
          <button class="btn btn-success btn-block btn-lg" @click="getUsageReportHistoryPastMonths">
            <i class="fa fa-arrow-down" aria-hidden="true"></i> My Usage Report
            <i class="fa fa-arrow-down" aria-hidden="true"></i>
          </button>
        </div>
      </div>

      <br />
      <br />
      <div v-if="usageReportHistoryPastMonths">
        <h1 class="mb-4">
          <i class="fa fa-history" aria-hidden="true"></i> Report: Past Periods
        </h1>
        <div>
          <spinner klass="centered-spinner" :loading="loadingPastPeriods" id="loadingPastPeriods" />
        </div>
        <div
          v-for="(listOfFeedsReport, monthYear) in usageReportHistoryPastMonths"
          v-bind:key="monthYear"
          class="mb-4 pb-4"
        >
          <usage-report-details-report-table
            :monthYear="monthYear"
            :listOfFeedsReport="listOfFeedsReport"
            class="mb-4 mt-4"
          ></usage-report-details-report-table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "../ui/spinner.vue";
import users from "../../api/users.js";
import usageReportDetailsReportTable from "./usage-report-details-report-table.vue";
import { isAdminUser } from "../../utils/user";
import { formatDate } from "../../utils/utils.js";

export default {
  name: "dashboard",

  components: {
    Spinner,
    usageReportDetailsReportTable
  },

  mounted() {
    this.getUsageReportHistoryCurrentMonth();
  },

  data() {
    return {
      loading: true,
      loadingPastPeriods: false,
      usageReportHistoryPastMonths: null,
      usageReportHistoryCurrentMonth: null,
      isAdminUser: isAdminUser(),
      token: JSON.parse(localStorage.getItem("user")).apiKey
    };
  },

  computed: {
    usage_report() {
      return this.$store.state.userUsageReport;
    },
    plan() {
      return this.$store.state.plan;
    },
    currentMonthYear() {
      return (new Date().getMonth()+1) + "/" + new Date().getFullYear();
    }
  },

  methods: {
    formatDate(stringDate) {
      return formatDate(stringDate);
    },

    orderReport(data) {
      data.sort(function(a, b) {
        var key_a = (a.year * 100 + a.month).toString();
        var key_b = (b.year * 100 + b.month).toString();

        if (key_a > key_b) {
          return -1;
        }
        if (key_b > key_a) {
          return 1;
        }

        if (key_b == key_a) {
          if (a.feed_name.toLowerCase() > b.feed_name.toLowerCase()) {
            return 1;
          }
          if (b.feed_name.toLowerCase() > a.feed_name.toLowerCase()) {
            return -1;
          } else {
            return 0;
          }
        }
        return 0;
      });

      return data;
    },

    async getUsageReportHistoryCurrentMonth() {
      this.usageReportHistory = null;
      this.loading = true;

      const userId = JSON.parse(localStorage.getItem("user")).id;
      const response = await users.getUsageReportHistoryCurrentMonth(userId);

      const [data, err] = response;

      if (!err) {
        let sortedData = this.orderReport(data);

        let current_month_data = data.filter(
          plan => plan.is_current_month === true
        );

        this.usageReportHistoryCurrentMonth = current_month_data;
        this.loading = false;
      }
    },
    async getUsageReportHistoryPastMonths() {
      this.usageReportHistory = null;
      this.loadingPastPeriods = true;
      let response;

      const userId = JSON.parse(localStorage.getItem("user")).id;
      response = await users.getUsageReportHistoryPastMonths(userId);

      const [data, err] = response;

      if (!err) {
        let sortedData = this.orderReport(data);

        let past_months_data = sortedData.filter(
          plan => plan.is_current_month === false
        );

        var _this = this;
        var pastMonthsDataDict = {};

        var currentKey = "";
        past_months_data.forEach(function(item, index) {
          let key = item.month + "/" + item.year;
          if (key != currentKey) {
            pastMonthsDataDict[key] = [];
            currentKey = key;
          }

          pastMonthsDataDict[currentKey].push(item);
        });

        this.usageReportHistoryPastMonths = pastMonthsDataDict;
        this.loadingPastPeriods = false;
      }
    }
  }
};
</script>

<style>
.feeds-view {
  min-height: 60vh;
}

table {
  border: 1px solid #eaeaea;
}

table td.year,
td.month {
  text-align: center;
}

table td.posts {
  text-align: right;
}

.selected-plan {
  border: 2px solid #157ef3;
  background-color: #157ef3;
  color: white !important;
}

h4.selected-plan {
  color: white;
}
</style>