<template>
  <div class="container">
    <div class="content">
      <div class="no-padding container-fixed-lg">
        <div class="row">
          <div class="col mt-4 mb-4">
            <a @click="$router.go(-1)">
              <i class="fa fa-arrow-left"></i>&nbsp;Back
            </a>
          </div>
          <!-- col -->
        </div>

        <!-- row -->

        <div class="row">
          <div class="col">
            <h1 class="examples-title" v-show="!loadingContent">
              Post content
            </h1>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div v-show="!loading">
              <div v-if="post">
                <cover-feed-detail-post-suscribed
                  :a_feed_content="post"
                  :feed_info="feed"
                  @refreshContent="refreshContent"
                ></cover-feed-detail-post-suscribed>
              </div>
              <div v-else>Loading post...</div>
            </div>
            <spinner klass="centered-spinner" :loading="loadingContent" />
          </div>
          <!-- col -->
        </div>
        <!-- row -->
      </div>
      <spinner klass="centered-spinner" :loading="loading" />
    </div>
  </div>
</template>

<script>
import FilterView from "./filter.vue";
import FeedFeaturesTable from "./feed-features-table.vue";
import CoverFeedTable from "./cover-feed-table.vue";
import Spinner from "../ui/spinner.vue";
import CoverFeedDetailPost from "./cover-feed-detail-post.vue";
import CoverFeedDetailPostSuscribed from "./cover-feed-detail-post-suscribed.vue";
import PaginationCover from "../ui/table/pagination-cover.vue";

import feedsContent from "../../api/feedscontent.js";

import { isAdminUser } from "../../utils/user";



const ITEMS_PER_PAGE = 10;

export default {
  name: "cover-feed-detail",

  components: {
    FilterView,
    Spinner,
    CoverFeedDetailPost,
    CoverFeedDetailPostSuscribed,
    PaginationCover,
    FeedFeaturesTable,
  },

  mounted() {
    this.getDocContent(this.$route.params.docId);
  },

  data() {
    return {
      loading: false,
      loadingContent: false,
      feed: null,
      feedContent: null,
      a_feed: null,
      currentFeed: null,
      from: ITEMS_PER_PAGE,
      to: 0,
      itemsPerPage: ITEMS_PER_PAGE,
      next_is_active: false,
      isAdminUser: isAdminUser(),
      showFeedDetails: false,
      post: null,
    };
  },

  methods: {
    async getDocContent(docId) {
      console.log("Calling to getDocContent");
      this.feedContent = null;
      this.loadingContent = true;
      let response;

      const user = JSON.parse(localStorage.getItem("user"));
      // this.from+1 to check if the "next" button can be active
      response = await feedsContent.getPost(docId);

      const [data, err] = response;
      this.loading = false;
      this.loadingContent = false;

      if (!err && data) {
        this.post = data;
      } else {
        console.log("Error while reading the demo feed");
      }
    },
     refreshContent(){
      this.getDocContent(this.$route.params.docId);
    },
  },
};
</script>

<style>
.feed-title {
  font-size: 2em;
  display: inline;
}

.examples-title {
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  /*border-bottom: 1px solid #ddd;*/
}

#manual_validation_buttons {
  font-size: 10px;
  float: right;
  margin-top: 1em;
}

#manual_validation_buttons button {
  font-size: 1em;
  padding: 0em 1em 0em 1em;
}
#manual_validation_buttons p {
  font-size: 1.5em;
  margin-bottom: 0px;
}
h1 {
  color: black !important;
}

a.btn-success {
  color: #fff !important;
}
</style>