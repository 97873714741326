<template>
  <div class="content">
    <div class="no-padding container-fixed-lg">
      <div class="row">
        <div class="col mt-4 mb-4">
          <a @click="$router.go(-1)">
            <i class="fa fa-arrow-left"></i>&nbsp;Back
          </a>
        </div>
      </div>
      <!-- row -->
      <div class="feeds-view">
        <!--<h1>
          <i class="fa fa-credit-card" aria-hidden="true"></i> My Subscription
        </h1>
        <plans-table></plans-table>
        -->
        <h1>
          <i class="fa fa-list-alt" aria-hidden="true"></i> Report: Current Period
        </h1>
        <div>
          <spinner klass="centered-spinner" :loading="loading" />
        </div>
        <usage-report-details></usage-report-details>
      </div>
    </div>
  </div>
</template>

<script>
import PlansTable from "./plans-table.vue";
import UsageReportDetails from "./usage-report-details.vue";
import Spinner from "../ui/spinner.vue";

export default {
  name: "dashboard",

  components: {
    Spinner,
    PlansTable,
    UsageReportDetails
  },

  mounted() {
    document.querySelector(".page-container").classList.add("manage-plans");
  },
  destroyed () {
    document.querySelector(".page-container").classList.remove("manage-plans");
  },
  data() {
    return {
      loading: false
    };
  },

  methods: {}
};
</script>

<style>
.feeds-view {
  min-height: 60vh;
}

.feeds-view i {
  font-size: 18px !important;
}

table {
  border: 1px solid #eaeaea;
}
</style>