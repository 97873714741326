<template>
  <div class="email">
    <h2 v-if="title" class="email__title">{{ title }}</h2>
    <h2 v-else class="email__title">Please, check your email <span style="font-weight: bold">{{email}}</span>{{intent}}.</h2>
    <p v-if="title" class="email__check-email text-left">Please, check your email <span style="font-weight: bold">{{email}}</span>{{ intent }}.</p>
    <p class="email__check-email text-left">{{longMessage}}</p>
    <p class="email__support text-left mb-5">
      Still no email? Contact us at <a href="mailto:customer-support@narrativa.com">customer-support@narrativa.com</a>
    </p>
    <div class="d-flex justify-content-center">
      <router-link v-if="showbtn" to="/user/login" tag="button" class="btn btn-primary btn-cons btn-lg">Sign in</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'email-message',
    props: {
        email: String,
        intent: String,
        title: String,
        longMessage: String,
        showbtn:Boolean,
      }
  }
</script>

<style scoped>

.email a {
  color: #000;
  text-decoration: underline;
}
.email__title {
  font-size: 1.5rem !important;
  font-weight: 800;
  margin-bottom: 2rem;
}

.email__check-email {
  font-size: 16px;
}

.email__support {
  font-size: 16px;
}

.email button {
  font-size: 16px;
  font-weight: 800;
}

.email button:hover {
  background-color: #000;
  color: #fff;
}
</style>