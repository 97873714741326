import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'
import store from './store'

import axios from 'axios'

import '../assets/css/app.css'
import users from './api/users'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'
})



const App = new Vue({
  router,
  store
})


/* Axios interceptor to send to login when get unauthoriez response */
const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  response => response,
  error => {

    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      if (error.response.data.redirect === "false") {

        return Promise.reject(error);
      }
        const unathorized_retry = localStorage.getItem('unathorized_retry');

        if (!unathorized_retry) {

          store.dispatch('logOutUser')
          router.push('/user/login')
        }
          localStorage.removeItem('unathorized_retry')

    }
    return Promise.reject(error);
 }
);

Vue.prototype.axios = axios


App.$mount('#app')

window.setInterval(async () => {
  if (localStorage.getItem('user')) {
    await users.active();
  }
}
  , 30000);