import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Updates extends Endpoint {
  constructor() {
    super('/updates');
    bindAll(this, [ 'getLatestsUpdates' ]);
  }

  getLatestsUpdates() {
    return this.get('');
  }

}


export default new Updates()