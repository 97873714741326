<template>
  <div class="mt-4">
    <h1 class="mb-4">Users Management</h1>
    <div v-if="isAdminUser" class="form-group mb-4 mt-2">
      <button class="btn btn-success mb-4 mt-2" @click="showCreateUserModal">Create User</button>
    </div>

    <users-table
      :users="users"
      @updateUser="showUpdateUserModal"
      @deleteUser="showDeleteUserModal"
      @editUserSubscriptions="showUserSubscriptionsModal"
    ></users-table>
    <user-modal ref="userModal" @createUser="createUser" @updateUser="updateUser"></user-modal>
    <user-subscriptions-modal ref="userSubscriptionsModal"></user-subscriptions-modal>
    <alert v-bind:message="error" type="danger"></alert>
    <confirm-dialog ref="confirmDialog" :message="confirmMessage" @confirm="deleteUser"></confirm-dialog>
    <spinner klass="centered-spinner" :loading="loading" />
  </div>
</template>

<script>
import UsersTable from "./users-table.vue";
import { isAdminUser } from "../../../utils/user";
import users from "../../../api/users";
import UserModal from "./user-modal.vue";
import Alert from "../../ui/alert.vue";
import Spinner from "../../ui/spinner.vue";
import ConfirmDialog from "../../ui/confirm-dialog.vue";
import UserSubscriptionsModal from "./user-subscriptions-modal.vue";

export default {
  name: "users-management",
  components: {
    UserSubscriptionsModal,
    ConfirmDialog,
    Spinner,
    Alert,
    UserModal,
    UsersTable
  },
  data() {
    return {
      users: [],
      userToDelete: null,
      isAdminUser: isAdminUser(),
      error: null,
      loading: false,
      confirmMessage: "Are you sure you want to delete the user?"
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.loading = true;
      const [data, err] = await users.getUsers();
      this.loading = false;

      if (err) {
        this.error = err;
        return;
      }

      this.users = data;
    },
    showCreateUserModal() {
      this.$refs.userModal.show();
    },
    showUpdateUserModal(user) {
      this.$refs.userModal.show(user);
    },
    showDeleteUserModal(userId) {
      this.userToDelete = userId;
      this.$refs.confirmDialog.show();
    },
    showUserSubscriptionsModal(user) {
      this.$refs.userSubscriptionsModal.show(user);
    },
    async createUser(user) {
      this.error = null;
      this.loading = true;
      const [data, err] = await users.createUser(user);
      this.loading = false;
      if (err) {
        this.error = err.data.message;
      }
      this.getUsers();
    },
    async updateUser(user) {
      this.error = null;
      this.loading = true;
      const [data, err] = await users.updateUser(user.id, user);
      this.loading = false;
      if (err) {
        this.error = err.data.message;
      }
      this.getUsers();
    },
    async deleteUser() {
      this.error = null;
      this.loading = true;
      const [data, err] = await users.deleteUser(this.userToDelete);
      this.loading = false;
      if (err) {
        this.error = err.data.message;
      }
      this.getUsers();
    }
  }
};
</script>

<style scoped>
</style>