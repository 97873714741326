<template>
  <div class="row">
    <div class="col-md-5">
      <div class="card card-transparent">
        <div class="card-header">
          <div class="card-title">Your Payment methods</div>
        </div>
        <div class="card-block">
          <h3>Manage your payment methods</h3>
          <br>
        </div>
      </div>
    </div>

    <div class="col-md-7">
      <div class="card">
        <div class="card-block payment-methods-block">
          <empty-methods v-if="isEmpty" />

          <div v-show="!loading">
            <payment-methods :cards="cards" :primary="primary"
                             @selected="onSelected($event)" />

            <div class="ta-r">
              <button class="btn btn-success mt-2" @click="action">{{ actionText }}</button>
              <button class="btn btn-danger mt-2" v-show="active" @click="deleteMethod">Delete</button>
            </div>
          </div>
        </div>

        <spinner klass="centered-spinner" :loading="loading" />
      </div>
    </div>

    <card-modal @added='onAdded' ref="cardmodal" title="Add payment method"/>
    <progress-dialog ref='pdialog'
                     :message="dialog.message"
                     @onclose="onPdialogClose" />
  </div>
</template>

<script>
  import Alert from '../ui/alert.vue'
  import Spinner from '../ui/spinner.vue'

  import CardModal from './card-modal/modal.vue'
  import EmptyMethods from './payment-methods/empty-methods.vue'
  import PaymentMethods from './payment-methods/list.vue'
  import ProgressDialog from '../ui/progress-dialog.vue'

  import billing from '../../api/billing.js'
  import consts from '../consts.js'

  export default {
    components: {
      Alert,
      Spinner,
      CardModal,
      EmptyMethods,
      PaymentMethods,
      ProgressDialog
    },

    data() {
      return {
        loading: false,
        cards: [],
        primary: null,
        active: null,
        dialog: {
          message: null
        }
      }
    },

    mounted() {
      this.loadPaymentMethods()
    },

    computed: {
      isEmpty() {
        return !this.loading && this.cards.length == 0;
      },

      actionText() {
        return this.active == null ? "Add Payment Method" : "Make default"
      }
    },

    methods: {
      async loadPaymentMethods() {
        this.loading = true
        this.cards = null
        this.primary = null
        this.active = null

        const [data, err] = await billing.getPaymentMethods()
        this.loading = false

        if (err) {
          return
        }

        this.cards = data.cards
        this.primary = data.primary
      },

      async onAdded(token) {
        this.$refs.pdialog.show()
        const [data, err] = await billing.addMethod(token.id)

        if (err) {
          this.dialog.message = consts.REQUEST_ERR
          return
        }

        this.$store.dispatch('updateUser', {
          stripeCustomerId: 'notnull'
        })

        this.dialog.message = "Payment method has been added"
      },

      async action() {
        if (!this.active) {
          this.$refs.cardmodal.show()
          return
        }

        // make default
        this.$refs.pdialog.show();
        const [data, err] = await billing.setDefault({
          source: this.active
        });

        if (err) {
          this.dialog.message = consts.REQUEST_ERR
          return
        }

        this.dialog.message = "Payment method has been changed."
      },

      async deleteMethod() {
        this.$refs.pdialog.show();
        const [data, err] = await billing.deleteMethod(this.active)

        if (err) {
          this.dialog.message = consts.REQUEST_ERR
          return
        }

        this.dialog.message = "Payment method has been deleted."
      },

      onSelected(cardId) {
        this.active = cardId
      },

      onPdialogClose() {
        this.dialog.message = null
        this.loadPaymentMethods()
      }
    }
  }
</script>

<style>
  .payment-methods-block {
    min-height: 135px;
  }

  .empty-methods {
    text-align: center;
  }

  .ta-r {
    text-align: right;
  }
</style>