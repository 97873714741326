<template>
  <div>
    <div class="row mt-3">
      <div class="col-md-8 offset-md-4">
        <div class="form-group mt-3">
          <input
            type="text"
            v-model="search"
            class="form-control"
            placeholder="Search for ..."
            id="feed-search"
          />
        </div>
      </div>
    </div>
    <!-- row -->
    <div class="row">
      <div class="col text-right">
        <div v-if="search">
          <a @click="emptySearch">
            <strong>
              <i class="fa fa-window-close" aria-hidden="true"></i>
              &nbsp;Filtered by:
            </strong>
            {{search}}
          </a>
        </div>
        <div v-else>&nbsp;</div>
      </div>
    </div>
    <div class="mb-4"><h2>Don't see the feed you want? Let us know!</h2></div>
    <div class="row mb-3 cover-feed__filters-row">
      <div class="col d-flex align-items-baseline">
        <span @click="toggleFilters()">
          <i class="fa fa-filter" aria-hidden="true"></i> Filters
        </span>
        <span
          class="d-flex align-items-baseline"
          @click="resetFilters()"
          v-if="checkedCategories && checkedCategories.length"
        >
          <i class="fa fa-window-close" aria-hidden="true"></i>
        </span>
      </div>
    </div>
    <div class="row mb-3 cover-feed__filters-row">
      <div class="col d-flex justify-content-between">
        <span class="suscribed-legend">
          <a @click="toggleShowOnlySuscribed()">
            <i v-if="!showOnlySubscribed" class="far fa-square icon-suscribed-legend"></i>
            <i v-else class="far fa-check-square" aria-hidden="true"></i>
            Already suscribed
          </a>
        </span>
        <span class="cover-feed__sort-by" @click="sort()">
          Sort by Title
          <i
            v-if="this.currentSortDir=='asc'"
            class="fas fa-sort-alpha-up"
            aria-hidden="true"
          ></i>
          <i v-else class="fas fa-sort-alpha-down" aria-hidden="true"></i>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <!-- filters -->
        <catalog-cover-feed-table-filters
          :the_feeds="feeds"
          @filterChange="onFilterChange"
          ref="filters"
        ></catalog-cover-feed-table-filters>
      </div>
      <div class="mt-4 col-lg-9">
        <div class="row row-cols-3" v-if="data && data.length">
          <cover-feed-item
            v-for="(feed) in data"
            :feed="feed"
            :base_url="'/feed/details/'"
            v-bind:key="feed.id"
          ></cover-feed-item>
        </div>
        <div class="row mt-4" v-else>
          <div class="col text-center">
            <h1><i class="fa fa-frown-o" aria-hidden="true"></i> Sorry, no results found</h1>
            <br />
            <h2>Please try with other search criteria</h2>
          </div>
        </div>
        <table-pagination :data="sortedFeeds" @pageChange="onPageChange" />
      </div>
    </div>
    <!-- container -->

    <feed-detail-container ref="feedDetail" @reload="reload" />
  </div>
</template>

<script>
import feeds from "../../api/feeds.js";

import TablePagination from "../ui/table/pagination.vue";
import FeedDetailContainer from "./feeds/detail-modal.vue";
import CoverFeedItem from "./cover-feed-item.vue";
import CatalogCoverFeedTableFilters from "./catalog-cover-feed-table-filters.vue";
import { toggleFiltersMenu } from "../../utils/utils";

export default {
  name: "cover-feeds-table",
  props: ["feeds"],

  components: {
    TablePagination,
    FeedDetailContainer,
    CoverFeedItem,
    CatalogCoverFeedTableFilters
  },

  data() {
    return {
      feed: null,
      data: [],
      currentSort: "type"
    };
  },

  watch: {
    $route(to, from) {
      this.handleModal(to, from);
    }
  },

  mounted() {
    this.handleModal(this.$router.currentRoute, null);
    this.getFilters();
  },

  computed: {
    feedsToShow() {
      return this.data;
    },
    checkedCategories() {
      return this.$store.state.catalog.checkedCategories;
    },
    setOfFeedIdToRemove() {
      return this.$store.state.catalog.setOfFeedIdToRemove;
    },
    feedId() {
      const { feed } = this;
      return feed ? feed.narrativaID : null;
    },
    sortedFeeds: function() {
      if (this.feeds != null) {
        var searched = this.feeds.filter(item => {
          var items =
            item.type.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
            item.subtype.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
          return items && !this.setOfFeedIdToRemove.has(item.id);
        });

        if (this.showOnlySubscribed) {
          searched = searched.filter(item => {
            return item.is_user_suscribed == true;
          });
        }

        return searched.sort((a, b) => {
          let modifier = 1;
          if (this.currentSortDir === "desc") modifier = -1;
          if (a["title"].toLowerCase() < b["title"].toLowerCase())
            return -1 * modifier;
          if (a["title"].toLowerCase() > b["title"].toLowerCase())
            return 1 * modifier;
          return 0;
        });
      }
    },
    search: {
      get() {
        return this.$store.state.catalog.search;
      },
      set(value) {
        this.$store.dispatch("updateCatalogSearch", value);
      }
    },
    showOnlySubscribed: {
      get() {
        return this.$store.state.catalog.showOnlySubscribed;
      },
      set(value) {
        this.$store.dispatch("updateShowOnlySubscribed", value);
      }
    },
    currentSortDir() {
      return this.$store.state.catalog.currentSortDir;
    }
  },

  methods: {
    toggleFilters () {
      toggleFiltersMenu();
    },
    emptySearch() {
      this.$store.dispatch("updateCatalogSearch", "");
    },
    handleModal(to, from) {
      const path = to.path;
      if (path.indexOf("/feed/") != -1) {
        const { feedId } = to.params;
        this.$refs.feedDetail.show(feedId);
      }
    },

    reload() {
      this.$emit("reload");
    },

    showDetails(feedId) {
      this.$refs.feedDetail.show(feedId);
    },

    onPageChange(dataset) {
      this.data = dataset;
    },

    sort: function(s) {
      this.$store.dispatch(
        "updateCatalogCurrentSortDir",
        this.$store.state.catalog.currentSortDir === "asc" ? "desc" : "asc"
      );
    },
    toggleShowOnlySuscribed() {
      this.showOnlySubscribed = !this.showOnlySubscribed;
    },
    onFilterChange(theSetOfIdFeedsToRemove) {
      this.$store.dispatch(
        "updateCatalogFeedIdToRemove",
        theSetOfIdFeedsToRemove
      );
    },

    resetFilters() {
      this.$refs.filters.resetFilters();
    },

    async getFilters() {
      this.feeds = null;
      this.loading = true;
      let response;
      response = await feeds.getFeedsFilters();

      const [data, err] = response;
      this.loading = false;

      if (!err) {
        this.filters = data;
      }
    }
  }
};
</script>

<style>
.cover-feed__filters-row + .cover-feed__filters-row {
  margin-bottom: 0 !important;
}
.cover-feed__filters-row span {
  margin-right: .5rem;
}

.cover-feed__sort-by {
  cursor: pointer;
}
.suscribed-legend {
  color: #157ef3;
  font-size: 1.2em;
  text-align: right;
}

.icon-suscribed-legend {
  color: #157ef3;
}

.icon-suscribed {
  color: #157ef3;
  position: absolute;
  font-size: 1.9em;
  text-align: right;
  right: 50px;
  top: 6px;
}

#feed-search {
  border-color: #ccc;
}
</style>