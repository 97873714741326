<template>
  <div class="forgot-password fh d-flex align-items-center align-content-center">
    <div class="forgot-password__inner">
      <div class="row forgot-password__logo-wrap">
        <div class="col text-center">
          <router-link to="/user/login">
            <img src="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png" alt="logo" data-src="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png" data-src-retina="/assets/
            img/logo.png" width="250"/>
          </router-link>
        </div>
      </div>
      <template v-if="!processed">
        <div class="row">
          <div class="col">
            <h2 class="forgot-password__title">Reset password</h2>
            <p class="forgot-password__copy">If you forgot your password, write your email below. We will send the reset instructions to you.</p>
            <div class="forgot-password__error">
              <alert v-bind:message="error" type="danger"></alert>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <form id="form-login" class="forgot-password__form" role="form" @submit="submit">
              <div class="forgot-password__fields">
                <div class="form-group form-group-default">
                  <label>Email</label>
                  <div class="controls">
                    <input type="text" name="username" class="form-control" v-model="data.email" required spellcheck="false" @blur="validateEmailOnBlur" />
                  </div>
                </div>
                <span class="cdp__input-error" v-if="showEmailErrorMessage">{{emailErrorMessage}}</span>
              </div>
              <div class="row m-t-15 mb-3 d-flex justify-content-center">
                <div class="forgot-password__btn-col col-lg-5">
                  <button type="submit" class="forgot-password__btn btn btn-primary btn-cons btn-lg btn-block" :disabled="disabledButton">
                    <span>Reset my password</span>
                    <spinner v-bind:loading="loading" klass="action-spinner"></spinner>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <router-link to="/user/login" class="forgot-password__return">Sign in</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </template>
      <template v-else>
        <email-message v-if="processed" v-bind:email="data.email"
          title="You are almost there!"
          intent=", follow the instructions and reset your password"
          showbtn></email-message>
      </template>
    </div>
  </div>
</template>

<script>
  import EmailMessage from './onboarding/email-message.vue'
  import Alert from './ui/alert.vue'
  import Spinner from './ui/spinner.vue'

  import consts from './consts'
  import users from '../api/users'
  import { validateEmail } from "../utils/validation";

  const RECAPTCHA_TOKEN = '6Leh6H4bAAAAAGYT-I6bmz7oytbeC8xnd2m4_oJU'

  export default {
    name: 'forgot',
    components: {
      Alert,
      Spinner,
      EmailMessage
    },

    data() {
      return {
        data: { email: "", token: "" },
        error: null,
        processed: false,
        loading: false,
        showEmailErrorMessage: false,
        emailErrorMessage: "Please enter a valid email",
      }
    },
    mounted(){
      let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_TOKEN}`)
        document.head.appendChild(recaptchaScript)
    },
    computed: {
      disabledButton () {
        return !validateEmail(this.data.email)
      }
    },
    methods: {
      validateEmailOnBlur () {
        this.showEmailErrorMessage = !validateEmail(this.data.email);
      },
      recaptchaToken() {
        return new Promise((resolve) => {
          grecaptcha.ready(async () => {
            const token = await grecaptcha.execute(RECAPTCHA_TOKEN);
            resolve(token);
          });
        });
      },
      async submit(e) {
        e.preventDefault()

        this.loading = true
        const token = await this.recaptchaToken();
        this.data.token = token
        const [data, err] = await users.forgot(this.data)
        this.loading = false

        if (err) {
          this.heading = consts.REQUEST_ERR
          return
        }

        const {status, message} = data

        if (status == 'failure') {
          this.error = message
          return
        }

        this.processed = true
      }
    }
  }
</script>
<style scoped>

.forgot-password {
  color: #000;
}

.forgot-password a {
  color: #000;
  text-decoration: underline;
  font-weight: 800;
}

.forgot-password__inner {
  height: 100%;
  margin: 0 auto;
  max-width: 460px;
  width: 90%;
}

.forgot-password__logo-wrap {
  margin-bottom: 5rem;
}

.forgot-password__title {
  font-size: 28px !important;
  font-weight: 800;
  margin-bottom: 1rem;
}

.forgot-password__copy {
  font-size: 16px;
  margin-bottom: 2rem;
}

.forgot-password__error {
  margin-bottom: 1rem;
}

.forgot-password__btn {
  font-size: 16px;
  font-weight: 800;
}

.forgot-password__btn:hover {
  background-color: #000;
  color: #fff;
}

.forgot-password__return {
  font-size: 14px !important;
}

.forgot-password__fields {
  margin-bottom: 1.8rem;
}
.recaptcha-terms{
  font-size: .8em;
  margin-bottom: 2em;
}

@media (min-width: 992px) {

  .forgot-password__btn-col {
    min-width: 220px !important;
    max-width: 220px !important;
  }
}

@media (min-width: 1360px) {
   .forgot-password__inner {
      width: 75%;
    }
}

</style>