import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class FeedsContent extends Endpoint {
  constructor() {
    super('/content');
    bindAll(this, ['getFeedContentDemoById', 'getFeedContentLatestNumContentByToken', 'getFeedContentLatestNumContentByTokenNoValidate']);
  }


  getFeedContentDemoById(feedId) {
    return this.get(`/${feedId}/demo`);
  }

  getFeedContentDemoById(feedId, from, to) {
    if ((typeof (from) == "undefined") || (typeof (from) == "undefined")) {
      return this.get(`/${feedId}/demo`);
    }
    return this.get(`/${feedId}/demo?from_latest_num=${from}&to_latest_num=${to}`);

  }

  getFeedContentLatestNumContentByToken(feedId, token, latest_num) {
    return this.get(`/${token}/${feedId}?latest_num=${latest_num}`);
  }

  getFeedContentLatestNumContentByTokenNoValidate(feedId, token, latest_num) {
    return this.get(`/${token}/${feedId}?latest_num=${latest_num}&validated=0`);
  }

  getFeedContentContentByTokenIgnoreValidation(feedId, token, from, to) {
    if ((typeof (from) == "undefined") || (typeof (to) == "undefined")) {
      from = 20;
      to = 0;
    }

    return this.get(`/${token}/${feedId}?from_latest_num=${from}&to_latest_num=${to}&validated=0`);
  }


  setPostAsValidated(docId) {
    return this.put(`/doc/${docId}/validated`);
  }

  setPostAsNotValidated(docId) {
    return this.delete(`/doc/${docId}/validated`);
  }

  getPost(docId) {
    return this.get(`/doc/${docId}`);
  }

  deletePost(docId) {
    return this.delete(`/doc/${docId}`);
  }

  updatePost(docId, post) {
    return this.put(`/doc/${docId}`, post);
  }

  updatePostById(docId) {
    return this.put(`/doc/${docId}`, post);
  }

  addNewEmptyDoc(userCode, domain, language) {
    return this.post(`/users/${userCode}/domains/${domain}/languages/${language}/empty_doc`, null);
  }

}


export default new FeedsContent()