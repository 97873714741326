<template>
  <div class='modal fade' id='subscription-modal' role='dialog'>
    <div class='modal-dialog slide-up modal-m'>
      <div class='modal-content'>
        <div class='modal-header clearfix text-left'>
          <button type='button' class='close' data-dismiss='modal' aria-hidden='true'>×</button>
          <h5 style='font-size: 20px;' class='semi-bold'>{{ modalTitle }}</h5>
        </div>
        <div class='modal-body'>
          <div class='row cc-row'>
            <div class='col-12'>
              <!-- <stripe-card @onComplete='onComplete' ref='card' v-show="!loading" /> -->
              <spinner :loading="loading" klass="centered-spinner" />
              <h3 v-if="message">{{ message }}</h3>
            </div>
          </div>
        </div>
        <div class='modal-footer'>
          <div v-show="!loading">
            <button type='button' class='btn btn-default' data-dismiss='modal'>Cancel</button>
            <button type='button' class='btn btn-success' :disabled='disabled'
                    @click='tokenize' v-if="!step">Add Method</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /*import StripeCard from './card.vue'*/
  import Spinner from '../../ui/spinner.vue'
  import modals from '../../ui/modals.js'

  import feeds from '../../../api/feeds.js'
  import consts from '../../consts.js'

  export default {
    components: {
      /*StripeCard,*/
      Spinner
    },

    props: ['feedId', 'title'],

    data() {
      return {
        disabled: true,
        loading: false,
        message: null,

        step: 0
      }
    },

    computed: {
      modalTitle() {
        return this.title || `Subscribe to ${this.feedId}`
      }
    },

    methods: {
      onComplete(complete) {
        this.disabled = !complete
      },

      async tokenize() {
        this.loading = true

        const {card} = this.$refs
        const {error, token} = await card.getToken()

        this.loading = false

        if (error) {
          this.message = error
          return
        }

        this.hide()
        this.$emit('added', token)
      },

      show() {
        $('#subscription-modal').modal(modals.show)
        this.$refs.card.refresh()
      },

      hide() {
        $('#subscription-modal').modal('hide')
      }
    }
  }
</script>

<style>
  .cc-row {
    min-height: 125px;

    p {
      padding: 10px 15px;
    }
  }
</style>