<template>
  <div>
    <div class="fade" :class="{'hide': showTerms, 'show': !showTerms}">
      <feed-detail @subscribe="onSubscribe" @unsubscribe="unsubscribe" ref="fdetail"
                   @showTerms="showTermsModal"/>
    </div>

    <subscription-modal ref='addCard' @added="cardAdded($event)" :feedId="feedId" />
    <progress-dialog ref='pdialog' :message="pmessage" @onclose="onClose" />
    <setup-billing ref='setupBillingModal' :fields="billing.fields" :feedId="feedId" />

    <terms title="Subscriptions Terms and condition" id="subscription-tos"
           @onClose="onTermsClose" ref="subscriptionsTerms"/>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { isBillingSetuped } from './validation.js'

  import feeds from '../../../api/feeds.js'
  import FeedDetail from './feed-detail.vue'
  import SubscriptionModal from '../card-modal/modal.vue'
  import ProgressDialog from '../../ui/progress-dialog.vue'
  import SetupBilling from '../../ui/setup-billing-modal.vue'
  import Terms from '../../terms.vue'

  const components = {
    SubscriptionModal,
    ProgressDialog,
    SetupBilling,
    FeedDetail,
    Terms
  }

  export default {
    components,
    data() {
      return {
        pmessage: null,
        feedId: null,
        billing: {
          fields: []
        },

        showTerms: false
      }
    },

    computed: mapState({
     user: state => state.session.user
    }),

    methods: {
      show(feedId) {
        this.feedId = feedId
        this.$refs.fdetail.show(feedId)
      },

      dismiss() {
        this.$refs.fdetail.dismiss()
      },

      onSubscribe() {
        // const [setuped, fields] = isBillingSetuped()
        // if (!setuped) {
        //   this.billing.fields = fields
        //   this.$refs.setupBillingModal.show()
        //   return
        // }

        // const {stripeCustomerId} = this.user
        // if (stripeCustomerId == null) {
        //   this.$refs.addCard.show()
        //   return
        // }

        this.subscribe(null)
      },

      onClose() {
        this.pmessage = null
        this.$emit('reload');

        const {currentRoute} = this.$router
        if (currentRoute.path.indexOf('/feed/') != -1) {
          this.$router.push('/dashboard')
        }
      },

      cardAdded(token) {
        this.subscribe(token.id);
      },

      async subscribe(token) {
        this.$refs.pdialog.show()
        var temp_result
        if (!token){
          temp_result = await feeds.subscribeFreeFeed(this.feedId)
        }else{
          temp_result = await feeds.subscribe(this.feedId, token)
        }
        const [data, error] = temp_result

        if (!error) {
          this.pmessage = data.message
        } else {
          this.pmessage = consts.REQUEST_ERR
        }

        const {status} = data

        if (status == "success") {
          if (token != null) {
            this.$store.dispatch('updateUser', {
              stripeCustomerId: 'notnull'
            })
          }
        }
      },

      async unsubscribe() {
        this.$refs.pdialog.show()
        const [data, error] = await feeds.unsubscribe(this.feedId)

        if (!error) {
          this.pmessage = data.message
        } else {
          this.pmessage = consts.REQUEST_ERR
        }
      },

      /* subscriptions terms */
      onTermsClose() {
        setTimeout(() => {
          this.showTerms = false;
        }, 350)
      },

      showTermsModal() {
        this.$refs.subscriptionsTerms.show();
        this.showTerms = true;
      }
    }
  }
</script>