import users from '../api/users.js'

export default {
  logInUser({ commit }, data) {
    commit('LOGGED_IN', data)
  },

  logOutUser({ commit }) {
    commit('LOGGED_OUT')
  },

  updateForm({ commit }, key, val) {
    commit('UPDATE_FORM', key, val)
  },

  updateUser({ commit }, data) {
    commit('UPDATE_USER', data)
  },




  async updateUsageReport({ commit }, userId) {
    let response;
    response = await users.getUsageReport(userId);
    const [data, err] = response;

    if (!err) {
      commit('UPDATE_USAGE_REPORT', data)
    }
  },

  async updateUserPlanInfo({ commit }, userId) {
    let response;
    response = await users.getPlans(userId);
    const [data, err] = response;

    if (!err) {
      let the_plan;
      the_plan = data.filter(plan => plan.is_current_user_suscribed === true)[0];
      if (the_plan === undefined) {
        commit('UPDATE_USER_PLAN_INFO', null)
      } else {
        commit('UPDATE_USER_PLAN_INFO', the_plan)
      }
    }

  },

  updateCatalogCurrentSortDir({ commit }, data) {
    commit('UPDATE_CATALOG_CURRENT_SORT_DIR', data)
  },

  updateCatalogFeedIdToRemove({ commit }, data) {
    commit('UPDATE_CATALOG_FEED_ID_TO_REMOVE', data)
  },
  updateCatalogSearch({ commit }, data) {
    commit('UPDATE_CATALOG_SEARCH', data)
  },
  updateCatalogCheckedCategories({ commit }, data) {
    commit('UPDATE_CATALOG_CHECKEDCATEGORIES', data)
  },
  updateShowOnlySubscribed({ commit }, data) {
    commit('UPDATE_CATALOG_SHOWONLYSUBSCRIBED', data)
  },
}