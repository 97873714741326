<template>
  <div class="empty-methods">
    <alert type="danger"
           message="you don't have any payment methods, click on the button below to add a payment method." />
  </div>
</template>

<script>
  import Alert from '../../ui/alert.vue'

  export default {
    components: {
      Alert
    }
  }
</script>