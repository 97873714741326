<template>
  <div class="modal fade" id="subscription-detail" role="dialog">
    <div class="modal-dialog slide-up modal-subscriptions-detail">
      <div class="modal-content" v-if="subscription">
        <div class="modal-header clearfix text-left">
          <button type="button" class="close" @click='close' aria-hidden="true">×</button>
          <h5 style="font-size: 20px;" class="semi-bold">{{ subscription.narrativeId }} Invoices</h5>
        </div>
        <div class="modal-body">
          <invoices :subscription="subscription" />
        </div>
        <div class="modal-footer mt-2">
          <button type="button" class="btn btn-default" @click='close'>Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import modals from '../../ui/modals.js'
  import Invoices from './invoices/invoices-table.vue'

  export default {
    props: ['subscription'],
    components: {
      Invoices
    },
    methods: {
      close() {
        $('#subscription-detail').modal('hide')
      },

      show() {
        $('#subscription-detail').modal(modals.show)
      }
    }
  }
</script>

<style>
  .modal-subscriptions-detail {
    max-width: 660px !important;
  }
</style>