<template>
  <div class="login fh d-flex align-items-center align-content-center">
    <div class="login__inner">
      <div class="row login__logo-wrap">
        <div class="col text-center">
          <img
            src="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png"
            alt="logo"
            data-src="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png"
            data-src-retina="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png"
            width="250px"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h1 class="login__title"><strong>Sign In</strong></h1>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <form id="form-login" class="pt-0" role="form" @submit="login">
            <div>
              <div class="form-group form-group-default">
                <label>Email</label>
                <div class="controls">
                  <input
                    type="text"
                    name="email"
                    class="form-control"
                    v-model="credentials.email"
                    required
                    spellcheck="false"
                    @blur="validateEmailOnBlur"
                  />
                </div>
              </div>
              <span class="cdp__input-error" v-if="showEmailErrorMessage">{{emailErrorMessage}}</span>
            </div>
            <div class="form-group form-group-default login__password-field">
              <label>Password</label>
              <div class="controls">
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  v-model="credentials.password"
                  required
                  spellcheck="false"
                />
              </div>
            </div>
            <div class="row login__checkboxes">
              <div class="col-6 checkboxes">
                <input type="checkbox" name="remember" id="remember" v-model="credentials.remember"/>
                <label for="remember"><span>Remember me</span></label>
              </div>
              <div class="col-6 text-right" v-if="ENABLED">
                <router-link to="/user/forgot-password" class="forgot-link"><strong>Forgot password</strong></router-link>
              </div>
            </div>
            <div v-if="this.error" class="row mt-0 mb-0">
              <div class="col-12">
                <p class="text-center" style="font-weight:bold">{{generalErrorMessage}}</p>
              </div>
            </div>
            <div class="row login__btn-wrap d-flex justify-content-center">
              <div class="col-lg-5 login__btn-col">
                <button
                  type="submit"
                  class="login__btn btn btn-primary btn-cons btn-lg btn-block"
                  :disabled="loading"
                >
                  <span class="login__btn-text">{{ loginText }}</span>
                  <spinner v-bind:loading="loading" klass="action-spinner"></spinner>
                </button>
              </div>
            </div>
<!--            <div class="row login__need-credentials text-center">-->
<!--              <div class="col-12">-->
<!--                <span>Need credentials?</span>-->
<!--                <a v-if="!ENABLED" @click="showSubscriptionWarningModal">-->
<!--                  Join our Private Beta-->
<!--                </a>-->
<!--                <a v-if="ENABLED" href="#signup-popup" data-toggle="modal">-->
<!--                  <strong>Sign up</strong>-->
<!--                </a>-->
<!--              </div>-->
<!--            </div>-->
            <div class="row d-md-block d-lg-none mt-4">
              <div class="col-12">
                <a href="/catalog" class="login__access-catalog btn btn-success btn-block btn-lg">
                  <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                  <span>Access to Catalog</span>
                </a>
              </div>
            </div>
          </form>
        </div>
        <subscription-beta-modal v-if="!ENABLED" ref="showSubscriptionWarningModal"></subscription-beta-modal>
        <signup-popup v-if="ENABLED" title="Sign up" id="signup-popup" />
      </div>
    </div>
  </div>
</template>


<script>
import ENABLED from '../toggle_feature';
import Alert from "./ui/alert.vue";
import Spinner from "./ui/spinner.vue";
import RetryVerification from "./login/retry-verification.vue";
import SubscriptionBetaModal from "./subscription-beta-modal.vue";
import SignupPopup from './signup-popup.vue';
import { getErrorMessage } from "../api/utils.js";
import users from "../api/users";
import { validateEmail } from "../utils/validation";

const USER_INACTIVE = "user_inactive";

export default {
  name: "login",

  components: {
    RetryVerification,
    Spinner,
    Alert,
    SubscriptionBetaModal,
    SignupPopup
  },

  data() {
    return {
      ENABLEDENABLED: ENABLED,
      loading: false,
      error: false,

      credentials: {
        email: "",
        password: "",
        remember: false
      },

      showResend: false,
      showEmailErrorMessage: false,
      emailErrorMessage: "Please enter a valid email.",
      generalErrorMessage: ""
    };
  },

  computed: {
    loginText() {
      return this.loading ? "Please wait..." : "Sign in";
    }
  },

  methods: {
    validateEmailOnBlur () {
      this.showEmailErrorMessage = !validateEmail(this.credentials.email);
    },
    showSubscriptionWarningModal() {
      this.$refs.showSubscriptionWarningModal.show();
    },
    async login(e) {
      e.preventDefault();

      if (this.credentials.email || this.credentials.password) {
        this.error = true;
        this.generalErrorMessage = "Enter your credentials";
      }

      this.error = null;
      this.loading = true;

      const { email, password, remember } = this.credentials;
      const body = { email, password, remember };

      const [data, err] = await users.login(body);

      this.loading = false;

      if (err) {
        this.error = true;
        this.generalErrorMessage = "Incorrect Email/Password";
        return;
      }

      const { status, message } = data;

      switch (status) {
        case USER_INACTIVE:
          this.$refs.verify.setEmail(email);
          this.showResend = true;
          return;
      }

      if (status == "failure") {
        this.generalErrorMessage = message;
        return;
      }

      this.$store.dispatch("logInUser", data);
      this.$router.push("/manage-plans");
    }
  }
};
</script>

<style scoped>
.login {
  color: #000;
}

.login__logo-wrap {
  margin-bottom: 5rem;
}
.login a {
  color: #000;
  text-decoration: underline;
}
.login__inner {
  height: 100%;
  margin: 0 auto;
  max-width: 460px;
  width: 90%;
}

.login__btn {
  font-size: 16px;
  font-weight: 800;
}

.login__title {
  font-size: 28px !important;
  font-weight: 800 !important;
}

.login__password-field {
  margin-bottom: 1rem;
}

.login__checkboxes {
  margin: 0 .8rem .8rem;
}

.login__checkboxes > div {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.login__need-credentials {
  font-size: 14px;
  margin-bottom: .8rem;
}

.login__btn-wrap {
  margin-bottom: 1rem;
}

.login__access-catalog {
  font-size: 16px;
  font-weight: 800;
  text-decoration: none !important;
}

.login__access-catalog i {
  margin-right: 5px;
}

@media (min-width: 992px) {
  .login__checkboxes {
    margin: 0 1rem 1rem;
  }

  .login__btn-col {
    min-width: 150px !important;
    max-width: 150px !important;
  }

  .login__btn:disabled .login__btn-text {
    margin-left: -21px;
  }
}

@media (min-width: 1200px) {
  .login__password-field {
    margin-bottom: 2rem;
  }

  .login__checkboxes {
    margin: 0 1.5rem 1.8rem;
  }

  .login .form-group-default {
    padding-left: 24px;
    padding-right: 24px;
  }

  .login__need-credentials {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 1360px) {
   .login__inner {
      width: 75%;
    }
}


.login button:hover {
  background-color: #000;
  color: #fff;
}

.login .forgot-link,
.login .checkboxes span{
  font-size: 14px !important;
  color: #000;
}
.login .checkboxes label {
  display: inline-block;
  padding-right: 10px;
  white-space: nowrap;
}
.login .checkboxes input {
  vertical-align: middle;
}
.login .checkboxes label span {
  vertical-align: middle;
}
</style>