<template>
  <div class="reset-password fh d-flex align-items-center align-content-center">
    <div class="reset-password__inner">
      <div class="reset-password__logo-wrap text-center">
        <router-link to="/user/login" class="reset-password__logo text-center">
          <img src="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png" alt="logo" data-src="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png" data-src-retina="/assets/
          img/logo.png" width="250px"/>
        </router-link>
      </div>
      <div v-if="!processed && !expired">
        <div class="row">
          <div class="col">
            <h2 class="reset-password__title">Reset password</h2>
            <p class="reset-password__copy">You can now write a new password below.</p>
            <form id="form-login" role="form" @submit="reset" autocomplete="nope">
              <div class="reset-password__fields">
                <div class="form-group form-group-default">
                  <label>New password</label>
                  <div class="controls">
                    <input :type="passwordFieldType" name="New password" class="form-control"
                          v-model="data.password" @input="onPasswordUpdate" required ref="passwordInput" />
                    <i v-show="data.password.length" @click="revealPassword" :class="revealPasswordCss" aria-hidden="true"></i>
                  </div>
                </div>
                <PasswordValidationMsg v-show="showPasswordError" :validations="passwordValidations"></PasswordValidationMsg>
              </div>
              <div class="d-flex justify-content-center">
                <button type="submit" class="reset-password__btn btn btn-primary btn-cons btn-lg" :disabled="resetDisabled">
                  <span>Reset password</span>
                  <spinner v-bind:loading="loading" klass="action-spinner"></spinner>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <onboard-message v-if="processed && !expired" v-bind:title="onSuccess.title"
                       v-bind:message="onSuccess.message"
                       v-bind:showbtn="true"></onboard-message>

      <div class="m-t-15" v-if="expired">
        <alert message="Token expired" type="danger"></alert>
        <p>Your reset password request has expired</p>
        <p>Request it again</p>
        <router-link to="/user/reset-password" class="btn btn-primary btn-cons btn-lg">
          Request reset password
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from './ui/spinner.vue'
  import Alert from './ui/alert.vue'
  import OnboardMessage from './onboarding/onboard-message.vue'
  import users from '../api/users'
  import { validate, validatePassword } from '../utils/validation.js'
  import PasswordValidationMsg from "./PasswordValidationMsg.vue"

  const resetMessage = "Reset your Password"
  const matchError = "The passwords don't match"
  const remoteError = "An error occur on server"

  const onSuccess = {
    title: "You have a new password!",
    message: "Your Narrativa account is back on track.",
  }

  export default {
    name: 'reset-password',
    components: {
      Alert,
      Spinner,
      OnboardMessage,
      PasswordValidationMsg
    },

    data() {
      return {
        data: {
          password: '',
          confPassword: '',
        },
        passwordFieldType: "password",
        passwordValidations: [],
        processed: false,
        loading: false,
        expired: false,
        error: null,
        validated: false,
        message: resetMessage,
        onSuccess,
        showPasswordError: false
      }
    },

    computed: {
      resetDisabled() {
        if (!this.passwordValidations.length > 0) {
          return true
        } else {
          return this.passwordValidations.some(item => item.valid === false)
        }
      },
      revealPasswordCss () {
        return {
          'reset-password__reveal': true, 
          'fa': true, 
          'fa-eye': this.passwordFieldType === 'password',
          'fa-eye-slash': this.passwordFieldType !== "password"
        }
      }
    },

    methods: {
      async reset(e) {
        e.preventDefault()
        const { password, confPassword } = this.data

        this.message = resetMessage

        const body = {
          password,
          confPassword,
          hash: this.$route.params.hash
        }

        this.loading = true
        const [data, err] = await users.resetPassword(body)
        this.loading = false

        if (err) {
          if (err.data.msg === "Reset token expired"){
            this.expired = true
          }
          return
        }

        if (data.status === 'success') {
          console.log("success")
          this.processed = true
        } else {
          this.message = data.message
        }
      },
      revealPassword () {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      },
      onPasswordUpdate() {
        this.passwordValidations = validatePassword(this.data.password);
        this.showPasswordError = this.passwordValidations.some(item => item.valid === false);
      }
    }
  }
</script>

<style scoped>
.reset-password {
  color: #000;
}

.reset-password a {
  color: #000;
  text-decoration: underline;
  font-weight: 800;
}

.reset-password__inner {
  height: 100%;
  margin: 0 auto;
  max-width: 460px;
  width: 90%;
}

.reset-password__logo-wrap {
  margin-bottom: 5rem;
}

.reset-password__title {
  font-size: 28px !important;
  font-weight: 800;
  margin-bottom: 1rem;
}

.reset-password__copy {
  font-size: 16px;
  margin-bottom: 2rem;
}

.reset-password__form-error {
  margin-bottom: .5rem;
}

.reset-password__btn {
  font-size: 16px;
  font-weight: 800;
}

.reset-password__btn:hover {
  background-color: #000;
  color: #fff;
}

.reset-password__fields{
  margin-bottom: 1.8rem;
}

.reset-password .controls {
  position: relative;
}

.reset-password__reveal {
  cursor: pointer;
  font-size: 15px;
  margin-right: 5px;
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 1360px) {
   .reset-password__inner {
      width: 75%;
    }
}
</style>