export default {
  ['LOGGED_IN'](state, data) {
    const { user } = data

    state.session.isLoggedIn = true
    state.session.user = user

    localStorage.setItem('token', data.token)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('refresh', data.refresh)
  },

  ['LOGGED_OUT'](state) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('refresh')
    localStorage.removeItem('unathorized_retry')

    state.session.isLoggedIn = false
  },

  ['UPDATE_FORM'](state, key, val) {
    state.session.user[key] = val
  },

  ['UPDATE_USER'](state, data) {
    const user = Object.assign({}, state.session.user, data)
    state.session.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },

  ['UPDATE_USAGE_REPORT'](state, data) {
    const userUsageReport = Object.assign({}, state.usageReport, data);
    state.userUsageReport = userUsageReport;
  },
  ['UPDATE_USER_PLAN_INFO'](state, data) {
    if (data) {
      const plan = Object.assign({}, state.plan, data);
      state.plan = plan;
    } else {
      state.plan = null;
    }

  },
  ['UPDATE_CATALOG_CURRENT_SORT_DIR'](state, data) {
    if (data) {
      state.catalog.currentSortDir = data;
    } else {
      state.catalog.currentSortDir = "asc";
    }
  },
  ['UPDATE_CATALOG_FEED_ID_TO_REMOVE'](state, data) {
    if (data) {
      state.catalog.setOfFeedIdToRemove = data;
    } else {
      state.catalog.setOfFeedIdToRemove = new Set();
    }
  },
  ['UPDATE_CATALOG_SEARCH'](state, data) {
    if (data) {
      state.catalog.search = data;
    } else {
      state.catalog.search = "";
    }
  },
  ['UPDATE_CATALOG_CHECKEDCATEGORIES'](state, data) {
    if (data) {
      state.catalog.checkedCategories = data;
    } else {
      state.catalog.checkedCategories = [];
    }
  },
  ['UPDATE_CATALOG_SHOWONLYSUBSCRIBED'](state, data) {
    if (data) {
      state.catalog.showOnlySubscribed = data;
    } else {
      state.catalog.showOnlySubscribed = false;
    }
  },
}