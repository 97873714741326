<template>
  <div class="modal fade" id="feed-detail" role="dialog">
    <div class="modal-dialog slide-up modal-lg">
      <div class="modal-content">
        <div class="modal-header clearfix text-left">
          <button type="button" class="close" @click='close' aria-hidden="true">×</button>
          <h5 v-if="feed && feed.data" style="font-size: 20px;" class="semi-bold">{{ feed.data.title }}</h5>
        </div>
        <div class="modal-body">
          <div class="feed-detail">
            <div class="row" v-if="feed">
              <narratives-table :narratives="feed.narratives" :subscribed="feed.subscribed" @onDownload="download"/>
              <feed-info :info="feed"/>
            </div>
            <div class="pull-right tos" v-if="feed && feed.data && !feed.subscribed && feed.data.active">
              <div class="checkbox check-success">
                <input type="checkbox" id="checkbox-agree" v-model="confirmation">
                <label for="checkbox-agree">
                  I agree to terms and conditions,
                  <a href="#" @click="$emit('showTerms')">click here</a>
                </label>
              </div>
            </div>
            
          </div>
        </div>
        <div class="modal-footer" v-if="feed">
          <button type="button" class="btn btn-default" @click='close'>Close</button>
        </div>
      </div>
    </div>
    <spinner :loading="loading" klass="centered-spinner"/>
  </div>
</template>

<script>
  import NarrativesTable from './narratives-table.vue'
  import FeedInfo from './feed-info.vue'
  import Terms from '../subscriptions/tos.vue'
  import modals from '../../ui/modals.js'
  import Spinner from '../../ui/spinner.vue'
  import narratives from '../../../api/narratives.js'
  import feeds from '../../../api/feeds.js'

  export default {
    components: {
      NarrativesTable,
      FeedInfo,
      Spinner,
      Terms
    },

    data(){
      return {
        loading: true,
        feed: null,

        confirmation: false,
      }
    },

    mounted: function () {
      this.loading = false
    },

    computed: {
      disabled() {
        const feed = this.feed
        return feed ? (!this.confirmation && !this.feed.subscribed) : false
      },

      actioncls() {
        const feed = this.feed
        return feed ? {
          'btn-danger': feed.subscribed,
          'btn-success': !feed.subscribed,
        } : {}
      },
    },

    methods: {
      async getInfo(feedId) {
        this.loading = true

        const [data, err] = await feeds.getById(feedId);
        // const [data, err] = await narratives.getFeed(feedId)
        this.feed = data
        this.loading = false
      },

      action() {
        this.dismiss()
        const event = this.feed.subscribed ? 'unsubscribe' : 'subscribe'
        this.$emit(event)
        this.confirmation = false
      },

      dismiss() {
        $('#feed-detail').modal('hide')
      },

      close() {
        this.feed = null
        this.confirmation = false
        this.dismiss()

        const {currentRoute} = this.$router
        if (currentRoute.path.indexOf('/feed/') != -1) {
          this.$router.push('/dashboard')
        }
      },

      show(feedId) {
        $('#feed-detail').modal(modals.show)
        setTimeout(() => {
          this.getInfo(feedId)
        }, 200);
      },

      download(selected) {
        const { feedUrl } = this.feed;
        const query = selected.length > 0 ? selected : null
        let url = `${API_HOST}/api/content/${feedUrl}/download`
        if (query) {
          url += '?ids=' + selected.join(',');
        }
        window.open(url, 'Download Feed');
      }
    }
  }
</script>

<style>
  .tos {
    margin-right: 10px;
  }

  .feed-detail {
    min-height: 425px;
  }
</style>