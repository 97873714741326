import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Feeds extends Endpoint {
    constructor() {
        super('');
        bindAll(this, ['getAll','getAllNoNIP', 'getAllPublic', 'getById', 'create', 'update', 'getUserFeeds', 'getAllPublicFeedsAndRegisteredForUser', 'getAllPublicFeedsForUser']);
    }

    getAll() {
        return this.get('/feeds');
    }

    getAllNoNIP() {
        return this.get('/feeds/no_nip');
    }

    getAllPublic() {
        return this.get('/feeds/public');
    }

    getAllPublicFeedsForUser(userId) {
        return this.get(`/users/${userId}/feeds/public`);
    }

    getAllPublicFeedsAndRegisteredForUser(userId) {
        return this.get(`/users/${userId}/feeds/publicandregistered`);
    }

    getById(feedId) {
        return this.get(`/feeds/${feedId}`);
    }

    getByIdForUser(feedId, userId) {
        return this.get(`/feeds/${feedId}/users/${userId}`);
    }

    create(feed) {
        return this.post('/feeds', feed);
    }

    update(feed) {
        return this.put('/feeds', feed);
    }

    subscribe(feedId, stripeToken) {
        return this.post('/feeds/subscribe', {
            feedId,
            stripeToken
        })
    }

    subscribeFreeFeed(feedId) {
        return this.post('/feeds/subscribe-free-feed', {
            feedId
        })
    }

    unsubscribe(feedId) {
        return this.post(`/feeds/unsubscribe/${feedId}`)
    }

    getUserFeeds(userId) {
        return this.get(`/feeds/users/${userId}`);
    }

    getFeedsFilters() {
        return this.get('/feeds/filter');
    }
}


export default new Feeds()