<template>
<div class="plans">
  <div class="alert alert-danger" role="alert" v-if="error">
    The purchase process could not be successful.
  </div>
  <div class="alert alert-warning" v-if="plan_ends_at">
      Current subscribed plan will ends on {{plan_ends_at}}
  </div>
  <!--<div class="card-transparent">
    <div class="plans__intro">
      <p class="plans__available-text">These are the available plans, please choose one:</p>
      <div class="plans__periodicity-btns" v-if="!loading">
          <div class="btn-group btn-group-toggle">
          <label class="btn btn_primary" v-bind:class="{active: periodicity=='yearly' }">
            <input type="radio" value="yearly" v-model="periodicity"> Yearly
          </label>

          <label  class="btn btn_primary" v-bind:class="{active: periodicity=='monthly' }">
            <input type="radio" value="monthly" v-model="periodicity"> Monthly
          </label>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <div class="card-deck mb-3 text-center">
          <div
            v-for="(plan, plan_index) in plans" v-bind:key="plan.id"
            :class="planCssClass(plan)"
          >
            <template v-if="plan.name === 'Light'" >
              <div class="card-header">
                <h1 class="plan-name">{{ plan.name }}</h1>
                <i class="fa fa-award plan__award-icon" data-toggle="tooltip" data-placement="top" title="Most popular"></i>
              </div>
            </template>
            <template v-else >
              <div class="card-header">
                <h1 class="plan-name">{{ plan.name }}</h1>
              </div>
            </template>
            <div class="card-body d-flex flex-column justify-content-between">
              <div>
                <h2
                  class="plans__feeds mt-4 mb-4"
                  v-if="plan && plan.feeds_allowed && plan.feeds_allowed != -1"
                >
                  {{ plan.feeds_allowed }} feed<span v-if="plan.feeds_allowed>1">s</span>
                </h2>
                <h2 v-if="plan.price!=-1" class="card-title pricing-card-title">
                  <div v-if="periodicity=='yearly'">
                    <p class="plans__price">
                      <span class=""><strong>${{ plan.price_annually }}</strong></span><small class="text-muted">/{{plan.interval}}</small>
                    </p>
                    <p class="save_annually mb-0 mt-0">
                      <span class="plans__price-save">Save ${{ (plan.price*12 - plan.price_annually*12) }} annually</span>
                    </p>
                  </div>
                  <div v-if="periodicity=='monthly'">
                    <p class="plans__price">
                      <span class=""><strong>${{ plan.price }}</strong></span><small class="text-muted">/{{plan.interval}}</small>
                    </p>
                  </div>
                </h2>
                
                <p class="plans__description text-center mb-3">{{ planDescription(plan) }}</p>
                <p class="list-unstyled mt-3 mb-4" v-if="plan.is_current_user_suscribed">
                  <span v-if="plan.id!=4">
                    <span v-if="plan.annually_payment">Yearly</span><span v-if="!plan.annually_payment">Monthly</span> Renewal
                  </span>
                </p>
              </div>
              <div>
                <button
                  v-if="plan.is_current_user_suscribed === true && !isEnterprise"
                  v-on:click="showUnsubscribeDialog(plan.id)"
                  type="button"
                  class="btn btn-lg btn-block btn-outline-primary"
                >
                  {{unsubscribeButtonText}}
                </button>
                <template v-if="plan.stripe_price_id && user_plan === -1">
                  <button
                    v-if="!usedTrial"
                    type="button"
                    v-on:click="subscribeTrialPlanStripe(plan.id)"
                    class="btn btn-lg btn-block trial-btn"
                  >
                    {{trialButtonText}}
                  </button>
                  <a
                    v-on:click="suscribeToStripePlan(plan.id)"
                    class="btn btn-lg btn-block purchase-btn"
                  >
                      {{startButtonText}}{{plan.name}}
                    </a>
                </template>
                <template v-if="plan.stripe_price_id && !isEnterprise && user_plan >= 0 && plan_index > user_plan">
                  <button
                    type="button"
                    v-on:click="suscribeToStripePlan(plan.id)"
                    class="btn btn-lg btn-block btn-outline-primary trial-btn"
                  >
                    {{upgradeButtonText}}
                  </button>
                </template>
              </div>
            </div>
          </div>
          <div v-for="(plan) in plans" v-bind:key="plan.id+'dialog'">
            <confirm-dialog
              :a_id="'signUp_'+plan.id+'_dialog'"
              :ref="'signUp_'+plan.id+'_dialog'"
              :message="'You are going to <strong>sign up</strong> into <strong>' + plan.name.toUpperCase()+ '</sontr> plan<br><br>Are you sure?'"
              @confirm="suscribeToPlan(plan.id)"
            ></confirm-dialog>
            <confirm-dialog
              :a_id="'unsubscribe_'+plan.id+'_dialog'"
              :ref="'unsubscribe_'+plan.id+'_dialog'"
              :message="'You are going <strong>unsubscribe</strong> of <strong>' + plan.name.toUpperCase()+ '</sontr> plan<br><br>Are you sure?'"
              @confirm="unsubscribePlan()"
            ></confirm-dialog>
          </div>
          
        </div>
      </div>
      <spinner klass="centered-spinner" :loading="loading" />
    </div>-->
  </div>
</div>
</template>

<script>
import Spinner from "../ui/spinner.vue";
import plans from "../../api/plans.js";
import users from "../../api/users.js";
import { isAdminUser, isEnterprise, usedTrial } from "../../utils/user";
import ConfirmDialog from "../ui/confirm-dialog.vue";

export default {
  name: "dashboard",

  components: {
    Spinner,
    ConfirmDialog
  },

  mounted() {
    this.getPublicPlans();
  },

  data() {
    return {
      loading: false,
      plans: null,
      isAdminUser: isAdminUser(),
      isEnterprise: isEnterprise(),
      usedTrial: usedTrial(),
      plan_ends_at: null,
      user_plan: -1,
      error: false,
      periodicity: "yearly",
      unsubscribeButtonText: "Unsubscribe",
      upgradeButtonText: "Upgrade",
      trialButtonText: "Free 15-day trial",
      startButtonText: "Start "
    };
  },
  computed: {
    planCssClass () {
      return (plan) => ({
        "col-sm-4": true,
        "col-lg-3": true,
        "card": true,
        "mb-4": true,
        "plans__plan": true,
        "plans__light-plan": plan.name.toLowerCase() === "light",
        "plans__enterprise-plan": plan.name.toLowerCase() === "enterprise",
        "selected-plan": plan.is_current_user_suscribed === true
      });
    },
    planDescription () {
      return (plan) => {
        if (plan.name.toLowerCase() === "basic") {
          return "For teams just getting started with Narrativa.";
        } else if (plan.name.toLowerCase() === "light") {
          return "For those looking to cover multiple feeds.";
        } else if (plan.name.toLowerCase() === "pro") {
          return "For a broader range of feeds and subject areas.";
        } else if (plan.name.toLowerCase() === "enterprise") {
          return "Special packages for larger bundles and customised services.";
        }
      }
    }
  },

  methods: {
    showSignUpDialog(planId) {
      var dialog_ref = "signUp_" + planId + "_dialog";
      var dialog = this.$refs[dialog_ref];
      dialog[0].show();
    },

    showUnsubscribeDialog(planId) {
      var dialog_ref = "unsubscribe_" + planId + "_dialog";
      var dialog = this.$refs[dialog_ref];
      dialog[0].show();
    },
    async getPublicPlans() {
      this.plans = null;
      this.loading = true;

      const userId = JSON.parse(localStorage.getItem("user")).id;
      const response = await users.getPlans(userId);

      const [data, err] = response;
      this.loading = false;

      if (!err) {
        this.plans = data;
        for (const [key, item] of data.entries()){
          if (item.is_current_user_suscribed){
            if (item.plan_ends_at){
              this.plan_ends_at = item.plan_ends_at
            }
            this.user_plan = key;
            return
          }
        }
      }
    },

    async unsubscribePlan() {
      const userId = JSON.parse(localStorage.getItem("user")).id;
      this.user_plan = -1;

      this.plans = null;
      this.loading=true;
      const response = await users.unsubscribePlans(userId);
      await this.$store.dispatch("updateUsageReport", this.$store.getters.user.id);
      await this.$store.dispatch("updateUserPlanInfo", this.$store.getters.user.id);
      const [user_data, err] = await users.getUserInfo(userId);
      if (!err){
        this.$store.dispatch("updateUser", user_data);
        this.usedTrial = usedTrial();
      }
      await this.getPublicPlans();
    },

    async suscribeToPlan(planId) {
      this.error = false
      const userId = JSON.parse(localStorage.getItem("user")).id;
      this.plans = null;
      this.loading=true;
      const response = await users.subscribePlan(userId, planId, this.periodicity);
      await this.$store.dispatch("updateUsageReport", this.$store.getters.user.id);
      await this.$store.dispatch("updateUserPlanInfo", this.$store.getters.user.id);
      await this.getPublicPlans();
      this.loading=false;

    },
     async suscribeToStripePlan(planId) {
      const userId = JSON.parse(localStorage.getItem("user")).id;
      this.plans = null;
      this.loading=true;
      const response = await users.subscribePlanStripe(userId, planId, this.periodicity);
      if (response[0] && response[0].sessionId ){
        if(response[0].sessionId == -1){
          await this.$store.dispatch("updateUsageReport", this.$store.getters.user.id);
          await this.$store.dispatch("updateUserPlanInfo", this.$store.getters.user.id);

          await this.getPublicPlans();
          this.loading=false;
          return;
        }
        await this.$store.dispatch("updateUsageReport", this.$store.getters.user.id);
        await this.$store.dispatch("updateUserPlanInfo", this.$store.getters.user.id);
        const stripe = Stripe(STRIPE_KEY);
          stripe.redirectToCheckout({
            sessionId: response[0].sessionId
          })
        await this.getPublicPlans();
        const [user_data, err] = await users.getUserInfo(userId);
        if (!err){
          this.$store.dispatch("updateUser", user_data);
        }
        this.loading=false;
      }
      else{
        this.error = true
      }


    return;

    },
     async subscribeTrialPlanStripe(planId) {
      const userId = JSON.parse(localStorage.getItem("user")).id;
      this.plans = null;
      this.loading=true;
      const response = await users.subscribeTrialPlanStripe(userId, planId, this.periodicity);

      if (response[0] && response[0].sessionId ){
        if(response[0].sessionId == -1){
          await this.$store.dispatch("updateUsageReport", this.$store.getters.user.id);
          await this.$store.dispatch("updateUserPlanInfo", this.$store.getters.user.id);
          this.getPublicPlans();
          this.loading=false;
          // location.reload();
          return;
        }
        await this.$store.dispatch("updateUsageReport", this.$store.getters.user.id);
        await this.$store.dispatch("updateUserPlanInfo", this.$store.getters.user.id);
        const stripe = Stripe(STRIPE_KEY);
          stripe.redirectToCheckout({
            sessionId: response[0].sessionId
          })
        this.getPublicPlans();
        this.loading=false;
      }
      else{
        this.error = true
      }


    return;

    },
  }
};
</script>

<style scoped>
.plans__description {
  font-size: 14px !important;
}
.plans .card {
  border: 0 !important;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  padding-bottom: 15px;
}

.plan__award-icon {
  color: #157ef3 !important;
  position: absolute;
  right: 0;
  top: 11px;
}

.plans .card-body {
  padding: 0 !important;
}

.plans__available-text {
  margin: 1rem 0 2rem;
}
.plans__periodicity-btns {
  text-align: center;
  margin-bottom: 2rem;
}

.plans .card .card-header {
  padding: 12px 20px 7px 20px !important;
}
.plans .purchase-btn,
.plans .trial-btn {
  background-color: #157ef3 !important;
  border: 0 !important;
  color: #fff;
  padding: 10px 0 !important;
  text-align: center;
}
.plans .btn.active {
  background-color: #157ef3 !important;
  color: #fff !important;
}

.plans .purchase-btn:hover {
  color: #fff!important;
}

.plan-name {
  color: #157ef3 !important;
  font-size: 3rem;
  font-weight: 800 !important;
  word-wrap: normal !important;
}

@media (min-width: 992px) {
  .plans .plan-name {
    font-size: 1.5rem  !important;
  }

  .plans__plan {
    min-height: 250px;
  }

  .plans__intro {
    display: flex;
    justify-content: space-between;
  }
}

.plans .text-muted {
  color: #5b5b5b !important;
}

.plans .plans__price {
  font-size: 1.2rem;
  margin-bottom: .3rem;
}

.plans .plans__price-save {
  font-size: .8rem;
}

.plans__feeds {
  font-size: 1.1rem !important;
}

.plans__enterprise-plan .plans__description {
  margin-top: .5rem;
}

.feeds-view {
  min-height: 60vh;
}

table {
  border: 1px solid #ccc;
}



.card-header h1 {
  font-size: 1.6em !important;
  margin-bottom: 0 !important;
}

.card{
  border-color: #ccc;

}

.save_annually{
  font-size: .6em;
  color: #5b5b5b;

 }

.plans .plans__plan.selected-plan {
  background-color: #fff !important;
  border: 2px solid #157ef3 !important;
  color: #222 !important;
}
</style>