import Endpoint from './endpoint'
import bindAll from 'lodash.bindall'

class Categories extends Endpoint {
  constructor() {
    super('/category');
    bindAll(this, ['getPublicCategories','getCategories']);
  }

  getPublicCategories() {
    return this.get('/public/nofeeds');
  }

  getCategories() {
    return this.get('');
  }
}

export default new Categories();