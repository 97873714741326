
export function sleep_time(ms) {
   return new Promise(resolve => setTimeout(resolve, ms));
};


export function formatDate(dateString) {
   return new Date(dateString).toLocaleString();
};

export function toggleFiltersMenu () {
   const feedFiltersMenu = document.querySelector(".feed-filters");
   feedFiltersMenu.classList.toggle("open");
   if (feedFiltersMenu.classList.contains("open")) {
      document.getElementById("app").classList.add("no-scroll");
   } else {
      document.getElementById("app").classList.remove("no-scroll");
   }
}