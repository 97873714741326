<template>
  <div class="container">
    <div class="header-inner header-xl-height">
      <a
        href="#"
        class="btn-link toggle-sidebar hidden-lg-up pg pg-menu text-white"
        data-toggle="horizontal-menu"
      ></a>

      <div class>
        <div class="brand inline no-border hidden-xs-down">
          <router-link to="/catalog">
          <img
            src="/assets/img/NarrativaLogoBlancoOnlyLogo.png"
            alt="logo"
            data-src="/assets/img/NarrativaLogoBlancoOnlyLogo.png"
            data-src-retina="/assets/img/NarrativaLogoBlancoOnlyLogo.png"
            height="40"
          />
          </router-link>
        </div>
      </div>
      <!-- Logo -->

      <div id="log-info">
        <div v-if="!user" class="pull-right">
          <router-link to="/user/login">
            <i class="fa fa-sign-in" aria-hidden="true"></i> Login
          </router-link>

<!--          <a-->
<!--            v-if="ENABLED"-->
<!--            href="#signup-popup"-->
<!--            data-toggle='modal'-->
<!--            class="sign_up_button"-->
<!--            >-->
<!--              <i class="fa fa-user-plus" aria-hidden="true"></i>Sign up-->
<!--          </a>-->

        </div>
        <div v-else class="d-flex align-items-center">
          <div class="pull-left p-r-10 fs-14 font-heading hidden-md-down text-white">
            <span v-if="isAdminUser" class="bold">{{ name }} (admin)</span>
            <span v-else class="bold">{{ name }}</span>
          </div>
          <div class="dropdown pull-right">
            <button
              class="profile-dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="thumbnail-wrapper circular inline sm-m-r-5 txt-thumb">{{ thumbnail }}</span>
            </button>

            <header-menu />

          </div>
        </div>
      </div>
      <!-- Login -->
    </div>

  </div>
  </template>


<script>
import ENABLED from '../../../toggle_feature';
import HeaderMenu from "./header-menu.vue";
import { isAdminUser } from "../../../utils/user";

export default {
  name: "dashboard-header",

  components: {
    HeaderMenu,
  },

  computed: {
    thumbnail() {
      return this.$store.getters.thumbnail;
    },

    name() {
      return this.$store.getters.name;
    }
  },

  data() {
    return {
      ENABLED: ENABLED,
      isAdminUser: isAdminUser(),
      user: JSON.parse(localStorage.getItem("user")),
    };
  }
};
</script>

<style scoped>
.header-xl-height {
  height: 5em !important;
}

.header-inner {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#log-info a {
  color: white;
}
.pull-right a{
  margin-left: 1em;
}
.sign_up_button{
  background-color: #007bff;
  color: white !important;
  padding: 1.2em 2em;
  border-radius: 5px;
  font-weight: bolder;
}
.sign_up_button i{
  padding-right: .5em;
}

</style>