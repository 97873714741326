import Onboarding from './containers/onboarding.vue'
import Dashboard from './containers/dashboard.vue'
import CoverDashboard from './containers/cover-dashboard.vue'
import CoverDashboardFull from './containers/cover-dashboard-full.vue'

import Profile from './components/dashboard/profile.vue'
import EditProfile from './components/dashboard/edit_profile.vue'
import ChangePassword from './components/dashboard/change_password.vue'
import Main from './components/dashboard/main.vue'
import CoverMain from './components/dashboard/cover-main.vue'
import CatalogCoverMain from './components/dashboard/catalog-cover-main.vue'
import MainCatalogCover from './components/dashboard/main-catalog-cover.vue'
import CoverFeedDetail from './components/dashboard/cover-feed-detail.vue'
import PostEditor from './components/dashboard/post-editor.vue'
import CatalogCoverFeedDetail from './components/dashboard/catalog-cover-feed-detail.vue'
import ManagePlans from './components/dashboard/manage-plans.vue'
import Subscriptions from './components/dashboard/subscriptions/list.vue'
import TermsOfUse from './components/terms_of_use.vue'

import Login from './components/login.vue'
//import Signup from './components/signup.vue'
import ResetPassword from './components/reset-password.vue'
import ForgotPassword from './components/forgot.vue'

import UsersManagement from './components/dashboard/users/users-management.vue'

import EmailVerification from './components/verify.vue'

import AuthMiddleware from './contrib/auth'

export default [
  { path: '/', beforeEnter: AuthMiddleware.index, redirect: '/manage-plans' },
  {
    path: '/user', component: Onboarding,
    beforeEnter: AuthMiddleware.guest,
    children: [
      { path: 'login', component: Login },
      //    { path: 'sign-up', component: Signup },
      { path: 'reset-password/:hash', component: ResetPassword },
      { path: 'forgot-password', component: ForgotPassword },
      { path: 'verify/:hash', component: EmailVerification },
    ]
  },
  {
    path: '/dashboard', component: CoverDashboard,
    beforeEnter: AuthMiddleware.auth,
    children: [
      { path: '', component: Main },
      { path: 'feed/:feedId', component: Main },
      { path: 'profile', component: Profile },
      { path: 'edit_profile', component: EditProfile },
      { path: 'change_password', component: ChangePassword },
      { path: 'subscriptions', component: Subscriptions },
      {
        path: 'users',
        component: UsersManagement,
        beforeEnter: AuthMiddleware.admin,
      },
    ]
  },
  {
    path: '/cover-dashboard', component: CoverDashboard,
    beforeEnter: AuthMiddleware.auth,
    children: [
      { path: '', component: CoverMain },
      { path: 'feed/:feedId', component: CoverMain },
      { path: 'profile', component: Profile },
      { path: 'subscriptions', component: Subscriptions },
      {
        path: 'users',
        component: UsersManagement,
        beforeEnter: AuthMiddleware.admin,
      },
    ]
  },
  {
    path: '/catalog', component: CoverDashboardFull,
    children: [
      { path: '', component: CatalogCoverMain },
      { path: 'feed/details/:feedId', component: CatalogCoverFeedDetail },
    ]
  },
  {
    path: '/maincover', component: CoverDashboard,
    children: [
      { path: '', component: MainCatalogCover }
    ]
  },
  {
    path: '/feed', component: CoverDashboard,
    beforeEnter: AuthMiddleware.auth,
    children: [
      { path: '', component: CoverFeedDetail },
      { path: 'details/:feedId', component: CoverFeedDetail }
    ]
  },
  {
    path: '/narrative', component: CoverDashboardFull,
    beforeEnter: AuthMiddleware.auth,
    children: [
      { path: 'editor/:docId', component: PostEditor }
    ]
  },
  {
    path: '/manage-plans', component: CoverDashboard,
    beforeEnter: AuthMiddleware.auth,
    children: [
      { path: '', component: ManagePlans }
    ]
  },
  {
    path: '/terms', component: CoverDashboardFull,
    children: [
      { path: '', component: TermsOfUse}
    ]
  }
]