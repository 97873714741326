<template>
  <div class="content">
    <div class="no-padding container-fixed-lg">
      <div class="row">
        <div class="col mt-4 mb-4">
          <a @click="$router.go(-1)">
            <i class="fa fa-arrow-left"></i>&nbsp;Back
          </a>
        </div>
      </div>
      <div class="row" v-if="!isDemoUser">
        <div class="col">
          <h1>Change Password</h1>         
        </div>
      </div>
      <div class="row" v-if="!isDemoUser">
        <!-- row -->

        <div class="col">
          <alert v-bind:message="message" v-bind:type="status"></alert>

          <alert :message="error" type="error" v-for="error in errors" v-bind:key="error" />

          <form role="form" autocomplete="off" @submit="update" id="profile-form">
            <div class="form-group-attached">
              <div class="row clearfix">
                <div class="col-md-12 mb-4">
                  <div class="form-group form-group-default">
                    <label>Current password</label>
                    <input
                      type="password"
                      class="form-control"
                      name="current_password"
                      v-model="data.current_password"
                      spellcheck="false"
                      
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group form-group-default">
                    <label>New password</label>
                    <input
                      type="password"
                      class="form-control"
                      name="new_password"
                      v-model="data.new_password"
                      spellcheck="false"
                      
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group form-group-default">
                    <label>Confirm password</label>
                    <input
                      type="password"
                      class="form-control"
                      name="confirm_password"
                      v-model="data.confirm_password"
                      spellcheck="false"
                      
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
            <div class="col-lg-4 ml-auto">
              <button
                type="submit"
                class="btn btn-primary btn-cons btn-lg btn-block"
                :disabled="loading"
              >
                <span>Update</span>
                <spinner v-bind:loading="loading" klass="action-spinner"></spinner>
              </button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import consts from "../consts";

import users from "../../api/users";

import FormInput from "../ui/form-input.vue";
import Alert from "../ui/alert.vue";
import Spinner from "../ui/spinner.vue";

import PaymentMethods from "./payment-methods.vue";
import { validateForm, validate } from "../../utils/validation.js";
import { isDemoUser } from "../../utils/user";

export default {
  name: "profile",

  data() {
    return {
      loading: false,

      message: null,
      status: null,

      confirmation: true,
      user: JSON.parse(localStorage.getItem("user")),
      data: {
        current_password: '',
        new_password: '',
        confirm_password: '',
      },
      formError: false,
      errors: [], 
      isDemoUser: isDemoUser(),
    };
  },

  components: {
    Alert,
    Spinner,
    FormInput,
    PaymentMethods
  },

  computed: {
    disabled() {
      return this.loading || !this.confirmation || this.formError;
    }
  },

  methods: {
    async update(e) {
      e.preventDefault();

      const errors = validateForm(e.target);
      this.errors = errors;

      if (errors.length != 0) return;

      if (this.data.current_password.length < 8){
        this.errors = ["Current password is required"];
        return;
      }

      if (this.data.new_password.length) {
        const [validated, validationError] = validate(this.data.new_password);
        if (!validated) {
          this.errors = [validationError];
          return;
        }
      }

      if (this.data.confirm_password.length) {
        const [validated, validationError] = validate(this.data.confirm_password);
        if (!validated) {
          this.errors = [validationError];
          return;
        }
      }

      if (this.data.new_password != this.data.confirm_password){
        this.errors = ["Passwords do not match"]
        return;
      }

      
      this.loading = true;
            
      const [data, err] = await users.updatePassword(this.user.id, this.data);
      this.loading = false;

      
      if (err) {
        this.message = consts.REQUEST_ERR;
        this.status = "danger";
        return;
      }

      
      const { status, message, user } = data;

      if (status == "failure") {
        this.error = message;
        this.status = "danger";
        return;
      }

      this.status = "success";
      this.message = "Password updated successfully";
      this.data = {};

      
    },

    updateForm(e) {
      const { name, value } = e.target;
      this.user[name] = value;
      this.data[name] = value;

      if (name === "password") {
        if (!value.length) {
          this.errors = [];
          return;
        }

        this.errors = [validate(value)[1]];
      }
    }
  }
};
</script>

<style scoped>
.form-control[readonly] {
  color: #2c2d2f;
}

label {
  margin-bottom: 1em;
  color:#888;
}
</style>