<template>
  <div class="alert" :class="alertklass" role="alert">
    <p>{{ message }}</p>
    <action-btn :klass="btnKlass" text="Resend Email" :loading="loading"
                spinnerKlass="spinner-plain"
                @click="resendEmail" v-if="!processed" />
  </div>
</template>

<script>
  import ActionBtn from '../ui/action-btn.vue'
  import users from '../../api/users'
  import { getErrorMessage } from '../../api/utils.js'

  const DEFAULT_MESSAGE =
  "Your email has not been verified yet, click here to resend verification email."

  const SUCCESS_MESSAGE = "Check your inbox :)"

  export default {
    components: {
      ActionBtn
    },

    data() {
      return {
        message: DEFAULT_MESSAGE,
        loading: false,
        klass: "warning",
        processed: false
      }
    },

    computed: {
      alertklass() {
        return `alert-${this.klass}`
      },

      btnKlass() {
        return `resend-btn btn-${this.klass}` + (this.loading ? " action-active" : "")
      }
    },

    methods: {
      setEmail(email) {
        this.email = email
        this.processed = false
        this.message = DEFAULT_MESSAGE
      },

      async resendEmail() {
        this.loading = true
        this.message = DEFAULT_MESSAGE

        const [data, err] = await users.verify(this.email)
        this.loading = false

        if (err) {
          this.message = getErrorMessage(err)
          this.klass = 'danger'
          return
        }

        this.message = SUCCESS_MESSAGE
        this.klass = 'success'
        this.processed = true
      }
    }
  }
</script>

<style>
  .resend-btn {
    margin-top: 5px;
  }

  .action-active {
    padding-right: 33px;
  }
</style>