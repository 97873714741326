<template>
  <div class="tabs_parent_bg" v-if="isUserLogged">
    <div class="container">
      <div class="row">
        <div class="col-8">
          <div class="menu-bar header-sm-height" data-pages-init="horizontal-menu">
            <ul v-if="!isAdminUser">
              <li>
                <router-link to="/cover-dashboard">
                  <i class="fa fa-th-list" aria-hidden="true"></i> Feeds
                </router-link>
              </li>

              <li>
                <router-link to="/manage-plans">
                  <i class="fa fa-credit-card" aria-hidden="true"></i> My Subscription
                </router-link>
              </li>
            </ul>
            <ul v-if="isAdminUser">
              <li>
                <router-link to="/dashboard">Feeds</router-link>
              </li>
              <li>
                <router-link to="/dashboard/users">Users</router-link>
              </li>
              <li>
                <router-link to="/cover-dashboard">Public Feeds</router-link>
              </li>
              <li>
                <router-link to="/catalog">Catalog</router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- Col -->
        <div class="col-4">
          <div class="menu-bar header-sm-height float-right" data-pages-init="horizontal-menu">
            <ul>
              <li>
                <router-link to="/catalog">
                  <i class="fa fa-shopping-basket" aria-hidden="true"></i> Public Catalog
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isAdminUser } from "../../utils/user";
import { isUserLogged } from "../../utils/user";

export default {
  name: "tabs",
  data() {
    return {
      isAdminUser: isAdminUser(),
      isUserLogged: isUserLogged()
    };
  }
};
</script>

<style scoped>
.tabs_parent_bg {
  background-color: #fedd4e;
  color: black;
  padding-top: .5em;
}
</style>