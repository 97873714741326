<template>
  <div class="content">
    <div class="no-padding container-fixed-lg">
      <div class="feeds-view">
        <div class="card card-transparent">
          <catalog-cover-feed-table :feeds="feeds" v-show="!loading" @reload="getFeeds" />
        </div>

        <spinner klass="centered-spinner" :loading="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import FilterView from './filter.vue'
import CatalogCoverFeedTable from './catalog-cover-feed-table.vue'
import Spinner from '../ui/spinner.vue'
import feeds from '../../api/feeds.js';
import { isAdminUser } from '../../utils/user';

  export default {
    name: 'dashboard',
    components: {
      FilterView,
      CatalogCoverFeedTable,
      Spinner
    },

    mounted() {
      this.getFeeds(null);
    },

    data() {
      return {
        loading: false,
        feeds: null,
        currentFeed: null,
        isAdminUser: isAdminUser(),
      }
    },

    methods: {
      async getFeeds() {
        this.feeds = null;
        this.loading = true;
        let response;
        response = await feeds.getAllPublic();
        
        const [data, err] = response;
        this.loading = false;

        if (!err) {
          this.feeds = data;
        }
      }
    }
  }
</script>

<style>
  .feeds-view {
    min-height: 60vh;
  }

  table {
    border: 1px solid #eaeaea;
  }
</style>