<template>
<div class="signup-popup modal fade" :id="id" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header clearfix text-left">
        <button type="button" class="close" aria-hidden="true" @click="close">×</button>
      </div>
      <div class="modal-body">
        <div class="signup-popup__inner register-container full-height sm-p-t-30">
          <div class="d-flex justify-content-center flex-column full-height ">
            <div class="signup-popup__logo text-center">
              <img src="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png" alt="logo" data-src="/assets/img/NarrativaLogoVectorialTodoNegro_50h.png" data-src-retina="/assets/
              img/logo.png" width="250"/>
            </div>
            <template v-if="!processed">
              <h2 class="signup-popup__title text-center">Sign up to Gabriele.AI</h2>
              <p class="signup-popup__copy text-center">The AI that automates your texts.</p>
              <form id="form-register" class="p-t-15" role="form" @submit="signup">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group form-group-default">
                      <label>Full Name</label>
                      <input type="text" name="fname" class="form-control" v-model="info.firstName" spellcheck="false" @blur="validateFullName">
                    </div>
                    <span class="cdp__input-error" v-if="showNameErrorMessage">{{nameErrorMessage}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group form-group-default">
                      <label>Email</label>
                      <input type="text" name="email" class="form-control" v-model="info.email" spellcheck="false" @blur="validateEmailOnBlur">
                    </div>
                    <span class="cdp__input-error" v-if="showEmailErrorMessage">{{emailErrorMessage}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group form-group-default">
                      <label>Password</label>
                      <input :type="passwordFieldType" name="pass"  class="form-control" v-model="info.password" @input="onPasswordUpdate">
                      <i v-show="info.password.length" @click="revealPassword" :class="revealPasswordCss" aria-hidden="true"></i>
                    </div>
                    <PasswordValidationMsg v-show="showPasswordErrorMessage" :validations="passwordValidations"></PasswordValidationMsg>
                  </div>
                </div>

                <div class="row">
                  <!-- <div class="col-md-12 recaptcha-terms">
                    This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                  </div> -->
                </div>

                <p class="signup__submit-error text-center"><strong>{{error}}</strong></p>
                <div class="text-center mb-2 mt-4">
                  <button class="signup-popup__btn btn btn-primary btn-cons btn-lg" type="submit" :disabled="loading">
                    <span class="signup-popup__btn-text">Sign up</span>
                    <spinner v-bind:loading="loading" klass="action-spinner"></spinner>
                  </button>
                </div>

              </form>
            </template>
            

            <email-message v-if="processed" :email="info.email" intent="to verify your account" longMessage="Alternatively, check your spam folder." @onBack='onBack'/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import ENABLED from '../toggle_feature.js';
  import modal from './ui/modals.js'
  import EmailMessage from './onboarding/email-message.vue'
  import Spinner from './ui/spinner.vue'
  import Alert from './ui/alert.vue'
  import users from '../api/users'
  import { validateEmail, validatePassword } from '../utils/validation.js'
  import PasswordValidationMsg from "./PasswordValidationMsg.vue"

  const RECAPTCHA_TOKEN = '6Leh6H4bAAAAAGYT-I6bmz7oytbeC8xnd2m4_oJU'

  const initialState = () => {
    return {
      ENABLED: ENABLED,
      info: {
        firstName: '',
        email: '',
        password: '',
      },

      error: null,
      loading: false,
      processed: false,
      validated: false,
      passwordFieldType: "password",
      emailErrorMessage: "Please enter a valid email.",
      showEmailErrorMessage: false,
      regexFullName: /^[a-zA-Z]{2,}(?: +[a-zA-Z]{2,})+$/,
      nameErrorMessage: "It must include at least two words.",
      showNameErrorMessage: false,
      showPasswordErrorMessage: false,
      passwordValidations: [],
    }
  }

  export default {
    name: 'signup',
    data() {
      return initialState()
    },
    props: ['title', 'id'],
    components: {
      EmailMessage,
      Spinner,
      Alert,
      PasswordValidationMsg
    },
    mounted(){
      if (ENABLED){
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_TOKEN}`)
        document.head.appendChild(recaptchaScript)
      }
    },
    computed: {
      _id() {
        return `#${this.id}`
      },
       signupDisabled() {
        return !this.validated && !this.loading
      },
      revealPasswordCss () {
        return {
          'reset-password__reveal': true, 
          'fa': true, 
          'fa-eye': this.passwordFieldType === 'password',
          'fa-eye-slash': this.passwordFieldType !== "password"
        }
      }
    },

    methods: {
      validateFullName () {
        //Check it has at least two words
        console.log(this.regexFullName.test(this.info.firstName));
        this.showNameErrorMessage = !this.regexFullName.test(this.info.firstName) && this.info.firstName.length;
      },
      validateEmailOnBlur () {
        this.showEmailErrorMessage = !validateEmail(this.info.email);
      },
      show() {
        $(this._id).modal(modal.show);
      },

      close() {
        $(this._id).modal('hide');
        this.$emit('onClose');
        this.processed = false;
        this.info = Object.assign({}, initialState().info)
      },

    check_form(){
      return this.showEmailErrorMessage === false && this.showNameErrorMessage === false && this.showPasswordErrorMessage === false;
    },

    async signup(e) {
        e.preventDefault()

        this.loading = true
        this.error = null

        const formValid = this.check_form()
        if (!formValid){
          this.loading = false
          return
        }

        const { email, password, firstName } = this.info
        const token = await this.recaptchaToken();
        const body = { email, password, firstName, token }

        const [data, err] = await users.signup(body)
        this.loading = false

        if (err) {
          this.error = "Something went wrong, try again."
          return
        }

        const {status, message} = data
        if (status == "failure") {
          this.error = message
          return
        }
        this.processed = true
      },
      recaptchaToken() {
        return new Promise((resolve) => {
          grecaptcha.ready(async () => {
            const token = await grecaptcha.execute(RECAPTCHA_TOKEN);
            resolve(token);
          });
        });
      },

      onPasswordUpdate(e) {
        this.passwordValidations = validatePassword(this.info.password);
        this.showPasswordErrorMessage = this.passwordValidations.some(item => item.valid === false);
      },

      onBack() {
        this.processed = false
        this.info = Object.assign({}, initialState().info)
      },
      revealPassword () {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      }
    }
  }
</script>

<style scoped>
.signup-popup.modal .modal-content {
  background-color: #EBEBEB !important;
}
.signup-popup__inner {
  padding: 35px 20px !important
}

.signup-popup__logo {
  margin-bottom: 2rem;
}

.signup-popup__title {
  font-size: 20px !important;
  font-weight: 800;
}

.signup-popup__copy {
  font-size: 16px !important;
}

.signup-popup__btn {
  font-size: 16px !important;
  font-weight: 800 !important;
}
.recaptcha-terms{
  font-size: .8em;
  color: #999;
  margin-bottom: 1em;
}
.recaptcha-terms a{
  color: rgb(110, 110, 223);
}
.signup-popup .modal-dialog{
  max-width: 600px;
}

.signup-popup .form-group-default {
  position: relative;
}
.signup-popup .form-group-default.focused {
  background-color: #fff!important;
}

.signup-popup .form-group-default.focused label {
  opacity: 1 !important;
}

.signup-popup .reset-password__reveal {
  cursor: pointer;
  font-size: 15px;
  margin-right: 5px;
  position: absolute;
  right: 5px;
  top: 24px;
}

.signup__submit-error {
  margin-top: 1rem;
}

.signup-popup.modal .close {
  margin: 0;
  padding: 0;
  top: 6px;
  right: 10px;
}

.signup-popup__btn:disabled .signup-popup__btn-text {
  margin-left: -21px;
}

</style>