<template>
  <div class="content">
    <div class="bg-white"></div>

    <div class="jumbotron">
      <div class="container p-l-0 p-r-0 container-fixed-lg sm-p-l-0 sm-p-r-0"/>
    </div>

    <div class="no-padding container-fixed-lg">
      <div class="container feeds-view">
        <message-progress klass="centered-spinner" :message="mspinner.message"
                          v-show="mspinner.show"  />

        <div class="row m-4 float-right" v-if="apiKey">
          <div class="col">
            <i class="fa fa-key"></i>
            <span class="bold m-r-10">Your Key</span>

            <span class="api-info-pre">
              {{ apiKey }}
            </span>
          </div>
        </div>

        <subscriptions-table v-show="!mspinner.show" :subscriptions="subscriptions"
                             @cancel="unsubscribe" @viewDetails="viewDetails"/>
      </div>
    </div>

    <progress-dialog ref='pdialog' :message="dialog.message" @onclose="onPdialogClose" />
    <subscriptions-detail ref='transactions' :subscription="subscription"/>
  </div>
</template>

<script>
  import MessageProgress from '../../ui/message-progress.vue'
  import ProgressDialog from '../../ui/progress-dialog.vue'
  import SubscriptionsDetail from './subscription-detail.vue'
  import SubscriptionsTable from './table.vue'

  import users from '../../../api/users.js'
  import feeds from '../../../api/feeds.js'
  import consts from '../../consts.js'

  export default {
    components: {
      ProgressDialog,
      MessageProgress,
      SubscriptionsTable,
      SubscriptionsDetail
    },

    data() {
      return {
        mspinner: {
          show: true,
          message: null,
        },

        subscriptions: null,
        subscription: null,
        apiKey: null,
        dialog: {
          message: null
        }
      }
    },

    mounted() {
      this.getSubscriptions();
      this.getUserApiKey();
    },

    methods: {
      async getSubscriptions() {
        this.mspinner.show = true;
        const userId = JSON.parse(localStorage.getItem('user')).id;
        const [subscriptions, err] = await users.getSubscriptions(userId);

        if (err) {
          this.mspinner.message = consts.REQUEST_ERR;
          return;
        }

        this.subscriptions = subscriptions;

        if (!subscriptions.length) {
          this.mspinner.message = 'You are not subscribed to any feeds.';
          return;
        }

        this.mspinner.show = false;
      },

      async unsubscribe(feedId) {
        this.$refs.pdialog.show();

        let message = null;

        const [data, error] = await feeds.unsubscribe(feedId);
        this.dialog.message = error ? consts.REQUEST_ERR : data.message;
      },

      async getUserApiKey() {
        const [userInfo, err] = await users.getUserInfo(this.$store.getters.user.id);
        if (err) {
          this.mspinner.message = consts.REQUEST_ERR;
          return;
        }
        this.apiKey = userInfo.apiKey;
      },

      onPdialogClose() {
        this.getSubscriptions();
      },

      viewDetails(subscription) {
        this.subscription = subscription;
        this.$refs.transactions.show();
      }
    }
  }
</script>