<template>
  <div id="newspaper" class="preview">
    <div class="row" id="browser">
      <div class="tex-center" id="browser-controls">
        &nbsp;&nbsp;
        <i class="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;
        <i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;&nbsp;
        <i class="fa fa-refresh" aria-hidden="true"></i>
        &nbsp;&nbsp;
      </div>
      <div class id="url-direction">
        &nbsp;
        <i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;https://www.theacmesj.com
      </div>
    </div>
    <div class="row">
      <div class="col" id="newspaper-title">
        <h1 class="fake">THE ACME STREET JOURNAL</h1>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-12 text-center">
        <span class="newspaper-section fake" v-for="section in newspaperSections">{{section}}</span>
      </div>
    </div>
    <!-- row -->
    <!--<div class="row">
      <div class="col-md-8 offset-md-2" id="newspaper-central-banner">Banner</div>
    </div>-->
    <!-- row -->
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col">
            <p class="newspaper-breadcrumb">
              <span v-if="post && post.section1">
                <a>{{post.section1}}</a>
              </span>
              <span v-if="post && post.section2">
                <a>| {{post.section2}}</a>
              </span>
              <span v-if="post && post.section3">
                <a>| {{post.section3}}</a>
              </span>
            </p>
          </div>
        </div>
        <div class="row newspaper-news">
          <div class="col">
            <h1 v-if="post &&  post.title">{{ post.title }}</h1>
            <h2 v-if="post &&  post.subtitle">{{ post.subtitle }}</h2>
          </div>
        </div>
      </div>
      <div class="col-md-9 newspaper-news">
        <div class="row">
          <div class="col">
            <p v-if="post &&  post.picurl" id="picurl">
              <a v-bind:href="post.picurl" target="_blank">
                <img v-bind:src="post.picurl+'&scale=1&extension=.jpg'" class="img-fluid" />
              </a>
            </p>
            <!-- <h3>{{ post.leadin }}</h3> -->

            <p id="newspaper-author">
              By
              <a>Gabriele</a> for
              <a>Our beloved customer</a>
            </p>
            <p id="newspaper-date" v-if="post &&  post.date">{{post.date}}</p>
            <p id="newspaper-icons">
              <a>
                <i class="fa fa-print" aria-hidden="true"></i> Print
              </a>&nbsp;&nbsp;&nbsp;
              <a>
                <i class="fa fa-font" aria-hidden="true"></i> Text
              </a>
            </p>
            <div
              v-if="post &&  post.content"
              v-html="post.content"
              id="newspaper-text"
            >{{post.content}}</div>
          </div>
        </div>
        <!-- row -->
        <div class="row fake" id="newspaper-comments">
          <div class="col">
            <h2 class="fake">
              Comments
              <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h2 class="fake">Sponsored Content</h2>
            <div class="row" id="newspaper-foot-news">
              <div class="col-3" v-for="(news,index) in newsPaperFootNews">
                <div class="row">
                  <div class="col">
                    <div class="image">
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="fake">{{news}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- row -->
      </div>
      <div class="col-md-3">
        <div class="row">
          <div class="col">
            <div id="newspaper-lateral-banner">
              Banner
              <br />
              <br />
              <br />Use
              <br />Automated
              <br />News
              <br />
              <br />
              <br />By
              <br />Narrativa
              <br />
            </div>
          </div>
        </div>
        <!-- row -->
        <div class="row">
          <div class="col">
            <div id="newspaper-best-news">
              <h2 class="fake">Today's top news</h2>
              <div class="row fake" v-for="(news,index) in this.newspaperTopNews">
                <div class="col-2 number">{{index+1}}.</div>
                <div class="col-10 title">{{news}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- row -->
      </div>
    </div>
    <!-- row -->
  </div>
</template>

<script>
import Spinner from "../ui/spinner.vue";

import feedsContent from "../../api/feedscontent.js";
import { isUserLogged } from "../../utils/user";

export default {
  name: "feed-preview-newspaper",

  components: {
    Spinner
  },

  props: ["post"],

  data() {
    return {
      loading: false,
      loadingContent: false,
      newspaperSections: [
        "Home",
        "World",
        "Politics",
        "Economy",
        "Business",
        "Markets",
        "Opinion",
        "Real State"
      ],
      newspaperTopNews: [
        "Scientists create a plant that cannot die",
        "Automated news is the future",
        "World’s first hotdog ATM opens in Malaysia",
        "Flood of caramel unleashed by Brazilian sugar fire",
        "Crocodile breaks loose on Qantas flight"
      ],
      newsPaperFootNews: [
        "Priscilla Presley Sells Spanish-Style for...",
        "Worldwise: Cookbook Author and Entrepreneur...",
        "Canadian Billionaire Lawrence Stroll Sells ...",
        "Enjoy Life on the Water’s Edge and Views of..."
      ],
      isUserLogged: isUserLogged()
    };
  }
};
</script>

<style>
.feed-title {
  font-size: 2em;
  display: inline;
}

.preview {
  border: 2px solid #000;
  padding: 1em;
  padding-top: 0px;
  margin-bottom: 4em;
  background-color: #fff;
}

#newspaper-title h1 {
  text-align: center;
  font-size: 3em !important;
  font-family: 'Times New Roman', Times, serif !important;
}

.newspaper-section {
  padding-left: 1em;
  padding-right: 1em;
}

.fake {
  color: #ccc !important;
}

.newspaper-breadcrumb a {
  text-transform: capitalize;
  color: blue;
}

.newspaper-news h1 {
  font-size: 2.3em !important;
}

#newspaper-author {
  font-style: italic;
}

#newspaper-author a {
  text-decoration: underline;
}

#newspaper-date {
  font-size: 0.8em;
  margin-top: -1em;
}

#newspaper-icons {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

#picurl {
  margin-bottom: 2em;
  margin-top: 2em;
}

#newspaper-comments {
  margin-top: 2em;
  margin-bottom: 2em;
}

#newspaper-lateral-banner {
  background-color: #eee;
  color: white;
  font-size: 1.7em;
  padding: 1em;
  line-height: 1em;
  margin-top: 1em;
}

#newspaper-central-banner {
  background-color: #eee;
  color: white;
  font-size: 2em;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

#newspaper-text p {
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: 1.5em;
}

#newspaper-best-news {
  margin-top: 5em;
}

#newspaper-best-news .row {
  margin-top: 1em;
}

#newspaper-best-news .number {
  font-size: 2.5em;
  margin-top: 0em;
}

#newspaper-best-news .title {
  font-size: 1em;
  line-height: 1.3em;
  margin-top: 0.6em;
}

#newspaper-best-news .image {
  background-color: #eee;
}

#newspaper-foot-news .image {
  background-color: #eee;
}

#browser {
  background-color: #000;
  height: 2.5em;
}
#url-direction {
  background-color: white;
  height: 1.5em;
  margin-top: 0.45em;
  width: 47em;
}

#browser-controls {
  height: 1.5em;
  margin-top: 0.45em;
  color: white;
}
</style>