<template>
  <!-- tabs -->
  <div class="container">
    <div v-if="a_feed_content">
      <form @submit="save">
        <div class="mb-2" v-if="isAdminUser">
          <label for="inputTitle">Date</label>
          <div class="input-group">
            <input
              type="text"
              v-model="a_feed_content.date"
              class="form-control"
              id="inputTitle"
              placeholder="Date"
              required
            />
          </div>
        </div>
        <div class="mb-2">
          <label for="inputTitle">Title</label>
          <div class="input-group">
            <input
              type="text"
              v-model="a_feed_content.title"
              class="form-control"
              id="inputTitle"
              placeholder="Title"
              required
            />
          </div>
        </div>
        <div class="mb-2">
          <label for="inputSubtitle">Subtitle</label>

          <div class="input-group">
            <input
              type="text"
              v-model="a_feed_content.subtitle"
              class="form-control"
              id="inputSubtitle"
              placeholder="Subtitle"
            />
          </div>
        </div>
        <div class="mb-2">
          <label for="inputLeadin">Lead in</label>
          <div class="input-group">
            <input
              type="text"
              v-model="a_feed_content.leadin"
              class="form-control"
              id="inputLeadin"
              placeholder="Lead in"
            />
          </div>
        </div>
        <div class="mb-2">
          <label for="inputContent">Content</label>
          <div>
            <ckeditor
              v-model="a_feed_content.content"
              @namespaceloaded="onNamespaceLoaded"
              :config="editorConfig"
              @focus="onEditorFocus"
            ></ckeditor>
          </div>
        </div>
        <div class="mb-2">
          <label for="inputPicurl">Picture URL</label>
          <div class="input-group">
            <input
              type="text"
              v-model="a_feed_content.picurl"
              class="form-control"
              id="inputPicurl"
              placeholder="https://..."
            />
          </div>
        </div>
        <div class="mb-2">
          <label for="inputTags">Tags (comma separated)</label>
          <div class="input-group">
            <input
              type="text"
              v-model="a_feed_content.tag"
              class="form-control"
              id="inputTags"
              placeholder="tag1, tag2"
            />
          </div>
        </div>
        <div class="mb-2">
          <label for="inputSection1">Section 1</label>
          <div class="input-group">
            <input
              type="text"
              v-model="a_feed_content.section1"
              class="form-control"
              id="inputSection1"
              placeholder="Section 1"
            />
          </div>
        </div>
        <div class="mb-2">
          <label for="inputSection2">Section 2</label>
          <div class="input-group">
            <input
              type="text"
              v-model="a_feed_content.section2"
              class="form-control"
              id="inputSection2"
              placeholder="Section 2"
            />
          </div>
        </div>
        <div class="mb-2">
          <label for="inputSection3">Section 3</label>
          <div class="input-group">
            <input
              type="text"
              v-model="a_feed_content.section3"
              class="form-control"
              id="inputSection3"
              placeholder="Section 3"
            />
          </div>
        </div>
        <div class="text-right">
          <span class="text-danger" v-if="is_changed_but_not_saved">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i
            >&nbsp;Click on "Save" to persist the changes
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </span>
          <button
            type="button"
            class="btn btn-default"
            @click="showCancelPostDialog"
          >
            Discard
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="showSavePostDialog"
          >
            Save
          </button>
        </div>
      </form>
    </div>
    <confirm-dialog
      :a_id="'savePostDialog_' + a_feed_content._id"
      ref="'savePostDialog_' + a_feed_content._id"
      :message="'Changes are going to be stored permanently. Are you sure?'"
      @confirm="save()"
    ></confirm-dialog>
    <confirm-dialog
      :a_id="'cancelPostDialog_' + a_feed_content._id"
      ref="'cancelPostDialog_' + a_feed_content._id"
      :message="'Changes are going to be discarded and will be lost. Are you sure?'"
      @confirm="cancel()"
    ></confirm-dialog>
  </div>
  <!-- preview code -->
</template>

<script>
import feedsContent from "../../api/feedscontent.js";
import { formatDate } from "../../utils/utils.js";
import CKEditor from "ckeditor4-vue";
import ConfirmDialog from "../ui/confirm-dialog.vue";
import { isAdminUser } from "../../utils/user";

export default {
  name: "feed-preview-editor",

  props: ["a_feed_content", "is_changed_but_not_saved"],

  components: {
    // Use the <ckeditor> component in this view.
    ckeditor: CKEditor.component,
    ConfirmDialog,
  },

  watch: {
    a_feed_content: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.firstTime) {
          console.debug("first time, ignoring");
          this.firstTime = false;
          return;
        }
        console.debug("Watch - a_feed_content - handler(): ");
        var changed = false;
        /*console.debug(newVal);
        console.debug(this.copy_of_just_prev_feed_content);*/
        for (const property in newVal) {
          /*console.debug(
            `${property}: ${newVal[property]} VS ${this.copy_of_just_prev_feed_content[property]}`
          );*/

          if (
            (typeof newVal[property] === "string" ||
              newVal[property] instanceof String) &&
            newVal[property] != this.copy_of_just_prev_feed_content[property]
          ) {
            //console.debug(
            //  `${property}: [${newVal[property]}] VS [${this.copy_of_just_prev_feed_content[property]}]`
            //);
            changed = true;
          }
        }
        if (changed) {
          this.copy_of_just_prev_feed_content = JSON.parse(
            JSON.stringify(newVal)
          );
          this.$emit("doc_changed");
        } else {
          console.debug("Change event discarded because there was no difference");
        }
      },
    },
  },

  data() {
    return {
      copy_of_just_prev_feed_content: JSON.parse(
        JSON.stringify(this.a_feed_content)
      ),
      copy_of_a_feed_content: JSON.parse(JSON.stringify(this.a_feed_content)),
      changed_by_cancel: false,
      editorConfig: {
        // The configuration of the editor.
        //toolbar: [ [ 'Bold' ] ]
        autoParagraph: false,
        enterMode: 1,
        allowedContent: true,
        extraPlugins: "bidi",
      },
      firstTime: true,
      isAdminUser: isAdminUser(),
    };
  },

  methods: {
    onNamespaceLoaded(CKEDITOR) {
      // Add external `placeholder` plugin which will be available for each
      // editor instance on the page.
      CKEDITOR.plugins.addExternal(
        "bidi",
        "/assets/plugins/bidi/",
        "plugin.js"
      );
    },

    onEditorFocus() {
      this.firstTime = false;
    },

    formatDate(stringDate) {
      return formatDate(stringDate);
    },

    showSavePostDialog() {
      console.debug("showSavePostDialog()" + this.a_feed_content._id);
      this.$refs["'savePostDialog_' + a_feed_content._id"].show();
    },

    showCancelPostDialog() {
      this.$refs["'cancelPostDialog_' + a_feed_content._id"].show();
    },

    async save(e) {
      let post_id = this.a_feed_content._id;
      console.log("Saving post: " + post_id);

      let data, err;

      [data, err] = await feedsContent.updatePost(post_id, this.a_feed_content);

      if (err) {
        this.error = err.data.message;
        return;
      }

      console.debug("Doc updated in database");
      this.copy_of_a_feed_content = JSON.parse(
        JSON.stringify(this.a_feed_content)
      );
      this.$emit("doc_saved", post_id);
    },

    async cancel(e) {
      this.changed_by_cancel = true;

      const array = [
        "title",
        "subtitle",
        "leadin",
        "content",
        "picurl",
        "tag",
        "section1",
        "section2",
        "section3",
      ];

      for (var i = 0; i < array.length; i++) {
        this.a_feed_content[array[i]] = this.copy_of_a_feed_content[array[i]];
      }
      this.changed_by_cancel = false;

      /* delay to emit this event the last one, and not the watch emit*/
      setTimeout(() => {
        this.$emit("doc_cancel_and_reload");
      }, 100);
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.5em !important;
  line-height: 1.3em;
}

h2 {
  font-size: 1.25em !important;
  line-height: 1.3em;
}

h3 {
  font-size: 1em !important;
  line-height: 1.3em;
}

#metainfo {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1em;
}

textarea {
  height: 100%;
}

#inputTitle {
  font-size: 1.6em;
}

#inputSubtitle {
  font-size: 1.3em;
}

#inputLeadin {
  font-size: 1.1em;
}

input {
  padding: 6px !important;
}

textarea {
  padding: 6px !important;
}

label {
  color: #157ef3 !important;
}
</style>